import { FC, useEffect } from "react";

type Props = {};

/**
 * このコンポーネントの責務は ブラウザバックを検知すること
 */
const DetectBrowserBack: FC<Props> = ({ children }) => {
  useBrowserBackDetect();
  return <>{children}</>;
};
export default DetectBrowserBack;

/**
 * ブラウザバック検知
 *
 * iOS/Safari はブラウザバックで遷移した場合、一部 js(alert, confirm, prompt) が機能しなくなる
 * ブラウザバックによる遷移が発生した場合、画面を更新させる
 */
function useBrowserBackDetect() {
  useEffect(() => {
    if (!isSafari()) return;

    const unused = document.getElementsByTagName("title")[0].innerHTML;
    window.history.replaceState(null, unused, null);

    const handler = (_: PopStateEvent) => {
      window.location.reload();
    };

    window.addEventListener("popstate", handler);

    return () => {
      window.removeEventListener("popstate", handler);
    };
  }, []);
}

/**
 * iPad Safari 判定
 *
 * @returns
 */
function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();

  return (
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
}
