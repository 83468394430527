import {
  ServiceReportHeaderDetail,
  ServiceReportHeaderDraft,
} from "../../models";

type SerializedDateProperties<
  T extends { createdAt: Date; updatedAt: Date }
> = Omit<T, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
};

/**
 * サービスレポートヘッダ情報の下書き
 */
export type SerializedServiceReportHeaderDraft = SerializedDateProperties<ServiceReportHeaderDraft>;

export function serviceReportHeaderDraftToJson(
  serviceReportHeader: ServiceReportHeaderDraft
): SerializedServiceReportHeaderDraft {
  return {
    ...serviceReportHeader,
    createdAt: serviceReportHeader.createdAt.toISOString(),
    updatedAt: serviceReportHeader.updatedAt.toISOString(),
  };
}

export function jsonToServiceReportHeaderDraft(
  json: SerializedServiceReportHeaderDraft
): ServiceReportHeaderDraft {
  return {
    ...json,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
  };
}

/**
 * サービスレポートヘッダ情報
 */
export type SerializedServiceReportHeaderDetail = Omit<
  SerializedDateProperties<ServiceReportHeaderDetail>,
  "technicianSignedAt" | "customerSignedAt"
> & {
  technicianSignedAt?: string;
  customerSignedAt?: string;
};

export function serviceReportToJson(
  serviceReportHeader: ServiceReportHeaderDetail
): SerializedServiceReportHeaderDetail {
  const {
    technicianSignedAt,
    customerSignedAt,
    createdAt,
    updatedAt,
    ...rest
  } = serviceReportHeader;
  const json: SerializedServiceReportHeaderDetail = {
    ...rest,
    createdAt: createdAt.toISOString(),
    updatedAt: updatedAt.toISOString(),
  };
  if (!!technicianSignedAt) {
    json.technicianSignedAt = technicianSignedAt.toISOString();
  }
  if (!!customerSignedAt) {
    json.customerSignedAt = customerSignedAt.toISOString();
  }
  return json;
}

export function jsonToServiceReport(
  json: SerializedServiceReportHeaderDetail
): ServiceReportHeaderDetail {
  const {
    technicianSignedAt,
    customerSignedAt,
    createdAt,
    updatedAt,
    ...rest
  } = json;
  const serviceReportHeader: ServiceReportHeaderDetail = {
    ...rest,
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
  };
  if (!!technicianSignedAt) {
    serviceReportHeader.technicianSignedAt = new Date(technicianSignedAt);
  }
  if (!!customerSignedAt) {
    serviceReportHeader.customerSignedAt = new Date(customerSignedAt);
  }
  return serviceReportHeader;
}
