import type {
  Diagram as WebApiDiagram,
  ExplodedView as WebApiExplodedView,
  ExplodedViewGroup as WebApiExplodedViewGroup,
  InlineResponse2001,
  ModelUnit as WebApiModelUnit,
  Ship as WebApiShip,
  ShipModel as WebApiShipModel,
} from "../webapi/generated";

export type Ship = WebApiShip & WithBookmark;
export type ShipDetail = InlineResponse2001;
export type Diagram = WebApiDiagram & {
  draftCount: number;
};
export type ShipModel = WebApiShipModel;
export type ShipModelWithDraft = WebApiShipModel & {
  draftCount: number;
};
export type ModelUnit = WebApiModelUnit;
export type ExplodedViewGroup = WebApiExplodedViewGroup;
export type ExplodedView = WebApiExplodedView;

export const shipMemoPageSize = 10;
export const shipBookmarkMaxCount = 6;

export type WithBookmark = {
  isBookmarked?: boolean;
  hasDraft?: boolean;
};
export type BookmarkShip = Ship & {
  language: string;
};
export interface BookmarkShipUpdate {
  id: BookmarkShip["fShipNo"];
  ship: Partial<Omit<BookmarkShip, "fShipNo" | "language">>;
}
export function getBookmarkShipId(
  fShipNo: BookmarkShip["fShipNo"],
  language: BookmarkShip["language"]
) {
  return `${fShipNo}-${language}`;
}

export type BookmarkShipAppendix = Pick<Ship, "fShipNo"> & {
  hasServiceReportDrafts: boolean;
  hasShipMemoDrafts: boolean;
  hasDiagramMemoDrafts: boolean;
  hasEquipmentMemoDrafts: boolean;
  hasServiceReportMemoDrafts: boolean;
  lastCacheUpdatedAt: number; // unix timestamp
};
export interface BookmarkShipAppendixUpdate {
  id: BookmarkShipAppendix["fShipNo"];
  shipAppendix: Partial<Omit<BookmarkShipAppendix, "fShipNo">>;
}

export type BookmarkShipCombine = BookmarkShip & BookmarkShipAppendix;

export type CacheKey = Pick<Ship, "fShipNo"> & {
  urls: string[];
};
export interface CacheKeyUpdated {
  id: CacheKey["fShipNo"];
  key: Partial<Omit<CacheKey, "fShipNO">>;
}

export type ShipNotification = {
  hasNewNotification: boolean;
};

/**
 * 引数のURLがS3URLか判定
 *
 * @param url
 * @returns
 */
export function isS3Url(url: string) {
  const s3UrlRegex = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/g);
  return s3UrlRegex.test(url);
}

/**
 * 引数のURLからクエリ以降を除いた文字列を取得
 *
 * @param targetUrl
 * @returns
 */
export function excludeQuery(targetUrl: string) {
  const url = new URL(targetUrl);
  return `${url.origin}${url.pathname}`;
}

/**
 * キャッシュ対象のリソース（何を取るか把握しないため unknown）
 */
export interface UnknownResource {
  language: BookmarkShip["language"];
  key: string;
  value: Blob;
}

/**
 * Bookmark のキャッシュを登録するストレージエリア名（DB名）を取得
 *
 * @param language
 * @returns
 */
export function getBookmarkStorageArea(language: UnknownResource["language"]) {
  return `bookmark-${language}`;
}

/**
 * IndexedDB へ言語別に保存するための型定義
 */
export interface IDBUpsertPayloadByLanguage {
  [language: string]: [
    {
      key: UnknownResource["key"];
      value: UnknownResource["value"];
    }
  ];
}
/**
 * IndexedDB へ言語別に保存するための変換処理
 *
 * @param resources
 * @returns
 */
export function convertPayloadByLanguageForUpsert(
  resources: UnknownResource[]
) {
  return resources.reduce((acc, resource) => {
    return Object.assign(acc, {
      [resource.language]: [
        ...(acc[resource.language] || []),
        { key: decodeURIComponent(resource.key), value: resource.value },
      ],
    });
  }, {} as IDBUpsertPayloadByLanguage);
}

/**
 * IndexedDB から言語別に削除するための型定義
 */
export interface IDBDeletePayloadByLanguage {
  [language: string]: UnknownResource["key"][];
}

/**
 * IndexedDB から言語別に削除するための変換処理
 *
 * @param urls
 * @returns
 */
export function convertPayloadByLanguageForDelete(urls: string[]) {
  const s3Urls = urls.filter(isS3Url);
  const cacheTargets = convertCacheTargetObject(urls);

  const payloadByLanguage = s3Urls.reduce((acc, url) => {
    return Object.assign(acc, { en: [...(acc["en"] || []), url] });
  }, {} as IDBDeletePayloadByLanguage);

  return cacheTargets.reduce((acc, target) => {
    return Object.assign(acc, {
      [target.language]: [...(acc[target.language] || []), target.url],
    });
  }, payloadByLanguage);
}

/**
 * キャッシュ対象 URL 一覧を言語別オブジェクトに変換
 *
 * @example
 * {
 *   language: string;
 *   url: string;
 * }
 *
 * @param targetUrls キャッシュ対象 URL 一覧
 * @returns
 */
export function convertCacheTargetObject(targetUrls: CacheKey["urls"]) {
  const languages = ["en", "ja"];

  return ([] as { language: string; url: string }[]).concat(
    ...targetUrls
      .filter((url) => !isS3Url(url))
      .map((url) => languages.map((language) => ({ language, url })))
  );
}
