import { FC, useState } from "react";
import Button from "../../Button";
import { ReactComponent as DeleteBtn } from "./ic_40_close_wh.svg";
import noimage from "./img_equipment_noimage_540.svg";
import styles from "./Thumbnail.module.css";

type Props = {
  src: string;
  onClick?: () => void;
  deletable?: boolean;
  onDeleteClick?: () => void;
  onImageError?: () => void;
};

/**
 * このコンポーネントの責務は添付ファイルを表示すること
 */
const Thumbnail: FC<Props> = ({
  src,
  onClick,
  deletable,
  onDeleteClick,
  onImageError,
}) => {
  const [hasError, setHasError] = useState(false);

  return (
    <div className={styles.host}>
      <img
        src={src}
        className={styles.image}
        alt="thumbnail"
        onClick={!hasError ? onClick : undefined}
        onError={(e) => {
          e.currentTarget.src = noimage;
          setHasError(true);
          onImageError?.();
        }}
      />
      {deletable ? (
        <Button
          className={styles.deleteButton}
          onClick={onDeleteClick}
          aria-label="delete attachment file"
        >
          <DeleteBtn />
        </Button>
      ) : null}
    </div>
  );
};
export default Thumbnail;
