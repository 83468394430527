import type {
  CommentDraft,
  CommentDraftAdd,
  CommentDraftUpdate,
  CreateCommentDraftArgType,
  CreateMemoDraftArgType,
  MemoDraft,
  MemoDraftAdd,
  MemoDraftUpdate,
  UpdateCommentDraftArgType,
  UpdateMemoDraftArgType,
} from "../../models";
import store, { actions, selectors } from "../../store";
import { jsonToMemo, memoToJson } from "../../store/serializers";
import { mergeMemos } from "./draft";

/**
 * 下書きメモ登録
 *
 * ※ 下書き種別: memo.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memo
 */
export function postNewEquipmentDraftMemo(
  shipId: MemoDraft["fShipNo"],
  equipmentId: NonNullable<MemoDraft["equipmentId"]>,
  memo: CreateMemoDraftArgType
) {
  const payload: MemoDraftAdd = {
    fShipNo: shipId,
    ...memo,
    type: memo.id ? "edit" : "create",
  };
  store.dispatch(actions.draft.equipmentMemoAdded({ ...payload, equipmentId }));
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書きメモ更新
 *
 * @param shipId
 * @param equipmentId
 * @param key
 * @param memo
 */
export function updateEquipmentDraftMemo(
  shipId: MemoDraft["fShipNo"],
  equipmentId: NonNullable<MemoDraft["equipmentId"]>,
  key: MemoDraft["key"],
  memo: UpdateMemoDraftArgType
) {
  store.dispatch(actions.draft.equipmentMemoUpdated({ key, memo }));
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書きメモ削除
 *
 * @param shipId
 * @param equipmentId
 * @param key
 */
export function deleteEquipmentDraftMemo(
  shipId: MemoDraft["fShipNo"],
  equipmentId: NonNullable<MemoDraft["equipmentId"]>,
  key: MemoDraft["key"]
) {
  store.dispatch(actions.draft.equipmentMemoRemoved(key));
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書きコメント登録
 *
 * ※ 下書き種別: comment.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param comment
 */
export function postNewEquipmentDraftComment(
  shipId: CommentDraft["fShipNo"],
  equipmentId: NonNullable<CommentDraft["equipmentId"]>,
  memoId: CommentDraft["memoId"],
  comment: CreateCommentDraftArgType
) {
  const payload: CommentDraftAdd = {
    fShipNo: shipId,
    ...comment,
    type: comment.id ? "edit" : "create",
    memoId,
  };
  store.dispatch(
    actions.draft.equipmentCommentAdded({ ...payload, equipmentId })
  );
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書きコメント更新
 *
 * @param shipId
 * @param equipmentId
 * @param key
 * @param comment
 */
export function updateEquipmentDraftComment(
  shipId: CommentDraft["fShipNo"],
  equipmentId: NonNullable<CommentDraft["equipmentId"]>,
  key: CommentDraft["key"],
  comment: UpdateCommentDraftArgType
) {
  store.dispatch(actions.draft.equipmentCommentUpdated({ key, comment }));
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書きコメント削除
 *
 * @param shipId
 * @param equipmentId
 * @param key
 */
export function deleteEquipmentDraftComment(
  shipId: CommentDraft["fShipNo"],
  equipmentId: NonNullable<CommentDraft["equipmentId"]>,
  key: CommentDraft["key"]
) {
  store.dispatch(actions.draft.equipmentCommentRemoved(key));
  updateHasEquipmentMemoDrafts(shipId, equipmentId);
}

/**
 * 下書き所有判定の更新
 *
 * @param shipId
 * @param equipmentId
 */
export function updateHasEquipmentMemoDrafts(
  shipId: MemoDraft["fShipNo"],
  equipmentId: NonNullable<MemoDraft["equipmentId"]>
) {
  const equipmentCountMap = selectors.draft.customSelectors.equipmentCountMap(
    store.getState().draft,
    shipId
  );
  const count = equipmentCountMap.get(equipmentId) || 0;
  store.dispatch(
    actions.bookmark.shipAppendixUpdated({
      id: shipId,
      shipAppendix: { hasEquipmentMemoDrafts: count > 0 },
    })
  );
}

/**
 * 下書きと通常メモ/コメントを取り出しマージ後、更新
 *
 * @param shipId
 * @param equipmentId
 * @param page
 * @returns
 */
export function pullDraft(
  shipId: string,
  equipmentId: string,
  page: number,
  queryCategories?: number[]
) {
  const { bookmark, draft, setting, account, ship } = store.getState();
  const {
    memos,
    comments,
  } = selectors.draft.customSelectors.equipmentDraftByFShipNo(draft, {
    fShipNo: shipId,
    equipmentId,
    categories: queryCategories || [],
  });
  const categories = selectors.category.selectAll(ship.categories);

  const equipmentMemos = selectors.equipmentMemo
    .selectAll(ship.equipmentMemos)
    .map(jsonToMemo);
  const cacheKey = selectors.bookmark.cacheKeysSelectors.selectById(
    bookmark.cacheKeys,
    shipId
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );

  if (!account.user) return [];

  const draftObject = {
    author: account.user,
    memos,
    comments,
  };
  const equipmentObject = {
    memos: equipmentMemos,
    count: ship.equipmentMemoCount,
    categories,
  };
  const dispatch = {
    updateMemoDraft: (arg: MemoDraftUpdate) =>
      store.dispatch(actions.draft.equipmentMemoUpdated(arg)),
    updateCommentDraft: (arg: CommentDraftUpdate) =>
      store.dispatch(actions.draft.equipmentCommentUpdated(arg)),
  };

  const { models, draftTemporaryMemos, count } = mergeMemos(
    page,
    equipmentObject,
    draftObject,
    dispatch,
    fulfilledConnect,
    cacheKey
  );

  store.dispatch(
    actions.ship.equipmentMemosReceived({
      memos: models.map(memoToJson),
      count,
    })
  );

  return draftTemporaryMemos;
}
