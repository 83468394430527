import classNames from "classnames/bind";
import type { FC } from "react";
import styles from "./Loading.module.css";
import { ReactComponent as Icon } from "./loading.svg";

const cx = classNames.bind(styles);

type Props = {
  background?: "blue" | "dark";
  disabled?: boolean;
};

/**
 * このコンポーネントの責務はアプリケーションが何かしらの読み込み待ちの状態であることを表示し、
 * ユーザーに伝えること
 */
const Loading: FC<Props> = ({ background = "blue", disabled }) => (
  <div className={cx("host", background, disabled && "disabled")}>
    <div className={styles.spinner}>
      <Icon />
    </div>
    <div className={styles.text}>Loading…</div>
  </div>
);
export default Loading;
