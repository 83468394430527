import { Item } from "../../models";
import { getAuthedWebapi } from "./custom-webapi";

/**
 * 船籍（国）マスタの取得
 */
export async function fetchShipFlags() {
  const webapi = await getAuthedWebapi();
  const { countries } = await webapi.getCountries();
  return countries;
}

/**
 * 船種マスタの取得
 */
export async function fetchShipSpecies() {
  const webapi = await getAuthedWebapi();
  const { shipSpecies } = await webapi.getShipSpecies();
  return shipSpecies;
}

/**
 * 管理単位マスタの取得
 */
export async function fetchManagementUnits() {
  const webapi = await getAuthedWebapi();
  const { managementUnits } = await webapi.getManagementUnits();
  return managementUnits;
}

/**
 * 保管場所マスタの取得
 */
export async function fetchWarehouses() {
  const webapi = await getAuthedWebapi();
  const { storageLocations } = await webapi.getStorageLocations();
  return storageLocations;
}

/**
 * 品目マスタの取得
 * @param query 条件
 */
export async function fetchItems(query: Item) {
  const webapi = await getAuthedWebapi();
  const { items } = await webapi.getItems(query);
  return items;
}
