import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

/**
 * このコンポーネントの責務はページ遷移の際に Google Analytics が現在ページ状態を
 * 追跡できるようにすること
 */
const GAPagePathTracking: FC<Props> = () => {
  useTracking();
  return null;
};
export default GAPagePathTracking;

/**
 * ページ遷移のたびに gtag に現在ページを設定する
 */
function useTracking() {
  const { pathname, search } = useLocation();
  const pagePath = pathname + search;

  useEffect(() => {
    // 画面タイトルの変更が別軸で行われるため、遅延評価を行う
    const delay = 1000;
    const timer = setTimeout(() => {
      gtag("event", "page_view", {
        page_path: pagePath,
        send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
      });
    }, delay);
    return () => clearTimeout(timer);
  }, [pagePath]);
}
