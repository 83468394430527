import { getAuthedWebapi } from "./custom-webapi";
import {
  FavoriteCategory as FC,
  FavoriteShipCategory,
  FavoriteShipCreate,
} from "../../webapi/generated/models";

export type FavoriteCategory = FC;

/**
 * 船舶お気に入りカテゴリの登録
 *
 * @param name カテゴリ名
 */
export async function postFavoriteCategory(name: string) {
  const webapi = await getAuthedWebapi();
  return webapi.postFavoriteCategories({ favoriteCategoryCreate: { name } });
}

/**
 * 船舶お気に入りカテゴリの編集
 *
 * @param categoryId お気に入りカテゴリID
 * @param name カテゴリ名
 */
export async function putFavoriteCategory(categoryId: number, name: string) {
  const webapi = await getAuthedWebapi();
  const requestParameters = {
    categoryId,
    favoriteCategoryUpdate: { name },
  };
  await webapi.putFavoriteCategoriesCategoryId(requestParameters);
}

/**
 * 船舶お気に入りカテゴリの削除
 *
 * @param categoryId お気に入りカテゴリID
 */
export async function deleteFavoriteCategory(categoryId: number) {
  const webapi = await getAuthedWebapi();
  await webapi.deleteFavoriteCategoriesCategoryId({ categoryId });
}

/**
 * お気に入り船舶のソート
 *
 * @param categories ソート対象のカテゴリ
 */
export async function sortFavoriteShips(categories: FavoriteShipCategory[]) {
  const webapi = await getAuthedWebapi();
  await webapi.putFavoriteShipsSort({
    favoriteShipSort: { favoriteShips: categories },
  });
}

/**
 * 船舶お気に入りカテゴリ一覧取得
 */
export async function fetchFavoriteCategories() {
  const webapi = await getAuthedWebapi();
  const { favoriteCategories } = await webapi.getFavoriteCategories();
  return favoriteCategories;
}

/**
 * お気に入り船舶の登録
 *
 * @param args 登録用引数
 */
export async function postFavoriteShip(args: FavoriteShipCreate) {
  const webapi = await getAuthedWebapi();
  await webapi.postFavoriteShips({ favoriteShipCreate: args });
}

/**
 * お気に入り船舶の削除
 *
 * @param favoriteShipId お気に入り船舶ID
 */
export async function deleteFavoriteShip(favoriteShipId: number) {
  const webapi = await getAuthedWebapi();
  await webapi.deleteFavoriteShipsFavoriteShipId({ favoriteShipId });
}
