import { AppApi, Configuration } from "./generated";

export default new AppApi(
  new Configuration({
    fetchApi: fetchWithMetrics,
    basePath: process.env.REACT_APP_WEBAPI_BASE_PATH,
  })
);

export function withApiBearerToken(webapi: AppApi, token: string): AppApi {
  return webapi.withPreMiddleware(async ({ url, init }) => {
    const headers = new Headers(init.headers);
    headers.set("authorization", token);
    return { url, init: { ...init, headers } };
  });
}

const locale = Intl.DateTimeFormat().resolvedOptions().timeZone;
export function withLanguageHeader(webapi: AppApi, lang: string): AppApi {
  return webapi.withPreMiddleware(async ({ url, init }) => {
    const headers = new Headers(init.headers);
    headers.set("accept-language", lang);
    headers.set("x-timezone-iana", locale);
    return { url, init: { ...init, headers } };
  });
}

/**
 * パフォーマンス計測のためにラップした fetch API
 */
async function fetchWithMetrics(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  const method =
    init?.method ??
    (input instanceof Request ? input.method : undefined) ??
    "GET";
  const url = input instanceof Request ? input.url : input;

  const startTime = performance.now();
  try {
    return await fetch(input, init);
  } finally {
    const endTime = performance.now();

    gtag("event", "timing_complete", {
      name: `${method} ${url}`,
      value: Math.round(endTime - startTime),
      event_category: "fetch",
      event_label: "HTTP Request Performance",
    });
  }
}
