import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import i18n from "../i18n";
import migration, { CURRENT_VERSION } from "./migration";
import { reducer } from "./modules";
import { createKvStorageAdapter } from "./storage";

const store = configureStore({
  reducer: persistReducer(
    {
      key: "root",
      storage: createKvStorageAdapter(),
      whitelist: ["account", "setting", "bookmark", "draft", "readMention"],
      migrate: migration,
      version: CURRENT_VERSION,
    },
    reducer
  ),
  middleware: getDefaultMiddleware({
    // redux-thunk のみ除外する
    // @see <path_to_project_root>/docs/frontend-architecture.md
    thunk: false,
    // redux-persist の設定用アクションは serializable ではないのでチェックから除外する
    serializableCheck: { ignoredActions: ["persist/PERSIST"] },
  }),
});

const persistor = persistStore(store, null, () => {
  // i18n の言語設定を復元する
  const { setting } = store.getState();
  i18n.changeLanguage(setting.language);
});

export { store, persistor };
