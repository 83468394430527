import store from "../../store";
import { getAuthedWebapi, getWebapi } from "./custom-webapi";

/**
 * お知らせ情報一覧取得 API 実行処理
 *
 * ※ オフライン時、空配列を返す
 *
 * @param isAuthed 認証可否
 * @returns
 */
export async function fetchInformations(isAuthed: boolean) {
  const { connection } = store.getState().setting;
  if (!connection) return [];

  const webapi = isAuthed ? await getAuthedWebapi() : await getWebapi();
  const { informations } = await webapi.getInformations({
    state: "nowShowing",
  });
  return informations;
}
