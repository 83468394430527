import { createMigrate } from "redux-persist";
import migrateV2 from "./migrations/v2";
import migrateV3 from "./migrations/v3";
import migrateV4 from "./migrations/v4";
import migrateV5 from "./migrations/v5";

// バージョン定義
export const PHASE = {
  V2: 2,
  V3: 3,
  V4: 4,
  V5: 5,
} as const;

/**
 * 永続対象を更新する場合は、変更必須
 */
export const CURRENT_VERSION = PHASE.V5;

const migrations = {
  [PHASE.V2]: migrateV2,
  [PHASE.V3]: migrateV3,
  [PHASE.V4]: migrateV4,
  [PHASE.V5]: migrateV5,
};

export default createMigrate(migrations as any, {
  debug: process.env.NODE_ENV === "development",
});
