import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Loading from "../Loading";

const ProcessingContext = createContext<{
  wait: (task: Promise<void>) => void;
}>({ wait: () => {} });

export function useProcessingContext() {
  return useContext(ProcessingContext);
}

type Props = {};

/**
 * このコンポーネントの責務は任意の処理が進行中であることを示す表示を提供すること
 */
const Processing: FC<Props> = ({ children }) => {
  const [processing, value] = useWaiter();

  return (
    <>
      <ProcessingContext.Provider value={value}>
        {children}
      </ProcessingContext.Provider>
      <Loading background="dark" disabled={!processing} />
    </>
  );
};
export default Processing;

function useWaiter() {
  const [tasks, setTasks] = useState<Promise<void>[]>([]);

  const processing = tasks.length > 0;

  const wait = useCallback((task: Promise<void>) => {
    setTasks((ts) => [...ts, task]);

    task.finally(() => setTasks((ts) => ts.filter((t) => t !== task)));
  }, []);
  const value = useMemo(() => ({ wait }), [wait]);

  return [processing, value] as const;
}
