import { FC, useEffect, useState } from "react";
import { selectors, useBoundSelector } from "../../../store";
import { information } from "../../../usecases";
import Button from "../../Button";
import InformationModal from "../../InformationModal";
import { ReactComponent as Icon } from "./ic_60_information.svg";
import styles from "./Information.module.css";

type Props = {};

/**
 * このコンポーネントの責務はお知らせを表示するボタンを表示すること
 */
const Information: FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const count = useInformationCount();
  const fulfilledConnect = useBoundSelector((s) =>
    selectors.setting.customSelectors.fulfilledConnectSelector(s.setting)
  );

  return (
    <>
      <Button
        className={styles.host}
        onClick={() => setIsOpen(true)}
        aria-label="show information"
        disabled={!fulfilledConnect}
      >
        <Icon />
        {count > 0 && <span className={styles.badge}>{count}</span>}
      </Button>
      <InformationModal
        isAuthed={true}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      />
    </>
  );
};
export default Information;

function useInformationCount() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      const informations = await information.fetchInformations(true);
      setCount(informations.length);
    })();
  }, []);

  return count;
}
