import type { FC } from "react";

type Props = {
  value?: Date;
};

/**
 * このコンポーネントの責務は Date インスタンスをこのプロジェクト特有の日付表示方法で
 * フォーマットした文字列に変換すること
 */
const Date: FC<Props> = ({ value }) => {
  return value ? <>{format(value)}</> : null;
};
export default Date;

export function format(date: Date): string {
  const hour = ("0" + date.getHours()).slice(-2);
  const min = ("0" + date.getMinutes()).slice(-2);
  const time = `${hour}:${min}`;
  return time;
}
