import type {
  CommentDraft,
  CommentDraftAdd,
  CommentDraftUpdate,
  CreateCommentDraftArgType,
  CreateMemoDraftArgType,
  MemoDraft,
  MemoDraftAdd,
  MemoDraftUpdate,
  UpdateCommentDraftArgType,
  UpdateMemoDraftArgType,
} from "../../models";
import store, { actions, selectors } from "../../store";
import { jsonToMemo, memoToJson } from "../../store/serializers";
import { mergeMemos } from "./draft";
import { updateHasServiceReportDrafts } from "./service-report-draft";

/**
 * 下書きメモ登録
 *
 * ※ 下書き種別: memo.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memo 登録内容
 */
export function postNewServiceReportDraftMemo(
  fShipNo: string,
  serviceReportId: NonNullable<MemoDraft["serviceReportId"]>,
  memo: CreateMemoDraftArgType
) {
  const payload: MemoDraftAdd = {
    fShipNo,
    serviceReportId,
    ...memo,
    type: memo.id ? "edit" : "create",
  };
  store.dispatch(
    actions.draft.serviceReportMemoAdded({ ...payload, serviceReportId })
  );
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * 下書きメモ更新
 *
 * @param shipId 船舶番号
 * @param key 下書きの識別子
 * @param memo 更新内容
 */
export function updateServiceReportDraftMemo(
  shipId: MemoDraft["fShipNo"],
  key: MemoDraft["key"],
  memo: UpdateMemoDraftArgType
) {
  store.dispatch(actions.draft.serviceReportMemoUpdated({ key, memo }));
  updateHasServiceReportDrafts(shipId);
}

/**
 * 下書きメモ削除
 *
 * @param shipId 船舶番号
 * @param key 下書きの識別子
 */
export function deleteServiceReportDraftMemo(
  shipId: MemoDraft["fShipNo"],
  key: MemoDraft["key"]
) {
  store.dispatch(actions.draft.serviceReportMemoRemoved(key));
  updateHasServiceReportDrafts(shipId);
}

/**
 * 下書きコメント登録
 *
 * ※ 下書き種別: comment.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param serviceReportId サービスレポートID
 * @param memoId メモID
 * @param comment 登録内容
 */
export function postNewServiceReportDraftComment(
  shipId: CommentDraft["fShipNo"],
  serviceReportId: NonNullable<CommentDraft["serviceReportId"]>,
  memoId: CommentDraft["memoId"],
  comment: CreateCommentDraftArgType
) {
  const payload: CommentDraftAdd = {
    fShipNo: shipId,
    ...comment,
    type: comment.id ? "edit" : "create",
    memoId,
  };
  store.dispatch(
    actions.draft.serviceReportCommentAdded({ ...payload, serviceReportId })
  );
  updateHasServiceReportDrafts(shipId);
}

/**
 * 下書きコメント更新
 *
 * @param shipId 船舶番号
 * @param key 下書きの識別子
 * @param comment 更新内容
 */
export function updateServiceReportDraftComment(
  shipId: CommentDraft["fShipNo"],
  key: CommentDraft["key"],
  comment: UpdateCommentDraftArgType
) {
  store.dispatch(actions.draft.serviceReportCommentUpdated({ key, comment }));
  updateHasServiceReportDrafts(shipId);
}

/**
 * 下書きコメント削除
 *
 * @param shipId
 * @param key
 */
export function deleteServiceReportDraftComment(
  shipId: CommentDraft["fShipNo"],
  key: CommentDraft["key"]
) {
  store.dispatch(actions.draft.serviceReportCommentRemoved(key));
  updateHasServiceReportDrafts(shipId);
}

/**
 * 下書きと通常メモ/コメントを取り出しマージ後、更新
 *
 * @param fShipNo 船舶番号
 * @param serviceReportId サービスレポートID
 * @param page ページ番号
 * @returns 編集下書き一覧
 */
export function pullDraft(
  fShipNo: string,
  serviceReportId: number,
  page: number
) {
  const { bookmark, draft, setting, account, ship } = store.getState();

  const {
    memos,
    comments,
  } = selectors.draft.customSelectors.serviceReportMemoDraftByFShipNo(draft, {
    fShipNo,
    serviceReportId,
  });

  const serviceReportMemos = selectors.serviceReportMemo
    .selectAll(ship.serviceReportMemos)
    .map(jsonToMemo);

  const cacheKey = selectors.bookmark.cacheKeysSelectors.selectById(
    bookmark.cacheKeys,
    fShipNo
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );

  if (!account.user) return [];

  const draftObject = {
    author: account.user,
    memos,
    comments,
  };
  const serviceReportObject = {
    memos: serviceReportMemos,
    count: ship.serviceReportMemoCount,
  };
  const dispatch = {
    updateMemoDraft: (arg: MemoDraftUpdate) =>
      store.dispatch(actions.draft.serviceReportMemoUpdated(arg)),
    updateCommentDraft: (arg: CommentDraftUpdate) =>
      store.dispatch(actions.draft.serviceReportCommentUpdated(arg)),
  };

  const { models, draftTemporaryMemos, count } = mergeMemos(
    page,
    serviceReportObject,
    draftObject,
    dispatch,
    fulfilledConnect,
    cacheKey
  );

  store.dispatch(
    actions.ship.serviceReportMemosReceived({
      memos: models.map(memoToJson),
      count,
    })
  );

  return draftTemporaryMemos;
}
