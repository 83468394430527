import { createSlice } from "@reduxjs/toolkit";
import type { SerializedUser } from "../../models";

type State = {
  user?: SerializedUser;
};

export const { actions, reducer } = createSlice({
  name: "account",
  initialState: {} as State,
  reducers: {
    userReceived(state, action: { payload: SerializedUser }) {
      state.user = action.payload;
    },
  },
});
