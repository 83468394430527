import type { AnchorHTMLAttributes, FC } from "react";

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
};

/**
 * このコンポーネントの責務は外部サイトへ遷移するためのアンカー要素を提供すること
 */
const ExternalLink: FC<Props> = ({ to, children, ...rest }) => {
  return (
    <a {...rest} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
export default ExternalLink;
