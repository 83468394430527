/**
 * ファイルシステム上の相対パスを React Router のルートキーに変換する
 * 例: `./about/index.tsx` → `/about`
 */
export default function relativePathToRouteKey(path: string): string {
  // remove dot prefix
  if (path.startsWith(".")) path = path.slice(1);
  // remove extension
  path = path.replace(/\.[jt]sx?$/, "");
  // remove /index suffix
  if (path.endsWith("/index")) path = path.slice(0, -6);
  // replace path param prefix
  path = path.replace(/(^|\/)_/g, "$1:");
  // root
  if (path === "") path = "/";

  return path;
}
