import classNames from "classnames";
import { FC, useCallback, useEffect, useState } from "react";
import type { AttachmentFile } from "../../models";
import { detectAttachmentFileTypeByName } from "../../models";
import { diagram } from "../../usecases";
import styles from "./AttachmentFiles.module.css";
import Link from "./Link";
import Thumbnail from "./Thumbnail";
import ViewerModal from "./ViewerModal";

type Props = {
  attachments: Array<File | AttachmentFile>;
  deletable?: boolean;
  onRequestDelete?: (target: File | AttachmentFile["id"]) => void;
  className?: string;
};

/**
 * このコンポーネントの責務は添付ファイル一覧を表示すること
 *
 * AttachmentButton コンポーネントと組み合わせて使用することを想定している
 */
const AttachmentFiles: FC<Props> = ({
  attachments,
  deletable,
  onRequestDelete,
  className,
}) => {
  const [groupedAttachments, onImageError] = useGroupedAttachments(attachments);
  const [onThumbnailClick, viewerModalProps] = useViewer(
    groupedAttachments.images
  );
  const onAttachmentFileDownload = useOnFileDownlaod();

  return (
    <>
      <div className={classNames(styles.host, className)}>
        {groupedAttachments.videos.map(({ url, attachment }) => (
          <Link
            key={url}
            url={url}
            fileName={
              attachment instanceof File ? attachment.name : attachment.fileName
            }
            deletable={deletable}
            disabled={!(attachment instanceof File) && !attachment.available}
            onDeleteClick={() =>
              onRequestDelete?.(
                attachment instanceof File ? attachment : attachment.id
              )
            }
          />
        ))}
        {groupedAttachments.others.map(({ url, attachment }) => (
          <Link
            key={url}
            url={url}
            fileName={
              attachment instanceof File ? attachment.name : attachment.fileName
            }
            deletable={deletable}
            disabled={!(attachment instanceof File) && !attachment.available}
            onDeleteClick={() =>
              onRequestDelete?.(
                attachment instanceof File ? attachment : attachment.id
              )
            }
            onDownloadClick={() =>
              onAttachmentFileDownload(
                url,
                attachment instanceof File
                  ? attachment.name
                  : attachment.fileName
              )
            }
          />
        ))}
        {groupedAttachments.images.map(({ url, attachment }, index) => (
          <Thumbnail
            key={url}
            src={url}
            onClick={() => onThumbnailClick(index)}
            deletable={deletable}
            onDeleteClick={() =>
              onRequestDelete?.(
                attachment instanceof File ? attachment : attachment.id
              )
            }
            onImageError={() =>
              onImageError(
                attachment instanceof File ? attachment : attachment.id
              )
            }
          />
        ))}
      </div>
      <ViewerModal {...viewerModalProps} />
    </>
  );
};
export default AttachmentFiles;

type GroupedAttachment = {
  attachment: Props["attachments"][number];
  url: string;
  hasError?: boolean;
};
type Groups = {
  videos: GroupedAttachment[];
  others: GroupedAttachment[];
  images: GroupedAttachment[];
};
function useGroupedAttachments(attachments: Props["attachments"]) {
  const [groupedAttachments, setGroupedAttachments] = useState<Groups>({
    videos: [],
    others: [],
    images: [],
  });

  const [errorImages, setErrorImages] = useState<GroupedAttachment[]>([]);

  const onImageError = useCallback(
    (target: File | AttachmentFile["id"]) => {
      const errorImage = errorImages.find((i) =>
        i.attachment instanceof File
          ? i.attachment === target
          : i.attachment.id === target
      );

      if (errorImage === undefined) {
        const image = groupedAttachments.images.find((i) =>
          i.attachment instanceof File
            ? i.attachment === target
            : i.attachment.id === target
        );

        if (image !== undefined) {
          image.hasError = true;
          setErrorImages([...errorImages, image]);
        }
      }
    },
    [groupedAttachments, errorImages]
  );

  useEffect(() => {
    const videos: GroupedAttachment[] = [];
    const others: GroupedAttachment[] = [];
    const images: GroupedAttachment[] = [];

    const objectUrls: string[] = [];
    for (const attachment of attachments) {
      let url;
      if (attachment instanceof File) {
        url = URL.createObjectURL(attachment);
        objectUrls.push(url);
      } else {
        url = attachment.url;
      }

      const fileName =
        attachment instanceof File ? attachment.name : attachment.fileName;
      const type = detectAttachmentFileTypeByName(fileName);

      switch (type) {
        case "video": {
          videos.push({ attachment, url });
          break;
        }
        case "other": {
          others.push({ attachment, url });
          break;
        }
        case "image": {
          const target =
            attachment instanceof File ? attachment : attachment.id;

          const hasError =
            errorImages.find((i) =>
              i.attachment instanceof File
                ? i.attachment === target
                : i.attachment.id === target
            ) !== undefined;

          images.push({ attachment, url, hasError });
          break;
        }
      }
    }
    setGroupedAttachments({ videos, others, images });

    return () => {
      for (const url of objectUrls) URL.revokeObjectURL(url);
    };
  }, [attachments, errorImages]);

  return [groupedAttachments, onImageError] as const;
}

function useViewer(images: GroupedAttachment[]) {
  const length = images.length;
  const [targetIndex, setTargetIndex] = useState<number | undefined>();
  const src = targetIndex !== undefined ? images[targetIndex].url : "";
  const isOpen = targetIndex !== undefined;

  const hasVaildImage = (from: number, to: number): boolean => {
    if (0 <= from && from <= to && to < length) {
      for (let i = from; i <= to; i++) {
        if (!images[i]?.hasError) return true;
      }
    }
    return false;
  };

  const hasLeftPage =
    targetIndex !== undefined ? hasVaildImage(0, targetIndex - 1) : false;

  const hasRightPage =
    targetIndex !== undefined
      ? hasVaildImage(targetIndex + 1, length - 1)
      : false;

  const onThumbnailClick = useCallback((index: number) => {
    setTargetIndex(index);
  }, []);

  const onLeftClick = useCallback(() => {
    setTargetIndex((prev) => {
      if (prev === undefined || prev > length - 1) return undefined;
      for (let i = prev - 1; i >= 0; i--) {
        if (!images[i]?.hasError) return i;
      }
      return undefined;
    });
  }, [length, images]);

  const onRightClick = useCallback(() => {
    setTargetIndex((prev) => {
      if (prev === undefined || prev < 0) return undefined;
      for (let i = prev + 1; i < length; i++) {
        if (!images[i]?.hasError) return i;
      }
      return undefined;
    });
  }, [length, images]);

  const onRequestClose = useCallback(() => {
    setTargetIndex(undefined);
  }, []);

  const viewerModalProps = {
    src,
    isOpen,
    hasLeftPage,
    hasRightPage,
    onLeftClick,
    onRightClick,
    onRequestClose,
  };
  return [onThumbnailClick, viewerModalProps] as const;
}

function useOnFileDownlaod() {
  return useCallback((url: string, fileName: string) => {
    diagram.downloadAttachmentFile(url, fileName);
  }, []);
}
