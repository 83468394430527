import { readMention } from "..";
import { Memo, memoType, MentionMemoWithBreadcrumbs, Ship } from "../../models";
import { ReadMention } from "../../models/read-mention";
import store, { actions } from "../../store";
import { MentionsGetRequest } from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";
import { getMemoTransitionParams, getSearchMemoBreadcrumbs } from "./memo";

/**
 * メンション通知一覧取得
 */
export async function fetchMentions(query?: MentionsGetRequest) {
  const webapi = await getAuthedWebapi();
  const { memos } = await webapi.mentionsGet(query || {});

  if (memos.length === 0) return [] as MentionMemoWithBreadcrumbs[];

  // ログインユーザの最終ログイン日時を取得
  const { account } = store.getState();
  const lastLoginAt = account.user
    ? new Date(account.user.lastLoginAt)
    : undefined;

  // 受け取ったデータの整形
  const convertedMentions = memos.map<MentionMemoWithBreadcrumbs>((memo) => {
    const files = (memo.files || []).map((file) => ({
      ...file,
      available: true,
    }));

    const values = {
      ...memo,
      files: files,
      breadcrumbs: getSearchMemoBreadcrumbs({
        ...memo,
        type: memo.type as number,
      }),
      isUnread: false,
    };

    const isNew = !!(
      lastLoginAt && lastLoginAt.getTime() < memo.updatedAt.getTime()
    );
    if (!isNew) return values;

    const compareMention: ReadMention = {
      id: memo.id,
      userId: account.user?.id!,
      memoId: memo.memoId,
      diagramId: memo.diagramId,
      equipmentId: memo.equipmentId,
      serviceReportId: memo.serviceReportId,
      updatedAt: memo.updatedAt,
    };

    values.isUnread = readMention.isUnread(compareMention);

    if (values.isUnread) {
      store.dispatch(
        actions.readMention.mentionAdded({
          ...compareMention,
          updatedAt: compareMention.updatedAt.toISOString(),
        })
      );
    }

    return values;
  });
  return convertedMentions;
}

/**
 * 新着メンションの取得
 */
export async function fetchNewMentions() {
  const webapi = await getAuthedWebapi();
  const { connection } = store.getState().setting;
  if (!connection) return [];

  const { memos } = await webapi.mentionsNewGet();
  return memos;
}

/**
 * メンション通知モーダル画面から特定のメモを表示するためのパスを取得
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param type メモ種別
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param serviceReportId サービスレポートID
 * @returns
 */
export async function getMemoTransitionPath(
  shipId: Ship["fShipNo"],
  memoId: Memo["id"],
  type: number,
  diagramId?: string,
  equipmentId?: number,
  serviceReportId?: number
) {
  const params = await getMemoTransitionParams(
    shipId,
    memoId!,
    diagramId,
    equipmentId,
    serviceReportId
  );

  let path = "";
  switch (type) {
    case memoType.SHIP:
      path = `/ships/${shipId}/notifications`;
      const { pathname, search } = window.location;

      if (pathname.startsWith("/ships")) {
        // 船系画面の場合
        const locationShipId = pathname.split("/")[2];
        // 同じ船かつ閲覧中の画面が Notification の場合
        if (locationShipId === shipId && pathname.includes("notifications")) {
          // 更新通知画面のパラメータ＋メモ詳細の特定に必要なパラメータ
          const combinedParams = new URLSearchParams({
            ...Object.fromEntries(new URLSearchParams(search)),
            ...Object.fromEntries(params),
          });
          path += `?${combinedParams}`;
        } else {
          path += `?${params}`;
        }
      } else {
        // 船系画面以外の場合
        path += `?${params}`;
      }
      break;
    case memoType.DIAGRAM:
      path = `/ships/${shipId}/diagrams/${diagramId}?${params}`;
      break;
    case memoType.EQUIPMENT:
      path = `/ships/${shipId}/models/${equipmentId}/units?${params}`;
      break;
    case memoType.SERVICE_REPORT:
      path = `/ships/${shipId}/service-reports/${serviceReportId}?${params}`;
      break;
  }

  return path;
}
