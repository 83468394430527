import store from "../../store";
import { getAuthedWebapi } from "./custom-webapi";

/**
 * sapliのユーザー一覧取得
 *
 * @param exclude ユーザー一覧に自身を含めないか
 */
export const fetchSapliUsers = async (exclude: boolean) => {
  const webapi = await getAuthedWebapi();
  const response = await webapi.sapliUsersGet();

  const { user } = store.getState().account;

  const users = exclude
    ? response.users.filter((u) => u.id !== user?.id)
    : response.users;

  return users;
};
