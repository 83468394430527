import {
  MemoDraft,
  ServiceReportSign,
  ServiceReportSignatureDraft,
  serviceReportSignatureType,
} from "../../models";
import store, { actions, selectors } from "../../store";

/**
 * 下書き所有判定の更新
 *
 * @param shipId
 */
export function updateHasServiceReportDrafts(shipId: MemoDraft["fShipNo"]) {
  const hasServiceReportDraft = selectors.draft.customSelectors.hasServiceReport(
    store.getState().draft,
    shipId
  );

  const hasServiceReportMemoDraft = selectors.draft.customSelectors.hasServiceReportMemo(
    store.getState().draft,
    shipId
  );

  store.dispatch(
    actions.bookmark.shipAppendixUpdated({
      id: shipId,
      shipAppendix: {
        hasServiceReportDrafts: hasServiceReportDraft,
        hasServiceReportMemoDrafts: hasServiceReportMemoDraft,
      },
    })
  );
}
/**
 * サービスレポートの下書き、サービスレポートに付随する下書きのメモ・コメント、サービスレポートに付随する下書きの署名・明細を保持しているかを判定する
 *
 * @param fShipNo 船舶番号
 * @param serviceReportId サービスレポートID
 * @param isBilled サービスレポートが請求済みのフラグ
 */
export function hasDraft(
  fShipNo: string,
  serviceReportId: number,
  isBilled?: boolean
) {
  const {
    memos,
    comments,
  } = selectors.draft.customSelectors.serviceReportMemoDraftByFShipNo(
    store.getState().draft,
    { fShipNo: fShipNo, serviceReportId: serviceReportId }
  );

  const hasServiceReportDraft = isBilled
    ? false
    : selectors.draft.customSelectors.serviceReportHeaderById(
        store.getState().draft,
        serviceReportId
      );

  const signatureDraft = selectors.draft.customSelectors.serviceReportSignatureByServiceReportId(
    store.getState().draft,
    serviceReportId
  );

  const detailDraft = selectors.draft.customSelectors.serviceReportDetailsByServiceReportId(
    store.getState().draft,
    String(serviceReportId)
  );

  const detailSignatureDraft = selectors.draft.serviceReportDetailSignaturesSelectors
    .selectAll(store.getState().draft.serviceReportDetailSignatures)
    .filter(
      (state) =>
        state.serviceReportId === serviceReportId ||
        state.serviceReportKey === String(serviceReportId)
    );

  return (
    !!memos.length ||
    !!comments.length ||
    !!hasServiceReportDraft ||
    !!signatureDraft ||
    !!detailDraft.length ||
    !!detailSignatureDraft.length
  );
}

/**
 * サービスレポートに対して署名の下書きの登録処理
 *
 * @param fShipNo 船舶番号
 * @param data 登録内容
 */
export function postNewServiceReportSignatureDraft(
  fShipNo: string,
  data: ServiceReportSign & { key: string; id?: number }
) {
  const isCustomer = data.type === serviceReportSignatureType.CUSTOMER;
  const signatureKey = isCustomer ? "customerSignature" : "technicianSignature";
  const signedAtKey = isCustomer ? "customerSignedAt" : "technicianSignedAt";

  const payload: any = {
    id: data.id,
    key: data.key,
    fShipNo,
    [signatureKey]: data.signature,
    [signedAtKey]: new Date().toISOString(),
  };

  if (isCustomer) {
    payload.customerRemarks = data.reason;
  }

  store.dispatch(actions.draft.serviceReportSignatureAdded(payload));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートの署名の下書きの更新処理
 *
 * @param fShipNo 船舶番号
 * @param key 識別子
 * @param data 更新内容
 */
export function updateServiceReportSignatureDraft(
  fShipNo: string,
  key: ServiceReportSignatureDraft["key"],
  data: ServiceReportSign
) {
  const isCustomer = data.type === serviceReportSignatureType.CUSTOMER;
  const signatureKey = isCustomer ? "customerSignature" : "technicianSignature";
  const signedAtKey = isCustomer ? "customerSignedAt" : "technicianSignedAt";

  const payload: Omit<any, "key" | "fShipNo"> = {
    [signatureKey]: data.signature,
    [signedAtKey]: new Date().toISOString(),
  };

  if (isCustomer) {
    payload.customerRemarks = data.reason;
  }

  store.dispatch(
    actions.draft.serviceReportSignatureUpdated({ key, data: payload })
  );
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートの署名の下書きの削除処理
 *
 * @param fShipNo 船舶番号
 * @param key 識別子
 */
export function deleteServiceReportSignatureDraft(
  fShipNo: string,
  key: ServiceReportSignatureDraft["key"]
) {
  store.dispatch(actions.draft.serviceReportSignatureRemoved(key));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートのお客様署名の下書きの削除処理
 *
 * @param fShipNo 船舶番号
 * @param key 識別子
 */
export function deleteServiceReportCustomerSignatureDraft(
  fShipNo: string,
  key: string
) {
  const signature = selectors.draft.serviceReportSignaturesSelectors.selectById(
    store.getState().draft.serviceReportSignatures,
    key
  );
  if (!signature) return;

  // 技術員署名の下書きが登録されている場合、お客様署名の下書きを空に変更する
  if (!!signature.technicianSignedAt) {
    const payload = {
      ...signature,
      customerSignature: undefined,
      customerRemarks: undefined,
      customerSignedAt: undefined,
    };
    store.dispatch(
      actions.draft.serviceReportSignatureUpdated({ key, data: payload })
    );
  } else {
    store.dispatch(actions.draft.serviceReportSignatureRemoved(key));
  }

  updateHasServiceReportDrafts(fShipNo);
}
