import { FC, useEffect, useState } from "react";
import styles from "./MentionButton.module.css";
import { ReactComponent as Icon } from "./ic_60_mention.svg";
import Button from "../../Button";
import store, { selectors, useBoundSelector } from "../../../store";
import MentionModal from "../../MentionModal";
import { mention, readMention } from "../../../usecases";
import { NewMention } from "../../../webapi/generated";
import { ReadMention } from "../../../models/read-mention";

type Props = {};

/**
 * このコンポーネントの責務は Header上にメンションを表示するボタンを表示すること
 */
const MentionButton: FC<Props> = () => {
  const [isOpen, setOpen] = useState(false);
  const count = useMentionCount();

  const fulfilledConnect = useBoundSelector((s) =>
    selectors.setting.customSelectors.fulfilledConnectSelector(s.setting)
  );
  return (
    <>
      <Button
        className={styles.host}
        disabled={!fulfilledConnect}
        onClick={() => setOpen(!isOpen)}
      >
        <Icon />
        {count > 0 && <span className={styles.badge}>{count}</span>}
      </Button>
      <MentionModal isOpen={isOpen} onRequestClose={() => setOpen(false)} />
    </>
  );
};
export default MentionButton;

const useMentionCount = () => {
  const [mentions, setMentions] = useState<NewMention[]>([]);
  const [count, setCount] = useState(0);
  const userId = store.getState().account.user?.id;
  const storeMentions = useBoundSelector((s) => s.readMention.readMentions);
  useEffect(() => {
    (async () => {
      const mentions = await mention.fetchNewMentions();
      setMentions(mentions);
    })();
  }, []);

  useEffect(() => {
    const unreadMention = mentions.filter((mention) => {
      const checkMention = { ...mention, userId: userId } as ReadMention;
      return readMention.isUnread(checkMention);
    });
    setCount(unreadMention.length);
  }, [mentions, storeMentions, userId]);

  return count;
};
