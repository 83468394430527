import type { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon } from "./img_offline.svg";
import ReactModal from "react-modal";
import SubmitButton from "../SubmitButton";
import styles from "./OfflineModal.module.css";
import DateFormat from "../Date";
import Time from "../Time";
import Button from "../Button";
import classNames from "classnames";

type Props = ComponentProps<typeof ReactModal> & {
  onRequestOK?(event: React.MouseEvent | React.KeyboardEvent): void;
  lastLoginAt?: Date;
};

/**
 * このコンポーネントの責務は接続状態がオフラインになったときに表示するモーダルを提供すること
 */
const OfflineModal: FC<Props> = ({
  onRequestOK,
  onRequestClose,
  lastLoginAt,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ReactModal
      className={styles.host}
      overlayClassName={styles.overlay}
      onRequestClose={onRequestOK || onRequestClose}
      shouldCloseOnOverlayClick={false}
      {...rest}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <div className={styles.title}>{t("Currently in offline mode.")}</div>
        <div className={styles.message}>
          <div>{t("Shows the last updated information in offline mode.")}</div>
          <div className={styles.lastLogin}>
            {t("Last login")}:
            {lastLoginAt ? (
              <>
                <span className={styles.lastLoginDate}>
                  <DateFormat value={lastLoginAt} />
                </span>
                <Time value={lastLoginAt} />
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className={classNames(styles.message, styles.confirmMessage)}>
          <div>{t("Do you want to change to offline mode?")}</div>
          <div>{t("Unsaved content will be discarded.")}</div>
        </div>
        <div className={styles.cancelBtnWrapper}>
          <SubmitButton
            type="button"
            onClick={onRequestOK}
            className={styles.button}
          >
            {t("OK")}
          </SubmitButton>
        </div>
        <div className={styles.cancelBtnWrapper}>
          <Button className={styles.cancelButton} onClick={onRequestClose}>
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};
export default OfflineModal;
