/**
 * フロントエンドのエントリーポイント (= ページ表示の最初に読み込まれ、実行されるファイル)
 */

import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ConnectionGate from "./components/ConnectionGate";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorGate from "./components/ErrorGate";
import Loading from "./components/Loading";
import MaintenanceGate from "./components/MaintenanceGate";
import PageRouter from "./components/PageRouter";
import Processing from "./components/Processing";
import "./i18n";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store, { persistor } from "./store";
import LanguageSubscriptor from "./components/LanguageSubscriptor";
import DownloadGate from "./components/DownloadGate";

const container = document.getElementById("root")!;

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <Processing>
              <ConnectionGate>
                <MaintenanceGate>
                  <ErrorGate>
                    <DownloadGate>
                      <LanguageSubscriptor />
                      <PageRouter />
                    </DownloadGate>
                  </ErrorGate>
                </MaintenanceGate>
              </ConnectionGate>
            </Processing>
          </Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  container
);

// a11y のため、モーダル表示中はスクリーンリーダーが読み上げ対象から外す要素を指定する
// @see http://reactcommunity.org/react-modal/accessibility/
ReactModal.setAppElement(container);

// Create React App の Service Worker の仕組みを有効にする
// @see https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorkerRegistration.register({
  onUpdate(registration) {
    registration.waiting!.postMessage({ type: "SKIP_WAITING" });
    window.postMessage({ type: "NEW_SW_LOADED" }, window.location.origin);
    window.location.reload();
  },
});
window.addEventListener("message", (event) => {
  if (event.origin !== window.location.origin) return;
  if (event.data.type !== "NEW_SW_LOADED") return;
  window.location.reload();
});
