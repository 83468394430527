import { bindActionCreators } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./modules";
import type { Actions, RootState } from "./modules";

/**
 * `store.dispatch(xxxAction())` の代わりに `xxxAction()` とできる Action 一覧を返す
 */
export function useBoundActions(): Actions {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      (Object.keys(actions) as [keyof Actions]).reduce(
        (accu, key) => ({
          ...accu,
          [key]: bindActionCreators(actions[key], dispatch),
        }),
        {} as any
      ),
    [dispatch]
  );
}

/**
 * 具体的な Store と結合していて型推論がされる useSelector
 */
export function useBoundSelector<TSelected>(
  selector: (state: RootState) => TSelected
) {
  return useSelector<RootState, TSelected>(selector);
}
