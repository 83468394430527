import { forwardRef, SelectHTMLAttributes, useState } from "react";
import Custom from "./Custom";
import { ReactComponent as PulldownIcon } from "./ic_60_pulldown_gr.svg";
import { ReactComponent as PulldownIconDisabled } from "./ic_60_pulldown_gr_disabled.svg";
import { ReactComponent as PullupIcon } from "./ic_60_pullup_gr.svg";
import { ReactComponent as PullupIconDisabled } from "./ic_60_pullup_gr_disabled.svg";
import Native from "./Native";
import styles from "./Select.module.css";

export type Option = {
  value: string;
  text?: string;
};

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  unselectedText?: string;
  options: Array<Option>;
  className?: string;
  bottomPadding?: number;
  max?: number;
  invalid?: boolean;
  resizeFlag?: boolean;
  onSelected?: (selected: string[]) => void;
};

/**
 * このコンポーネントの責務は選択肢の中から項目を一つ選ぶ機能を提供すること
 */
const Select = forwardRef<HTMLSelectElement, Props>(
  ({ label, unselectedText, options, ...rest }, ref) => {
    const [focus, setFocus] = useState(false);
    const content = (
      <>
        {rest.multiple ? (
          <Custom
            unselectedText={unselectedText}
            options={options}
            {...rest}
            ref={ref}
            onFocusChange={setFocus}
          />
        ) : (
          <Native options={options} {...rest} ref={ref} />
        )}
        {focus && !rest.disabled && <PullupIcon className={styles.icon} />}
        {focus && rest.disabled && (
          <PullupIconDisabled className={styles.icon} />
        )}
        {!focus && !rest.disabled && <PulldownIcon className={styles.icon} />}
        {!focus && rest.disabled && (
          <PulldownIconDisabled className={styles.icon} />
        )}
      </>
    );
    return label ? (
      <label className={styles.label}>
        <div className={styles.text}>{label}</div>
        {content}
      </label>
    ) : (
      <div className={styles.host}>{content}</div>
    );
  }
);
export default Select;
