import { createPartialBreadcrumbsByMemoType } from "../usecases/modules/memo";
import {
  Notification,
  GetShipsFShipNoNotificationsSortEnum,
} from "../webapi/generated";
import { AttachmentFile } from "./diagram";
import { memoType, MemoType, memoTypeName } from "./memo";

export type NotificationModel = Notification & {
  key: string;
  isNew: boolean;
  isUnread?: boolean;
  breadcrumbs: string[];
  files: AttachmentFile[];
};

/**
 * Enum から Union 型へ変換
 */
export type NotificationSortType = `${GetShipsFShipNoNotificationsSortEnum}`;

/**
 * 更新通知に表示するパンくずリストを取得
 */
export function getNotificationBreadcrumbs(notification: Notification) {
  const type = notification.type as MemoType;
  const breadcrumbs = [
    memoTypeName[type],
    ...createPartialBreadcrumbsByMemoType(type, notification),
  ];
  return breadcrumbs;
}

/**
 * 並び替えキー一覧
 */
export const notificationSortTypes: { value: string; text: string }[] = [
  {
    value: GetShipsFShipNoNotificationsSortEnum.LastUpdatedDesc,
    text: "Newest First",
  },
  {
    value: GetShipsFShipNoNotificationsSortEnum.LastUpdatedAsc,
    text: "Oldest First",
  },
];

/**
 * 1ページあたりの表示件数
 */
const notificationPageSize = 20;

export function getPageCount(totalCount: number) {
  return Math.ceil(totalCount / notificationPageSize);
}

/**
 * Union 型から Enum へ変換処理
 *
 * @param query 並び替えキー
 * @returns
 */
export function convertNotificationSortQuery(query?: string) {
  switch (query) {
    case GetShipsFShipNoNotificationsSortEnum.LastUpdatedAsc:
      return GetShipsFShipNoNotificationsSortEnum.LastUpdatedAsc;
    case GetShipsFShipNoNotificationsSortEnum.LastUpdatedDesc:
      return GetShipsFShipNoNotificationsSortEnum.LastUpdatedDesc;
    default:
      return GetShipsFShipNoNotificationsSortEnum.LastUpdatedDesc;
  }
}

/**
 * 更新通知のユニークな値を取得
 *
 * @param notification
 * @returns
 */
export function getNotificationKey(notification: Notification) {
  const keys = [`m${notification.id}`];

  switch (notification.type) {
    case memoType.DIAGRAM:
      if (notification.diagramId) {
        keys.push(`d${notification.diagramId}`);
      }
      break;
    case memoType.EQUIPMENT:
      if (notification.equipmentId) {
        keys.push(`e${notification.equipmentId}`);
      }
      break;
  }

  if (notification.isComment && notification.memoId) {
    keys.push(`c${notification.memoId}`);
  }

  const concatedKey = keys.join("_");
  return concatedKey;
}
