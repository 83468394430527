import { ComponentProps, FC } from "react";
import ReactModal from "react-modal";
import Button from "../../Button";
import { ReactComponent as LeftArrow } from "./btn_140_left.svg";
import { ReactComponent as RightArrow } from "./btn_140_right.svg";
import { ReactComponent as Close } from "./ic_60_close_wh.svg";
import styles from "./ViewerModal.module.css";

type Props = ComponentProps<typeof ReactModal> & {
  src: string;
  hasLeftPage?: boolean;
  hasRightPage?: boolean;
  onLeftClick?: () => void;
  onRightClick?: () => void;
};

/**
 * このコンポーネントの責務は添付ファイルを詳細に見るためのモーダルを表示すること
 */
const ViewerModal: FC<Props> = ({
  src,
  onLeftClick,
  hasLeftPage,
  hasRightPage,
  onRightClick,
  onRequestClose,
  children,
  ...rest
}) => {
  return (
    <ReactModal
      className={styles.host}
      overlayClassName={styles.overlay}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <img src={src} className={styles.content} alt="content" />
      {hasLeftPage ? (
        <Button
          className={styles.leftButton}
          onClick={onLeftClick}
          aria-label="left page"
        >
          <LeftArrow />
        </Button>
      ) : null}
      {hasRightPage ? (
        <Button
          className={styles.rightButton}
          onClick={onRightClick}
          aria-label="right page"
        >
          <RightArrow />
        </Button>
      ) : null}
      <Button
        className={styles.closeButton}
        onClick={onRequestClose}
        aria-label="close"
      >
        <Close />
      </Button>
    </ReactModal>
  );
};
export default ViewerModal;
