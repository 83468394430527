import { createSelector, createSlice } from "@reduxjs/toolkit";

type LanguageCode = "en" | "ja";
type State = {
  language: LanguageCode;
  /** 最終操作時刻 (エポックミリ秒) */
  latestOperationTime?: number;
  /** 通信接続状況 */
  connection: boolean;
  /** オフラインモーダル表示フラグ **/
  showOfflineModal: boolean;
  /** ユーザモード選択フラグ(仮) **/
  userSelectMode: boolean;
};

/**
 * 通信状況区分
 *
 * 通信: connection
 * ユーザーによる通信選択: userSelectMode
 *
 * PendingConnect: 通信OFF AND ユーザーによる通信選択ON
 * FulfilledConnect: 通信ON OR (通信OFF AND ユーザーによる通信選択ON)
 * RejectedConnect: 通信OFF AND ユーザーによる通信選択OFF
 */
const pendingConnectSelector = createSelector(
  (state: State) => state,
  (state) => !state.connection && state.userSelectMode
);
const fulfilledConnectSelector = createSelector(
  (state: State) => state,
  (state) => state.connection || pendingConnectSelector(state)
);
const rejectedConnectSelector = createSelector(
  (state: State) => state,
  (state) => !state.connection && !state.userSelectMode
);

/**
 * 通信状況区分を纏めたもの
 */
const connectSelector = createSelector(
  (state: State) => state,
  (state) => ({
    withPendingConnect: pendingConnectSelector(state),
    withFulfilledConnect: fulfilledConnectSelector(state),
    withRejectedConnect: rejectedConnectSelector(state),
  })
);

export const selectors = {
  customSelectors: {
    pendingConnectSelector,
    fulfilledConnectSelector,
    rejectedConnectSelector,
    connectSelector,
  },
};

export const { actions, reducer } = createSlice({
  name: "setting",
  initialState: {
    language: "en",
    showOfflineModal: false,
    userSelectMode: true,
  } as State,
  reducers: {
    languageChanged(state, action: { payload: LanguageCode }) {
      state.language = action.payload;
    },
    operated(state, action: { payload: number }) {
      state.latestOperationTime = action.payload;
    },
    clearOperationHistory(state) {
      delete state.latestOperationTime;
    },
    connectionReceived(state, action: { payload: boolean }) {
      state.connection = action.payload;
    },
    showModal(state, action: { payload: boolean }) {
      state.showOfflineModal = action.payload;
    },
    setUserMode(state, action: { payload: boolean }) {
      state.userSelectMode = action.payload;
    },
  },
});
