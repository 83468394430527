/**
 * オブジェクトの配列を指定されたキーでグループ化する
 *
 * @param array グループ化するオブジェクトの配列
 * @param key グループ化の基準となるキー
 * @example
 * // −−−− groupByの使い方 −−−−
 *  interface MyObject {
 *    id: number;
 *    name: string;
 *  }

 *  const objectsArray: MyObject[] = [
 *    { id: 1, name: "Object1" },
 *    { id: 2, name: "Object2" },
 *    { id: 1, name: "Object3" },
 *    { id: 3, name: "Object4" },
 *    { id: 2, name: "Object5" },
 *  ];
 *
 *  const groupedByIDs = groupBy(objectsArray, "id");
 *
 *  // groupedByIDsは以下のようなオブジェクトになる
 *  {
 *    '1': [
 *      { id: 1, name: 'Object1' },
 *      { id: 1, name: 'Object3' }
 *    ],
 *    '2': [
 *      { id: 2, name: 'Object2' },
 *      { id: 2, name: 'Object5' }
 *    ],
 *    '3': [
 *      { id: 3, name: 'Object4' }
 *    ]
 *  }
 */
export function groupBy<T>(array: T[], key: keyof T): Record<string, T[]> {
  return array.reduce((result: Record<string, T[]>, obj: T) => {
    const keyValue = String(obj[key]);
    (result[keyValue] = result[keyValue] || []).push(obj);
    return result;
  }, {});
}

/**
 * 昇順且つ NULL は末尾とする判定する関数
 *
 * @param baseA 対象
 * @param baseB 比較対象
 * @param key 並び替え項目
 * @returns 順序
 */
export function compareAscWithNullsLast<T>(baseA: T, baseB: T, key: keyof T) {
  const [a, b] = [baseA[key], baseB[key]];
  if (!a && b) return 1;
  if (a && !b) return -1;
  if (a === b) return 0;
  return a > b ? 1 : -1;
}
