import classNames from "classnames";
import { ComponentProps, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import Modal from "../Modal";
import styles from "./InformationModal.module.css";
import Informations from "./Informations";

type Props = ComponentProps<typeof ReactModal> & {
  isAuthed?: boolean;
};

/**
 * このコンポーネントの責務は Information を表示するモーダルを提供すること
 */
const InformationModal: FC<Props> = ({ isAuthed, ...rest }) => {
  const { t } = useTranslation();
  const [isContentFit, setIsContentFit] = useState<boolean>();

  return (
    <Modal
      className={classNames(isContentFit && styles.fit)}
      {...rest}
      title={t("Information")}
    >
      <Informations
        isAuthed={isAuthed}
        setHeightContentFit={(flag) => setIsContentFit(flag)}
      />
    </Modal>
  );
};
export default InformationModal;
