import { FC, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GAPagePathTracking from "./GAPagePathTracking";
import { Layout } from "./layout";
import lazyWithPrefetch from "./lazyWithPrefetch";
import relativePathToRouteKey from "./relativePathToRouteKey";
import ScrollToTop from "./ScrollToTop";
import ShowOfflineModal from "./ShowOfflineModal";
import DetectBrowserBack from "./DetectBrowserBack";

/**
 * このコンポーネントの責務は各画面を現在の URL に合わせて表示すること
 */
const PageRouter: FC = () => {
  // src/pages 以下のファイルツリーから、全ページコンポーネント定義をリストップ
  const context = require.context(
    "../../pages",
    true,
    /^\.(\/[a-z0-9-]+|\/_[a-zA-Z0-9]+)+\.[jt]sx?$/,
    "lazy"
  );

  // 定義済みの全ページコンポーネントをルーティング情報に変換する
  const routeProps = context.keys().map((contextKey) => {
    const routeKey = relativePathToRouteKey(contextKey);
    const loader = () => context(contextKey);
    return { routeKey, loader };
  });

  // URL がどの画面にも当てはまらない場合は 404 ページを表示する
  routeProps.push({ routeKey: "*", loader: () => import("../../pages/_404") });

  // 全ルーティング情報から react-router-dom の <Route> 要素を生成する
  const routes = routeProps.map(({ routeKey, loader }) => {
    const D = lazyWithPrefetch(loader);
    return <Route key={routeKey} path={routeKey} element={<D />} />;
  });

  // オフラインモーダルが初期展開か把握する
  // オフラインからオンラインに変更した場合、当コンポーネントの子要素を再レンダリング
  const [offlineMounted, setOfflineMounted] = useState(false);

  return (
    <BrowserRouter>
      <DetectBrowserBack>
        <Layout>
          <GAPagePathTracking />
          <ScrollToTop />
          <ShowOfflineModal
            mounted={offlineMounted}
            onMounted={setOfflineMounted}
          />
          <Routes>{routes}</Routes>
        </Layout>
      </DetectBrowserBack>
    </BrowserRouter>
  );
};
export default PageRouter;
