import { StorageArea } from "kv-storage-polyfill";
import { WebStorage } from "redux-persist";

/**
 * redux-persist が扱えて、バッキングストアが KVStorage であるストレージを作成する
 */
export function createKvStorageAdapter() {
  // redux-persist 用のストレージを作成する
  const kvStorage = new StorageArea("redux-persist");

  // kv-storage を redux-persist 用のストレージとして使えるようにする
  const adapter: WebStorage = {
    getItem: (key) =>
      kvStorage.get<string, string>(key).then((value) => value ?? null),
    setItem: (key, value) => kvStorage.set(key, value),
    removeItem: (key) => kvStorage.delete(key),
  };

  return adapter;
}
