import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  UsedParts,
  WorkingTimes,
  ServiceReportDetailDetail,
  WorkOrderTask,
  ServiceReportWorker,
  ShipModel,
  ServiceReportDetail,
} from "../../models";
import { SerializedServiceReportHeaderDetail } from "../serializers/serviceReport";

type State = {
  equipments: ShipModel[];
  workOrderTasks: WorkOrderTask[];
  workers: ServiceReportWorker[];
  serviceReport: SerializedServiceReportHeaderDetail | null;
  usedParts: UsedParts[] | null;
  workingTimes: WorkingTimes[] | null;
  serviceReportDetails: ServiceReportDetail[];
  serviceReportDetail: ServiceReportDetailDetail | null;
};

/**
 * 指定サービスレポートID の明細跨ぎの使用部品を取得する
 */
const usedPartsByServiceReportId = createSelector(
  (state: State) => state.usedParts,
  (_state: State, serviceReportId: string) => serviceReportId,
  (usedParts, serviceReportId) =>
    usedParts?.filter((u) => u.serviceReportId === serviceReportId)
);

/**
 * 指定サービスレポートID の明細跨ぎの作業時間を取得する
 */
const workingTimesByServiceReportId = createSelector(
  (state: State) => state.workingTimes,
  (_state: State, serviceReportId: string) => serviceReportId,
  (workingTimes, serviceReportId) =>
    workingTimes?.filter((w) => w.serviceReportId === serviceReportId)
);

export const selectors = {
  usedPartsByServiceReportId,
  workingTimesByServiceReportId,
};

export const { actions, reducer } = createSlice({
  name: "service-report",
  initialState: {
    equipments: [],
    workOrderTasks: [],
    workers: [],
    serviceReport: null,
    usedParts: [],
    workingTimes: [],
    serviceReportDetails: [],
    serviceReportDetail: null,
  } as State,
  reducers: {
    equipmentsReceived(state, action: { payload: State["equipments"] }) {
      state.equipments = action.payload;
    },
    workOrderTasksReceived(
      state,
      action: { payload: State["workOrderTasks"] }
    ) {
      state.workOrderTasks = action.payload;
    },
    workOrderTasksReset(state) {
      state.workOrderTasks = [];
    },
    workersReceived(state, action: { payload: State["workers"] }) {
      state.workers = action.payload;
    },
    serviceReportUpdate(state, action: { payload: State["serviceReport"] }) {
      state.serviceReport = action.payload;
    },
    usedPartsUpdate(state, action: { payload: State["usedParts"] }) {
      state.usedParts = action.payload;
    },
    workingTimesUpdate(state, action: { payload: State["workingTimes"] }) {
      state.workingTimes = action.payload;
    },
    serviceReportDetailsUpdate(
      state,
      action: { payload: State["serviceReportDetails"] }
    ) {
      state.serviceReportDetails = action.payload;
    },
    serviceReportDetailUpdate(
      state,
      action: { payload: State["serviceReportDetail"] }
    ) {
      state.serviceReportDetail = action.payload;
    },
  },
});
