import { FC } from "react";
import classNames from "classnames";
import { ReactComponent as NumIcon } from "./ic_60_badge02_num.svg";
import { ReactComponent as AttentionIcon } from "./ic_60_badge02_attention.svg";
import styles from "./DraftAttentionBadge.module.css";

type Props = {
  badgeNumber?: number;
  isNumIcon?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

/**
 * このコンポーネントの責務は 下書き件数や下書きの有無を表示するバッジを提供すること
 */
const DraftAttentionBadge: FC<Props> = ({
  badgeNumber,
  isNumIcon,
  className,
}) => {
  return (
    <span className={classNames(styles.host, className)}>
      {badgeNumber !== undefined ? (
        <>
          {badgeNumber > 0 ? (
            <>
              <NumIcon />
              <span className={styles.badge}>{badgeNumber}</span>
            </>
          ) : null}
        </>
      ) : (
        <>{isNumIcon ? <NumIcon /> : <AttentionIcon />}</>
      )}
    </span>
  );
};
export default DraftAttentionBadge;
