import { GetWorkOrdersSortEnum } from "../webapi/generated";
import {
  WorkOrder as WebApiWorkOrder,
  WorkOrderDetail as WebApiWorkOrderDetail,
  WorkOrderDetailModels as WebApiWorkOrderModels,
  WorkOrderTask as WebApiWorkOrderTask,
} from "../webapi/generated/models";

export type WorkOrder = WebApiWorkOrder;
export type WorkOrderDetail = WebApiWorkOrderDetail;
export type WorkOrderModels = WebApiWorkOrderModels;
export type ModifiedWorkOrder = WorkOrder & {
  reportable?: boolean;
};
export type WorkOrderSortType = `${GetWorkOrdersSortEnum}`;

export function convertWorkOrderSortQuery(query?: string) {
  switch (query) {
    case GetWorkOrdersSortEnum.ReceptionDateAsc:
      return GetWorkOrdersSortEnum.ReceptionDateAsc;
    case GetWorkOrdersSortEnum.ReceptionDateDesc:
      return GetWorkOrdersSortEnum.ReceptionDateDesc;
    default:
      return GetWorkOrdersSortEnum.ReceptionDateDesc;
  }
}
export type WorkOrderTask = WebApiWorkOrderTask;
