import {
  AppErrorNoSearchResult,
  AppErrorSearchLimitExceeded,
} from "../../models/error";
import store, { actions, selectors } from "../../store";
import { withLanguageHeader } from "../../webapi";
import {
  GetMemosRequest,
  GetWorkOrdersRequest,
  GetServiceOrdersRequest as ServiceOrderSearchParams,
  GetShipsRequest as ShipSearchParams,
} from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";
import {
  convertServiceOrderSortQuery,
  FavoriteShips,
  memoType,
  memoSearchTypeName,
  SearchMemo,
  SearchMemoWithBreadcrumbs,
  ServiceOrderSortType,
} from "../../models";
import { getMemoTransitionParams, getSearchMemoBreadcrumbs } from "./memo";
import { sapliUsers } from "..";

export async function searchShips(params: ShipSearchParams) {
  const webapi = await getAuthedWebapi();

  const { ships } = await webapi.getShips(params);
  throwSearchErrorByLength(ships.length);

  const { setting, bookmark } = store.getState();
  const bookmarkShips = selectors.bookmark.customSelectors.shipsByLanguage(
    bookmark,
    setting.language
  );

  const mergedShips = ships.map((ship) => {
    const bookmarked = bookmarkShips.find((bs) => bs.fShipNo === ship.fShipNo);
    return {
      ...ship,
      isBookmarked: !!bookmarked,
      hasDraft: !!(
        bookmarked?.hasShipMemoDrafts ||
        bookmarked?.hasDiagramMemoDrafts ||
        bookmarked?.hasEquipmentMemoDrafts ||
        bookmarked?.hasServiceReportDrafts ||
        bookmarked?.hasServiceReportMemoDrafts
      ),
    };
  });

  store.dispatch(actions.ship.shipsReceived(mergedShips));
}

export async function fetchDocumentSearchConditions() {
  const webapi = await getAuthedWebapi();

  const [modelTypes, categories, languages] = await Promise.all([
    // modelTypes
    (async () => {
      const { models } = await webapi.getModels();
      const types = models.map(({ modelType }) => modelType).filter((x) => x);
      const uniqued = Array.from(new Set(types));
      return uniqued;
    })(),

    // categories
    (async () => {
      const { categories } = await webapi.getDocumentCategories();
      return categories;
    })(),

    // languages
    (async () => {
      const { languages } = await webapi.getDocumentLanguages();
      return languages;
    })(),
  ]);

  return { modelTypes, categories, languages };
}

export async function fetchServiceOrderSearchConditions() {
  const webapi = await getAuthedWebapi();

  const [modelTypes, users] = await Promise.all([
    // modelTypes
    (async () => {
      const { models } = await webapi.getModels();
      const types = models.map(({ modelType }) => modelType).filter((x) => x);
      const uniqued = Array.from(new Set(types));
      return uniqued;
    })(),

    // sapliUsers
    (async () => {
      const users = await sapliUsers.fetchSapliUsers(false);
      return users;
    })(),
  ]);

  return {
    modelTypes: modelTypes,
    sapliUsers: users,
  };
}

export async function searchServiceOrders(params: ServiceOrderSearchParams) {
  const webapi = await getAuthedWebapi();

  const {
    serviceOrders,
    searchResultLimitExceeded,
  } = await webapi.getServiceOrders(params);

  if (searchResultLimitExceeded) {
    throw new AppErrorSearchLimitExceeded(
      "search process was interrupted because search limit was exceeded"
    );
  }

  throwSearchErrorByLength(serviceOrders.length);

  store.dispatch(actions.serviceOrder.serviceOrdersReceived(serviceOrders));
}

export async function fetchServiceOrders(
  shipId: string,
  sort: ServiceOrderSortType
) {
  const webapi = await getAuthedWebapi();

  const { serviceOrders } = await webapi.getServiceOrders({
    fShipNo: shipId,
    sort: convertServiceOrderSortQuery(sort),
  });

  return serviceOrders;
}

export async function fetchServiceOrder(
  serviceOrderId: string,
  language: "en" | "ja"
) {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const serviceOrder = await withLang.getServiceOrdersOrderId({
    serviceOrderNo: serviceOrderId,
  });

  return serviceOrder;
}

/**
 * 検索_ワークオーダー一覧取得
 *
 * @param params 検索条件
 */
export async function searchWorkOrders(params: GetWorkOrdersRequest) {
  const webapi = await getAuthedWebapi();

  const { workOrders, searchResultLimitExceeded } = await webapi.getWorkOrders(
    params
  );

  if (searchResultLimitExceeded) {
    throw new AppErrorSearchLimitExceeded(
      "search process was interrupted because search limit was exceeded"
    );
  }

  throwSearchErrorByLength(workOrders?.length || 0);

  store.dispatch(actions.workOrder.workOrdersReceived(workOrders || []));
}

export async function fetchFavoriteShips(
  language: string
): Promise<FavoriteShips[]> {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const { favoriteShips } = await withLang.getFavoriteShips();

  const { setting, bookmark } = store.getState();
  const bookmarkShips = selectors.bookmark.customSelectors.shipsByLanguage(
    bookmark,
    setting.language
  );

  const mergedShips = favoriteShips.map((category) => {
    const ships = category.ships.map((ship) => {
      const bookmarked = bookmarkShips.find(
        (bs) => bs.fShipNo === ship.fShipNo
      );
      return {
        ...ship,
        isBookmarked: !!bookmarked,
        hasDraft: !!(
          bookmarked?.hasShipMemoDrafts ||
          bookmarked?.hasDiagramMemoDrafts ||
          bookmarked?.hasEquipmentMemoDrafts ||
          bookmarked?.hasServiceReportDrafts ||
          bookmarked?.hasServiceReportMemoDrafts
        ),
      };
    });

    return {
      ...category,
      ships,
    };
  });

  return mergedShips;
}

/**
 * メモ検索処理
 *
 * @param params 検索条件
 * @param withValidate エラーハンドリングの実行フラグ 初期値：true
 */
export async function searchMemos(
  params: GetMemosRequest,
  withValidate = true
): Promise<SearchMemoWithBreadcrumbs[]> {
  const webapi = await getAuthedWebapi();
  const { memos } = await webapi.getMemos(params);
  withValidate && throwSearchErrorByLength(memos.length);

  // 各メモのパンくずリストを作成
  const memosWithBreadcrumbs = memos.map((memo) => {
    const breadcrumbs = getSearchMemoBreadcrumbs(memo);
    const files = (memo.files || []).map((file) => ({
      ...file,
      available: true,
    }));
    return { ...memo, breadcrumbs, files };
  });

  return memosWithBreadcrumbs;
}

/**
 * メモ検索の検索条件を取得
 */
export async function fetchMemoSearchConditions(language: "en" | "ja") {
  const webapi = await getAuthedWebapi();

  const [modelTypes, categories] = await Promise.all([
    // modelTypes
    (async () => {
      const { models } = await webapi.getModels();

      const types = models.map(({ modelType }) => modelType).filter((x) => x);
      const uniqued = Array.from(new Set(types));

      return uniqued;
    })(),

    // categories
    (async () => {
      const { categories } = await webapi.getCategories();

      const converted = categories.map((category) => ({
        value: category.id.toString(),
        text: language === "en" ? category.nameEn : category.nameJa,
      }));

      return converted;
    })(),
  ]);

  // memoTypes
  let memoTypes = Object.keys(memoType).map((key) => {
    const memoTypeKey = key as keyof typeof memoType;
    return {
      value: memoType[memoTypeKey].toString(),
      text: memoSearchTypeName[memoType[memoTypeKey]],
    };
  });
  // ph7: サービスレポートのメモを検索条件から除外
  memoTypes = memoTypes.filter(
    (mt) => mt.value !== memoType.SERVICE_REPORT.toString()
  );

  return { modelTypes, categories, memoTypes };
}

/**
 * メモ検索画面から特定のメモを表示するためのパスを取得
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param type メモ種別
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @returns
 */
export async function getMemoTransitionPath(
  shipId: SearchMemo["fShipNo"],
  memoId: SearchMemo["id"],
  type: SearchMemo["type"],
  diagramId: SearchMemo["diagramId"],
  equipmentId: SearchMemo["equipmentId"],
  serviceReportId: SearchMemo["serviceReportId"]
) {
  const params = await getMemoTransitionParams(
    shipId,
    memoId!,
    diagramId,
    equipmentId,
    serviceReportId
  );

  let path = "";
  switch (type) {
    case memoType.SHIP:
      path = `/ships/${shipId}/notifications`;
      break;
    case memoType.DIAGRAM:
      path = `/ships/${shipId}/diagrams/${diagramId}`;
      break;
    case memoType.EQUIPMENT:
      path = `/ships/${shipId}/models/${equipmentId}/units`;
      break;
    case memoType.SERVICE_REPORT:
      path = `/ships/${shipId}/service-reports/${serviceReportId}`;
      break;
    default:
      break;
  }

  return path + "?" + params;
}

export function throwSearchErrorByLength(length: number): void {
  if (length === 0) throw new AppErrorNoSearchResult();
  if (length > 300)
    throw new AppErrorSearchLimitExceeded(
      `upper limit is 200, but result size: ${length}`
    );
}

/**
 * 検索条件からクエリ文字列を生成
 *
 * @param data 検索条件
 * @param searched
 */
export function createQueryFromSearchParams(
  data: QueryRecord,
  searched?: boolean
): string {
  const entries = convertRecordToTuple(data);

  if (searched) {
    entries.push(["searched", "true"]);
  }

  return "?" + new URLSearchParams(entries);
}

/**
 * 連想配列をタプルに変換
 */
function convertRecordToTuple(data: QueryRecord): [string, string][] {
  const entries = Object.entries(data).reduce<[string, string][]>(
    (accu, [k, v]) =>
      !v
        ? accu
        : Array.isArray(v)
        ? [...accu, ...v.map<[string, string]>((x) => [k, x])]
        : [...accu, [k, v]],
    []
  );
  return entries;
}

type QueryRecord = Record<string, string | string[] | undefined>;
