import type { FC, HTMLAttributes } from "react";
import Button from "../../Button";
import styles from "./FavoriteButton.module.css";
import { ReactComponent as FavoriteOffIcon } from "./btn_100_favorite_off.svg";
import { ReactComponent as FavoriteOnIcon } from "./btn_100_favorite_on.svg";

type Props = HTMLAttributes<HTMLButtonElement> & {
  isFavorite?: boolean;
};

/**
 * このコンポーネントの責務は Header 上にお気に入りダイアログの開閉とお気に入り登録状態を示すボタンを表示すること
 */
const FavoriteButton: FC<Props> = ({ isFavorite, ...rest }) => {
  return (
    <Button
      className={styles.host}
      {...rest}
      aria-label={
        isFavorite
          ? "registered-favorite-button"
          : "unregistered-favorite-button"
      }
    >
      {isFavorite ? <FavoriteOnIcon /> : <FavoriteOffIcon />}
    </Button>
  );
};
export default FavoriteButton;
