import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { ErrorValue } from "../../../models/error";
import Header from "../../Header";
import LayoutGamma from "../../LayoutGamma";
import { ReactComponent as Icon } from "../../NotFound/img_error.svg";
import styles from "./SystemError.module.css";

type Props = {
  errors?: ErrorValue[];
};

/**
 * このコンポーネントの責務はシステムエラー画面を提供する
 */
const SystemError: FC<Props> = ({ errors = [] }) => {
  const { t } = useTranslation();

  return (
    <LayoutGamma header={<Header />}>
      <div className={styles.logo}>
        <Icon />
        <div className={styles.title}>{t("System Error")}</div>
      </div>
      <div className={styles.errors}>
        {errors.map((error, index) => (
          <div key={JSON.stringify({ error, index })}>
            <div>Time: {error.time}</div>
            <div>Type: {error.title}</div>
            <div>URL: {error.url}</div>
            <div>User: {error.user}</div>
            <div>Version: {error.version}</div>
            <div>Detail:</div>
            <pre>
              <code>{error.detail}</code>
            </pre>
          </div>
        ))}
      </div>
    </LayoutGamma>
  );
};
export default SystemError;
