import { forwardRef, SelectHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./Native.module.css";

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Array<{ value: string; text?: string }>;
  invalid?: boolean;
};

/**
 * このコンポーネントの責務は選択肢の中から項目を一つ選ぶ機能を標準の select をベースにして提供すること
 */
const Native = forwardRef<HTMLSelectElement, Props>(
  ({ options, invalid, ...rest }, ref) => {
    return (
      <select
        className={classNames(styles.host, invalid && styles.invalid)}
        {...rest}
        ref={ref}
      >
        {options.map(({ value, text = value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
    );
  }
);
export default Native;
