import classNames from "classnames";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import AccountMenu from "./AccountMenu";
import GoBack from "./GoBack";
import styles from "./Header.module.css";
import { ReactComponent as LogoIcon } from "./img_logo_title.svg";
import Information from "./Information";
import BookmarkButton from "./BookmarkButton";
import { WithBookmark } from "../../models";
import FavoriteButton from "./FavoriteButton";
import MentionButton from "./MentionButton";
import GoToDashboard from "./GoToDashboard";

type Props = {
  override?: ReactNode;
  onBack?: () => void;
  showGoToDashboard?: boolean;
  showInformation?: boolean;
  showAccountMenu?: boolean;
  showBookmark?: WithBookmark;
  showFavorite?: number | null;
  showMention?: boolean;
  onBookmarkAdd?: () => void;
  onBookmarkRemove?: () => void;
  onFavoriteButtonClick?: () => void;
};

/**
 * このコンポーネントの責務はアプリケーションの共通ヘッダーを与えること
 */
const Header: FC<Props> = ({
  override = <LogoIcon aria-label="logo" />,
  onBack,
  showGoToDashboard,
  showInformation,
  showAccountMenu,
  showBookmark,
  showFavorite,
  showMention,
  onBookmarkAdd,
  onBookmarkRemove,
  onFavoriteButtonClick,
}) => {
  const env = getEnvironment();
  const { t } = useTranslation();
  const hasDraftMessage = t("Cannot unbookmark because you have a draft.");

  return (
    <div
      className={classNames(styles.host, env !== "" && styles.showEnvironment)}
    >
      <div className={styles.left}>
        {onBack && <GoBack className={styles.goBack} onClick={onBack} />}
        {/** オフライン時は未定義 */}
        {showFavorite !== undefined && (
          <FavoriteButton
            isFavorite={showFavorite !== null}
            onClick={onFavoriteButtonClick}
          />
        )}
        {showBookmark && (
          <BookmarkButton
            {...showBookmark}
            onBookmarkAdd={onBookmarkAdd}
            onBookmarkRemove={() => {
              if (showBookmark.hasDraft) {
                window.alert(t(hasDraftMessage));
              } else {
                onBookmarkRemove?.();
              }
            }}
          />
        )}
        <div className={styles.override}>{override}</div>
      </div>
      <div className={styles.center}>
        {env ? (
          <div className={styles.environment} aria-label="environment">
            - {env} -
          </div>
        ) : null}
      </div>
      <div className={styles.right}>
        {showGoToDashboard && <GoToDashboard />}
        {showInformation && <Information />}
        {showMention && <MentionButton />}
        <div className={styles.separator} />
        {showAccountMenu && <AccountMenu />}
      </div>
    </div>
  );
};
export default Header;

function getEnvironment(): string {
  switch (process.env.REACT_APP_ENV) {
    case "stg":
      return "Staging";
    case "dev":
      return "Development";
    case "qa":
      return "QA";
    case "local":
      return "Local";
    default:
      return "";
  }
}
