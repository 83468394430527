import type { License } from "../../models/eula";
import store from "../../store";
import { getAuthedWebapi } from "./custom-webapi";

/**
 * 最新利用規約取得 API 実行処理
 *
 * ※ オフライン時、処理しない
 *
 * @returns
 */
export async function fetchLicense() {
  const { connection } = store.getState().setting;
  if (!connection) return;

  const webapi = await getAuthedWebapi();

  const response = (await webapi.getUserEndUserLicenseVersionsLatestRaw()).raw;
  if (!response.ok) throw new Error("HTTP Error in EULA check");

  const data: License = await response.json();
  if (data.agreed || !data.isFirstTime) enableGoogleAnalytics();
  return data;
}

export async function needToAgreeLicenseFirstTime() {
  const webapi = await getAuthedWebapi();

  const response = (await webapi.getUserEndUserLicenseVersionsLatestRaw()).raw;
  if (!response.ok) throw new Error("HTTP Error in EULA check");

  const data = await response.json();
  if (data.agreed === true) return false;

  return Boolean(data.isFirstTime);
}

export async function agreeLatestLicense() {
  const webapi = await getAuthedWebapi();
  const license = await fetchLicense();
  if (license!.agreed === false) {
    await webapi.postUserEndUserLicenseAgreements();
  }
  enableGoogleAnalytics();
}

function enableGoogleAnalytics() {
  localStorage.setItem("ga-opt-in", "true");
  (window as any)[
    "ga-disable-" + process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  ] = false;
}
