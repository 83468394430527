export type License = {
  isFirstTime: boolean;
  agreed: boolean;
  endUserLicenseVersion: Version;
};

export type Version = {
  id: number;
  urlEn: string;
  urlJa: string;
};

export function termsOfUseLink(lang: string, version?: Version) {
  if (version) return lang === "en" ? version.urlEn : version.urlJa;
  if (lang === "en") return "https://www.furuno.co.jp/en/terms/";
  return "https://www.furuno.co.jp/terms/";
}

export function privacyPolicyLink(lang: string) {
  if (lang === "en") return "https://www.furuno.co.jp/en/privacy/";
  return "https://www.furuno.co.jp/privacy/";
}
