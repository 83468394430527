import { bookmark, bookmarkStorage, draft } from ".";
import {
  AppErrorLoginSessionExpiration,
  AppOfflineAttention,
} from "../../models/error";
import store, { actions } from "../../store";
import { selectors } from "../../store/modules";
import type { PartialDeps } from "../types";
import { getAuthedWebapi } from "./custom-webapi";
import * as eula from "./eula";

export async function getAccessToken() {
  const rejectedConnect = selectors.setting.customSelectors.rejectedConnectSelector(
    store.getState().setting
  );
  if (rejectedConnect) return "";

  const { Auth } = await importAmplifyAuth();

  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  const jwtToken = idToken.getJwtToken();
  return jwtToken;
}

/**
 * 連続した SAPLi 利用であること (= 前回の操作から 4 時間以上経過していない) を確認する
 * @throws {AppErrorLoginSessionExpiration}
 */
export async function ensureContinuousOperation() {
  const { latestOperationTime: prev, connection } = store.getState().setting;
  // 前回の操作から 4 時間以上経過しているかどうかチェックする
  const limit = 60 * 60 * 1000 * 4;
  const now = Date.now();
  if (prev && now > prev + limit && connection) {
    throw new AppErrorLoginSessionExpiration();
  }
  // 今回の操作した時間を記録する
  store.dispatch(actions.setting.operated(now));
}

/**
 * 認証済みかを確認する
 * @description
 * セッションや各トークンが有効かではなくトークンを所有しているかを確認
 * @throws {AppErrorLoginSessionExpiration}
 */
export async function ensureLoggedIn() {
  try {
    await getAccessToken();
  } catch {
    throw new AppErrorLoginSessionExpiration();
  }
}

/**
 * SSO 実行関数
 */
export async function federatedSignIn() {
  if (!isOnline()) throw new AppOfflineAttention();
  const { Auth } = await importAmplifyAuth();
  await Auth.federatedSignIn({ customProvider: "Okta" });
}

/**
 * SSO 認証後の処理
 */
export async function execAfterFederatedSignIn() {
  store.dispatch(actions.setting.clearOperationHistory());
  const { Auth } = await importAmplifyAuth();
  await Auth.currentSession();
  return execAfterLogin;
}

/**
 * ログイン後の後処理
 *
 * @param message
 * @param navigate
 */
async function execAfterLogin(
  message: string,
  { navigate }: PartialDeps<"navigate">
) {
  const webapi = await getAuthedWebapi();

  // 最終ログイン日時を更新
  await webapi.putUserLastLoginAtRaw();

  // ログインユーザのIDを取得する
  const loggedInUserInfo = await webapi.getUser();

  // 既読メンションリストを削除
  store.dispatch(actions.readMention.mentionDeleted(loggedInUserInfo.id!));

  // 最終ログインユーザIDを取得する
  const { account, bookmark } = store.getState();
  const latestLoginUserId = account.user ? account.user.id : null;

  // ブックマークが存在するかを確認
  const hasBookmark =
    selectors.bookmark.shipsSelectors.selectIds(bookmark.ships).length > 0;

  // ブックマークが存在する かつ ユーザIDが一致しない場合
  if (
    hasBookmark &&
    latestLoginUserId &&
    loggedInUserInfo.id !== latestLoginUserId
  ) {
    if (window.confirm(message)) {
      await removePreviousUserCache();
    } else {
      logout();
      return false;
    }
  }

  // アカウント情報を保持する
  store.dispatch(
    actions.account.userReceived({
      id: loggedInUserInfo.id!,
      name: loggedInUserInfo.name,
      lastLoginAt: new Date(loggedInUserInfo.lastLoginAt!).toISOString(),
    })
  );

  // 初回ログインなどで利用規約同意がまだの場合は同意画面へ、それ以外はトップ画面へ飛ばす
  if (await eula.needToAgreeLicenseFirstTime()) {
    navigate("/end-user-license-agreement");
  } else {
    navigate("/");
  }

  return true;
}

/**
 * ログアウト処理
 */
export async function logout() {
  store.dispatch(actions.setting.clearOperationHistory());
  const { Auth } = await importAmplifyAuth();
  await Auth.signOut();
  // ログアウト済みの場合、signOut() でログイン画面へ遷移しないため
  window.location.href = "/login";
}

let amplifyAuthModule: Promise<typeof import("@aws-amplify/auth")>;
/** aws-amplify はサイズが大きいモジュールのため、遅延読み込みする */
function importAmplifyAuth() {
  if (amplifyAuthModule) return amplifyAuthModule;
  amplifyAuthModule = import("@aws-amplify/auth");

  amplifyAuthModule.then(({ Auth }) => {
    const config = {
      region: "ap-northeast-1",
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId:
        process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
        redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN_URL,
        redirectSignOut:
          process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT_URL,
        responseType: "code",
        scope: ["email", "openid"],
      },
      authenticationFlowType: "USER_PASSWORD_AUTH",
    };
    Auth.configure(config);
  });

  return amplifyAuthModule;
}

/**
 * 前回ログインしたユーザのキャッシュ(ブックマーク情報)を削除
 */
async function removePreviousUserCache() {
  await bookmarkStorage.deleteIDB();
  bookmark.removeAllBookmark();
  draft.removeAllDraft();
}

function isOnline() {
  const { setting } = store.getState();

  if (setting.connection) {
    if (!setting.userSelectMode) {
      store.dispatch(actions.setting.setUserMode(true));
    }
  } else {
    if (setting.userSelectMode) {
      store.dispatch(actions.setting.showModal(true));
      return false;
    }
  }

  return true;
}
