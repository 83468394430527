import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import styles from "./DownloadGate.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as LoadingIcon } from "./ic_64_loading.svg";
import classNames from "classnames";

const DownloadContext = createContext<{
  wait: (task: Promise<void>) => void;
}>({ wait: () => {} });

export function useDownloadContext() {
  return useContext(DownloadContext);
}

/**
 * このコンポーネントの責務はダウンロード処置を実行し、ダウンロード表示を行うこと
 */
const DownloadGate: FC = ({ children }) => {
  const { t } = useTranslation();
  const [processing, value] = useWaiter();
  return (
    <>
      <DownloadContext.Provider value={value}>
        {children}
      </DownloadContext.Provider>
      <div className={classNames(styles.loading, !processing && styles.hidden)}>
        <div className={styles.spinner}>
          <LoadingIcon />
        </div>
        {t("Now Downloading…")}
      </div>
    </>
  );
};

export default DownloadGate;

function useWaiter() {
  const [tasks, setTasks] = useState<Promise<void>[]>([]);

  const processing = tasks.length > 0;

  const wait = useCallback((task: Promise<void>) => {
    setTasks((ts) => [...ts, task]);

    task.finally(() => setTasks((ts) => ts.filter((t) => t !== task)));
  }, []);
  const value = useMemo(() => ({ wait }), [wait]);

  return [processing, value] as const;
}
