import classNames from "classnames";
import { FC } from "react";
import Button from "../../Button";
import ExternalLink from "../../ExternalLink";
import { ReactComponent as DeleteBtn } from "../Thumbnail/ic_40_close_wh.svg";
import styles from "./Link.module.css";

type Props = {
  url: string;
  download?: boolean;
  fileName: string;
  deletable?: boolean;
  disabled?: boolean;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
};

/**
 * このコンポーネントの責務は画像以外の添付ファイルのリンクを表示すること
 */
const Link: FC<Props> = ({
  url,
  fileName,
  deletable,
  disabled,
  onDeleteClick,
  onDownloadClick,
}) => {
  return (
    <div key={url} className={styles.host}>
      {onDownloadClick ? (
        <a
          className={classNames(styles.link, disabled && styles.disabled)}
          href={url}
          onClick={(e) => {
            e.preventDefault();
            !disabled && onDownloadClick();
          }}
        >
          {fileName}
        </a>
      ) : (
        <ExternalLink
          className={classNames(styles.link, disabled && styles.disabled)}
          to={url}
        >
          {fileName}
        </ExternalLink>
      )}
      {deletable && (
        <Button
          className={styles.deleteButton}
          onClick={onDeleteClick}
          aria-label="delete attachment file"
        >
          <DeleteBtn />
        </Button>
      )}
    </div>
  );
};
export default Link;
