import { PersistedRootState } from ".";
import { adapters } from "../modules";
import { PersistedRootStateV4 } from "./v4";

/**
 * v5 (ph8.3 以降のステート)
 */
export type PersistedRootStateV5 = PersistedRootState;

/**
 * v5 移行
 *
 * @param state v4 のステート定義
 * @returns v5 のステート定義
 */
function migrate(state: PersistedRootStateV4): PersistedRootStateV5 {
  const { draft } = adapters;

  return {
    ...state,
    draft: {
      ...state.draft,
      serviceReportDetailSignatures: draft.serviceReportDetailSignaturesAdapter.getInitialState(),
    },
  };
}

export default migrate;
