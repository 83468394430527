import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import {
  BookmarkShip,
  BookmarkShipAppendix,
  BookmarkShipAppendixUpdate,
  BookmarkShipCombine,
  BookmarkShipUpdate,
  CacheKey,
  CacheKeyUpdated,
  getBookmarkShipId,
} from "../../models";

type State = {
  ships: EntityState<BookmarkShip>;
  shipAppendices: EntityState<BookmarkShipAppendix>;
  cacheKeys: EntityState<CacheKey>;
};

const shipsAdapter = createEntityAdapter<BookmarkShip>({
  selectId: (ship) => getBookmarkShipId(ship.fShipNo, ship.language),
});
const shipsSelectors = shipsAdapter.getSelectors();

const shipAppendicesAdapter = createEntityAdapter<BookmarkShipAppendix>({
  selectId: (ship) => ship.fShipNo,
});
const shipAppendicesSelectors = shipAppendicesAdapter.getSelectors();

const cacheKeysAdapter = createEntityAdapter<CacheKey>({
  selectId: (ship) => ship.fShipNo,
});
const cacheKeysSelectors = cacheKeysAdapter.getSelectors();

/**
 * 言語別にブックマーク登録済みの船舶一覧取得
 */
const shipsByLanguage = createSelector(
  (state: State) => shipsSelectors.selectAll(state.ships),
  (state: State) => shipAppendicesSelectors.selectAll(state.shipAppendices),
  (_state: State, language: string) => language,
  (ships, shipAppendices, language) =>
    ships
      .filter((ship) => ship.language === language)
      .map(
        (ship) =>
          ({
            ...ship,
            ...shipAppendices.find(
              (appendix) => appendix.fShipNo === ship.fShipNo
            ),
          } as BookmarkShipCombine)
      )
);

/**
 * 言語別にブックマーク登録済みの船舶取得
 */
const shipByLanguage = createSelector(
  (state: State, props: { fShipNo: string; language: string }) => {
    const ship = shipsSelectors.selectById(
      state.ships,
      getBookmarkShipId(props.fShipNo, props.language)
    );
    const appendix = shipAppendicesSelectors.selectById(
      state.shipAppendices,
      props.fShipNo
    );
    return { ship, appendix };
  },
  ({ ship, appendix }): BookmarkShipCombine | undefined =>
    ship && appendix ? { ...ship, ...appendix } : undefined
);

/**
 * 指定船舶キャッシュキーと他のキャッシュキーを比較し、重複を排除した URL 一覧を取得
 */
const uniqueTargetCacheUrls = createSelector(
  (state: State) => cacheKeysSelectors.selectAll(state.cacheKeys),
  (state: State, fShipNo: string) => ({
    targetCacheKey: cacheKeysSelectors.selectById(state.cacheKeys, fShipNo),
    fShipNo,
  }),
  (cacheKeys, { targetCacheKey, fShipNo }) => {
    const allCacheUrls = ([] as string[]).concat(
      ...cacheKeys
        .filter((cachKey) => cachKey.fShipNo !== fShipNo)
        .map((cacheKey) => cacheKey.urls)
    );
    if (!targetCacheKey) return allCacheUrls;
    return targetCacheKey.urls.filter((url) => !allCacheUrls.includes(url));
  }
);

export const adapters = {
  shipsAdapter,
  shipAppendicesAdapter,
  cacheKeysAdapter,
};

export const selectors = {
  shipsSelectors,
  shipAppendicesSelectors,
  cacheKeysSelectors,
  customSelectors: {
    shipsByLanguage,
    shipByLanguage,
    uniqueTargetCacheUrls,
  },
};

export const { actions, reducer } = createSlice({
  name: "bookmark",
  initialState: {
    ships: shipsAdapter.getInitialState(),
    shipAppendices: shipAppendicesAdapter.getInitialState(),
    cacheKeys: cacheKeysAdapter.getInitialState(),
  } as State,
  reducers: {
    shipAdded(state, action: { payload: BookmarkShip }) {
      shipsAdapter.addOne(state.ships, action.payload);
    },
    shipsUpdated(state, action: { payload: BookmarkShipUpdate }) {
      const { id, ship } = action.payload;
      shipsAdapter.updateOne(state.ships, { id, changes: ship });
    },
    shipsRemoved(state, action: { payload: BookmarkShip["fShipNo"] }) {
      shipsAdapter.removeOne(state.ships, action.payload);
    },
    shipsAllRemoved(state) {
      shipsAdapter.removeAll(state.ships);
    },
    shipAppendixAdded(state, action: { payload: BookmarkShipAppendix }) {
      shipAppendicesAdapter.addOne(state.shipAppendices, action.payload);
    },
    shipAppendixUpdated(
      state,
      action: { payload: BookmarkShipAppendixUpdate }
    ) {
      const { id, shipAppendix } = action.payload;
      shipAppendicesAdapter.updateOne(state.shipAppendices, {
        id,
        changes: shipAppendix,
      });
    },
    shipAppendixRemoved(
      state,
      action: { payload: BookmarkShipAppendix["fShipNo"] }
    ) {
      shipAppendicesAdapter.removeOne(state.shipAppendices, action.payload);
    },
    shipAppendixAllRemoved(state) {
      shipAppendicesAdapter.removeAll(state.shipAppendices);
    },
    cacheKeyAdded(state, action: { payload: CacheKey }) {
      cacheKeysAdapter.addOne(state.cacheKeys, action.payload);
    },
    cacheKeyUpdated(state, action: { payload: CacheKeyUpdated }) {
      const { id, key } = action.payload;
      cacheKeysAdapter.updateOne(state.cacheKeys, { id, changes: key });
    },
    cacheKeyRemoved(state, action: { payload: CacheKey["fShipNo"] }) {
      cacheKeysAdapter.removeOne(state.cacheKeys, action.payload);
    },
    cacheKeyAllRemoved(state) {
      cacheKeysAdapter.removeAll(state.cacheKeys);
    },
  },
});
