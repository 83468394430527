import type { FC } from "react";
import Button from "../../Button";
import { ReactComponent as Icon } from "./ic_60_backarrow_wh.svg";

type Props = {
  className?: string;
  onClick?: () => void;
};

/**
 * このコンポーネントの責務はヘッダーで戻るボタンを提供すること
 */
const GoBack: FC<Props> = ({ className, onClick }) => {
  return (
    <Button className={className} onClick={onClick} aria-label="go back">
      <Icon />
    </Button>
  );
};
export default GoBack;
