import i18n from "../../../i18n";
import { AppErrorNotLogin, AppOfflineAttention } from "../../../models/error";
import store from "../../../store";
import { actions } from "../../../store/modules";
import webapi, {
  withApiBearerToken,
  withLanguageHeader,
} from "../../../webapi";
import type { PartialDeps } from "../../types";
import { ensureContinuousOperation, getAccessToken } from "../auth";

export async function getAuthedWebapi() {
  if (!isOnline()) {
    throw new AppOfflineAttention();
  }

  await ensureContinuousOperation();

  const webapiWithLang = withLanguageHeader(webapi, i18n.language);
  try {
    const token = await getAccessToken();
    return withApiBearerToken(webapiWithLang, token);
  } catch {
    throw new AppErrorNotLogin("Not logged in");
  }
}

export async function getWebapi() {
  if (!isOnline()) {
    throw new AppOfflineAttention();
  }
  return withLanguageHeader(webapi, i18n.language);
}

/**
 * 与えた関数を実行し、もし未ログインでのエラーが発生したらログイン画面にリダイレクトする
 */
export async function catchNotLoginError(
  { navigate }: PartialDeps<"navigate">,
  f: () => Promise<void>
) {
  try {
    await f();
  } catch (e) {
    if (!(e instanceof AppErrorNotLogin)) throw e;
    navigate("/login");
  }
}

export function isOnline() {
  const { setting } = store.getState();

  if (setting.connection) {
    if (!setting.userSelectMode) {
      store.dispatch(actions.setting.setUserMode(true));
    }
  } else {
    if (setting.userSelectMode) {
      store.dispatch(actions.setting.showModal(true));
      return false;
    }
  }

  return true;
}
