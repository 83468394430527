import classNames from "classnames";
import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import {
  License,
  privacyPolicyLink,
  termsOfUseLink,
} from "../../../models/eula";
import { eula } from "../../../usecases";
import Button from "../../Button";
import ExternalLink from "../../ExternalLink";
import Modal from "../../Modal";
import { ReactComponent as Icon } from "../img_information.svg";
import styles from "./TermsUpdateModal.module.css";

type Props = Omit<ComponentProps<typeof ReactModal>, "isOpen"> & {};

/**
 * このコンポーネントの責務は TermsUpdate 画面のモーダルを提供すること
 */
const TermsUpdateModal: FC<Props> = ({ className, ...rest }) => {
  const { t, i18n } = useTranslation();

  const [license, agree] = useLicense();

  return (
    <Modal
      className={classNames(styles.host, className)}
      {...rest}
      isOpen={license !== undefined && license.agreed === false}
    >
      <div className={styles.form}>
        <Icon />
        <div className={styles.title}>{t("Terms Update")}</div>
        <div>
          <span>{t("eula.1_prefix")}</span>
          <ExternalLink
            to={termsOfUseLink(i18n.language, license?.endUserLicenseVersion)}
          >
            {t("eula.2_terms")}
          </ExternalLink>
          <span>{t("eula.3_and")}</span>
          <ExternalLink to={privacyPolicyLink(i18n.language)}>
            {t("eula.4_policy")}
          </ExternalLink>
          <span>{t("eula.5_suffix")}</span>
        </div>
        <Button className={styles.agreeButton} onClick={agree}>
          {t("Agree")}
        </Button>
      </div>
    </Modal>
  );
};
export default TermsUpdateModal;

function useLicense() {
  const [license, setLicense] = useState<License>();

  useEffect(() => {
    eula.fetchLicense().then(setLicense);
  }, []);

  const agree = useCallback(() => {
    (async () => {
      await eula.agreeLatestLicense();
      const latestLicense = await eula.fetchLicense();
      setLicense(latestLicense);
    })();
  }, []);

  return [license, agree] as const;
}
