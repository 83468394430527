import { ReadMention } from "../../models/read-mention";
import store from "../../store";

/**
 * 受け取ったメンションが未読か既読か判定する
 *
 * 未読ならTRUE、既読ならFALSEを返す
 */
export const isUnread = (mention: ReadMention) => {
  const readMentions = store.getState().readMention.readMentions;

  // equipmentIdはstring型とnumber型が混在するので型判定を行いnumber型に統一して比較する
  const equipmentId =
    mention.equipmentId && typeof mention.equipmentId !== "number"
      ? Number(mention.equipmentId)
      : mention.equipmentId;

  const matchMention = readMentions.filter(
    (read) =>
      // eslint-disable-next-line eqeqeq
      read.memoId == mention.memoId &&
      // eslint-disable-next-line eqeqeq
      read.equipmentId == equipmentId &&
      read.id === mention.id &&
      read.userId === mention.userId &&
      read.serviceReportId === mention.serviceReportId &&
      // eslint-disable-next-line eqeqeq
      read.diagramId == mention.diagramId
  );

  if (matchMention.length === 0) return true;

  const compareUpdatedAt = matchMention.every((read) => {
    const readUpdateAt = new Date(read.updatedAt);
    return readUpdateAt.getTime() < mention.updatedAt.getTime();
  });

  return compareUpdatedAt;
};
