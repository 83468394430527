import type { FC } from "react";
import { WithBookmark } from "../../../models";
import Button from "../../Button";
import DraftAttentionBadge from "../../DraftAttentionBadge";
import styles from "./BookmarkButton.module.css";
import { ReactComponent as BookmarkOffIcon } from "./btn_100_bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "./btn_100_bookmark_on.svg";

type Props = {
  onBookmarkAdd?: () => void;
  onBookmarkRemove?: () => void;
} & WithBookmark;

/**
 * このコンポーネントの責務は Header 上にブックマーク機能を有するボタンを表示すること
 */
const BookmarkButton: FC<Props> = ({
  isBookmarked,
  hasDraft,
  onBookmarkAdd,
  onBookmarkRemove,
}) => {
  return (
    <Button
      className={styles.host}
      onClick={isBookmarked ? onBookmarkRemove : onBookmarkAdd}
      aria-label={isBookmarked ? "remove-bookmark" : "add-bookmark"}
    >
      {isBookmarked ? <BookmarkOnIcon /> : <BookmarkOffIcon />}
      {hasDraft && <DraftAttentionBadge className={styles.badge} />}
    </Button>
  );
};
export default BookmarkButton;
