import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import Modal from "../Modal";
import styles from "./MentionModal.module.css";
import NotificationCard from "../ShipNotifications/NotificationCard";
import { memoRedirectFrom, MentionMemoWithBreadcrumbs } from "../../models";
import { useNavigate } from "react-router";
import { useBoundActions, useBoundSelector } from "../../store";
import { mention } from "../../usecases";
import { ReactComponent as ErrorIcon } from "./btn_60_error.svg";
import classNames from "classnames";
import { useProcessingContext } from "../Processing";
import { useMemoTransitionResetter } from "../MemoEditor/useMemoTransitionResetter";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

type Props = ComponentProps<typeof ReactModal> & {
  onRequestClose: () => void;
};

/**
 * このコンポーネントの責務は メンション通知モーダルを表示する
 */
const MentionModal: FC<Props> = ({ onRequestClose, isOpen, ...rest }) => {
  const blockMemoNavigation = useMemoNavigateToggle(isOpen);
  const onMemoTransition = useMemoTransition(blockMemoNavigation);
  const mentions = useFetchMentions(isOpen);
  const { t } = useTranslation();

  return (
    <Modal
      {...rest}
      title={t("Mention")}
      onRequestClose={onRequestClose}
      className={classNames(mentions.length === 0 && styles.fit)}
      isOpen={isOpen}
    >
      {mentions.length !== 0 ? (
        <div className={styles.list}>
          {mentions.map((mention, index) => {
            return (
              <NotificationCard
                notification={mention}
                key={index}
                className={styles.card}
                onMemoTransition={() => {
                  onMemoTransition(mention);
                  onRequestClose();
                }}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.noMention}>
          <ErrorIcon />
          <span>{t("There is no mention to display.")}</span>
        </div>
      )}
    </Modal>
  );
};
export default MentionModal;

/**
 * メモ遷移用関数
 */
function useMemoTransition(
  blockMemoNavigation: ActionCreatorWithPayload<boolean, string>
) {
  const navigate = useNavigate();
  const { site } = useBoundActions();
  const resetMemoTransition = useMemoTransitionResetter(1);

  return useCallback(
    (memo: MentionMemoWithBreadcrumbs) => {
      const task = async () => {
        resetMemoTransition();
        blockMemoNavigation(true);

        const currentPath = window.location.pathname + window.location.search;
        site.memoRedirectPathReceived({
          redirectPath: currentPath,
          redirectFrom: memoRedirectFrom.MENTION_MODAL,
        });

        const memoId = memo.isComment ? memo.memoId : memo.id;
        const transitionPath = await mention.getMemoTransitionPath(
          memo.fShipNo,
          memoId!,
          memo.type as number,
          memo.diagramId,
          memo.equipmentId,
          memo.serviceReportId
        );

        navigate(transitionPath);
      };

      task();
    },
    [navigate, site, resetMemoTransition, blockMemoNavigation]
  );
}

const useFetchMentions = (isOpen: boolean) => {
  const [mentions, setMentions] = useState<MentionMemoWithBreadcrumbs[]>([]);

  const { wait } = useProcessingContext();
  useEffect(() => {
    if (isOpen) {
      const task = (async () => {
        const memos = await mention.fetchMentions();
        setMentions(memos);
      })();
      wait(task);
    }
  }, [isOpen, wait]);
  return mentions;
};

function useMemoNavigateToggle(isOpen: Props["isOpen"]) {
  const when = useBoundSelector((state) => state.site.memo.useNavigateBlock);
  const { site } = useBoundActions();
  /**
   * 直前に指定されていた画面遷移制御の有無を保持する
   */
  const previousWhen = useRef<boolean>(false);

  useEffect(() => {
    if (isOpen) previousWhen.current = when;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      site.memoNavigateBlockerToggle(false);
    } else {
      site.memoNavigateBlockerToggle(previousWhen.current);
    }
  }, [isOpen, site, previousWhen]);

  return site.memoNavigateBlockerToggle;
}
