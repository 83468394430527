import {
  excludeQuery,
  isS3Url,
  getBookmarkShipId,
  convertPayloadByLanguageForDelete,
  convertPayloadByLanguageForUpsert,
  convertCacheTargetObject,
} from "../../models";
import type {
  BookmarkShip,
  UnknownResource,
  CacheKey,
  BookmarkShipAppendix,
  IDBDeletePayloadByLanguage,
  IDBUpsertPayloadByLanguage,
} from "../../models";
import { withLanguageHeader } from "../../webapi";
import { getAccessToken } from "./auth";
import { getAuthedWebapi } from "./custom-webapi";
import store, { actions, selectors } from "../../store";
import * as storage from "./bookmark-storage";
import { AppErrorFetchAborted } from "../../models/error";

const languages = ["en", "ja"] as const;

type LangCode = typeof languages[number];

/**
 * ブックマーク登録処理
 *
 * @description
 * 1. 言語別に船舶詳細取得 API を実行
 * 2. キャッシュ対象のリソースを取得
 * 3. 2 で取得したリソースをキャッシュ保存
 * 4. 上記の結果を Store へ登録
 *
 * @param shipId 船舶番号
 * @param signal
 */
export async function addBookmark(
  shipId: BookmarkShip["fShipNo"],
  signal?: AbortSignal
) {
  const ships = await Promise.all(
    languages.map((language) => fetchShipWithAnyLanguages(shipId, language))
  );
  const { resources, lastCacheUpdatedAt } = await getCacheResource(
    shipId,
    signal
  );

  await saveCache(convertPayloadByLanguageForUpsert(resources), signal);

  // 重複排除
  const resourceKeys = Array.from(new Set(resources.map((r) => r.key))).sort();

  dispatchBookmarkAdded(shipId, ships, resourceKeys, lastCacheUpdatedAt);
}

/**
 * ブックマーク削除処理
 *
 * @description
 * 1. Store から指定船舶の登録済みかつ他全てと重複のないキャッシュキー(URL)一覧を取得
 * 2. 1 で取得したキャッシュキーを用いてキャッシュを削除
 * 3. Store から指定船舶データを削除
 *
 * @param shipId 船舶番号
 */
export async function removeBookmark(shipId: BookmarkShip["fShipNo"]) {
  const cachedUrls = selectors.bookmark.customSelectors.uniqueTargetCacheUrls(
    store.getState().bookmark,
    shipId
  );
  await removeCache(convertPayloadByLanguageForDelete(cachedUrls));
  dispatchBookmarkRemoved(shipId);
}

/**
 * ブックマーク更新処理
 *
 * @description
 * 1. Store から指定船舶の登録済みキャッシュキー一覧を取得
 * 2. 言語別に船舶詳細取得 API を実行
 * 3. キャッシュ対象のリソースを取得
 * 4. 1 で取得したキャッシュキーと 3 で取得したURL一覧を比較し、重複しないものを抽出
 * 5. 4 で取得したキャッシュキーを用いてキャッシュを削除
 * 6. 3 で取得したリソースをキャッシュ保存
 * 7. 上記の結果を Store へ登録
 *
 * @param shipId 船舶番号
 * @param signal
 */
export async function updateBookmark(
  shipId: BookmarkShip["fShipNo"],
  signal?: AbortSignal
) {
  const cachedUrls = selectors.bookmark.customSelectors.uniqueTargetCacheUrls(
    store.getState().bookmark,
    shipId
  );
  const ships = await Promise.all(
    languages.map((language) => fetchShipWithAnyLanguages(shipId, language))
  );
  const { resources, lastCacheUpdatedAt } = await getCacheResource(
    shipId,
    signal
  );

  // 重複排除
  const resourceKeys = Array.from(new Set(resources.map((r) => r.key))).sort();

  const targetUrls = cachedUrls.filter((url) => !resourceKeys.includes(url));
  await removeCache(convertPayloadByLanguageForDelete(targetUrls), signal);

  await saveCache(convertPayloadByLanguageForUpsert(resources), signal);
  dispatchBookmarkUpdated(shipId, ships, resourceKeys, lastCacheUpdatedAt);
}

/**
 * ブックマークキャンセル処理
 *
 * @description
 * 1. 船舶関連URL取得 API 実行
 * 2. Store から登録済みのキャッシュキーを全て取得
 * 3. 1 で取得した URL 一覧と 2. で取得した登録済みのキャッシュキー(URL)一覧を比較し、未登録の URL を抽出
 * 4. 3 で取得した キャッシュキーを用いてキャッシュを削除
 *
 * @param shipId 船舶番号
 * @param targetUrls 船舶キャッシュ用 URL 一覧レスポンス
 */
export async function cancelBookmark(shipId: BookmarkShip["fShipNo"]) {
  const { urls } = await fetchShipUrls(shipId);

  const allCacheKeys = ([] as string[]).concat(
    ...selectors.bookmark.cacheKeysSelectors
      .selectAll(store.getState().bookmark.cacheKeys)
      .map((cacheKey) => cacheKey.urls)
  );

  const targetUrls = urls
    .map((url) => (isS3Url(url) ? excludeQuery(url) : url))
    .filter((url) => !allCacheKeys.includes(url));

  await removeCache(convertPayloadByLanguageForDelete(targetUrls));
}

/**
 * 指定船舶情報とキャッシュ登録キーを Store へ追加
 *
 * @param shipId 船舶番号
 * @param ships 言語別船舶情報
 * @param cacheUrls キャッシュ登録キー(URL)一覧
 * @param lastCacheUpdatedAt 最終キャッシュ更新日
 */
export function dispatchBookmarkAdded(
  shipId: BookmarkShip["fShipNo"],
  ships: BookmarkShip[],
  cacheUrls: CacheKey["urls"],
  lastCacheUpdatedAt: BookmarkShipAppendix["lastCacheUpdatedAt"]
) {
  ships.map((ship) => store.dispatch(actions.bookmark.shipAdded(ship)));
  store.dispatch(
    actions.bookmark.cacheKeyAdded({ fShipNo: shipId, urls: cacheUrls })
  );
  store.dispatch(
    actions.bookmark.shipAppendixAdded({
      fShipNo: shipId,
      lastCacheUpdatedAt,
      hasDiagramMemoDrafts: false,
      hasShipMemoDrafts: false,
      hasEquipmentMemoDrafts: false,
      hasServiceReportDrafts: false,
      hasServiceReportMemoDrafts: false,
    })
  );
}

/**
 * 指定船舶情報とキャッシュ登録キーを Store へ更新
 *
 * @param shipId
 * @param ships
 * @param cacheUrls
 * @param lastCacheUpdatedAt
 */
export function dispatchBookmarkUpdated(
  shipId: BookmarkShip["fShipNo"],
  ships: BookmarkShip[],
  cacheUrls: CacheKey["urls"],
  lastCacheUpdatedAt: BookmarkShipAppendix["lastCacheUpdatedAt"]
) {
  ships.map((ship) =>
    store.dispatch(
      actions.bookmark.shipsUpdated({
        id: getBookmarkShipId(shipId, ship.language),
        ship,
      })
    )
  );
  store.dispatch(
    actions.bookmark.cacheKeyUpdated({ id: shipId, key: { urls: cacheUrls } })
  );
  store.dispatch(
    actions.bookmark.shipAppendixUpdated({
      id: shipId,
      shipAppendix: { lastCacheUpdatedAt },
    })
  );
}

/**
 * 指定船舶情報と付録・登録済みキャッシュキーを Store から削除
 *
 * @param shipId
 */
export function dispatchBookmarkRemoved(shipId: BookmarkShip["fShipNo"]) {
  const keys = languages.map((language) => getBookmarkShipId(shipId, language));
  keys.forEach((key) => store.dispatch(actions.bookmark.shipsRemoved(key)));
  store.dispatch(actions.bookmark.shipAppendixRemoved(shipId));
  store.dispatch(actions.bookmark.cacheKeyRemoved(shipId));
}

/**
 * キャッシュ対象のリソース取得
 *
 * @description
 * 1. 船舶関連URL取得 API 実行
 * 2. 1 で取得した URL 一覧を用いてリソースを取得
 * 3. 1 で取得した 最終キャッシュ更新日と 2 で取得したキャッシュ対象のリソースを返す
 *
 * @param shipId 船舶番号
 * @param signal
 * @returns
 */
export async function getCacheResource(
  shipId: BookmarkShip["fShipNo"],
  signal?: AbortSignal
) {
  const { urls, timestamp } = await fetchShipUrls(shipId);
  const resources = await fetchCacheResources(urls, signal);
  return { resources, lastCacheUpdatedAt: timestamp };
}

/**
 * IndexedDB へ対象データの登録
 *
 * @description
 * 1. AbortController の signal から abort イベントを購読
 *    abort イベント発火（処理がキャンセル）された場合、reject して処理中断
 * 2. キャッシュ対象のリソースを kv-storage を使って IndexedDB へ登録
 * 3. 登録済みキャッシュキーを一覧で返す（resolve）
 *
 * @param resources
 * @param signal
 * @returns
 */
export async function saveCache(
  payload: IDBUpsertPayloadByLanguage,
  signal?: AbortSignal
) {
  return new Promise<boolean>(async (resolve, reject) => {
    let aborted = false;
    const onAbort = () => {
      if (aborted) return;
      aborted = true;
      signal?.removeEventListener("abort", onAbort);
      return reject();
    };
    signal?.addEventListener("abort", onAbort);

    await Promise.all(
      Object.keys(payload).map(async (language) => {
        const storageName = storage.createStorageName(language);
        return await storage.bulkPut(storageName, payload[language]);
      })
    );

    signal?.removeEventListener("abort", onAbort);
    return resolve(true);
  });
}

/**
 * IndexedDB へ登録済みのデータ削除
 *
 * @description
 * 0. 登録済みのキャッシュキー一覧から通常 URL 一覧と S3 系 URL 一覧を各生成
 * 1. AbortController の signal から abort イベントを購読
 *    abort イベント発火（処理がキャンセル）された場合、reject して処理中断
 * 2. IndexedDB から 通常 URL と S3 系 URL をキーにキャッシュを削除
 *
 * @param cachedUrls 登録済みキー（URL）一覧
 * @param signal
 */
export async function removeCache(
  payload: IDBDeletePayloadByLanguage,
  signal?: AbortSignal
) {
  return new Promise<boolean>(async (resolve, reject) => {
    let aborted = false;
    const onAbort = () => {
      if (aborted) return;
      aborted = true;
      signal?.removeEventListener("abort", onAbort);
      return reject(false);
    };
    signal?.addEventListener("abort", onAbort);

    await Promise.all(
      Object.keys(payload).map(async (language) => {
        const storageName = storage.createStorageName(language);
        return await storage.bulkDelete(storageName, payload[language]);
      })
    );

    signal?.removeEventListener("abort", onAbort);
    return resolve(true);
  });
}

/**
 * 言語毎に船舶詳細データの取得
 *
 * @description
 * 1. Fetch API のインスタンスを認証付きで取得
 * 2. 1 で取得したインスタンスを引数の言語で更にラップ
 * 3. 2 で生成したインスタンスを用いて船舶詳細取得 API 実行
 * 4. 3 で取得した船舶詳細データから以下の項目を変更して返す
 *    owner → shipOwner
 *    furunoCompany1, furunoCompany2 → distributor
 *
 * @param shipId 船舶番号
 * @param language 言語
 * @returns
 */
export async function fetchShipWithAnyLanguages(
  shipId: BookmarkShip["fShipNo"],
  language: LangCode
): Promise<BookmarkShip> {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const data = await withLang.getShipsFShipNo({ fShipNo: shipId });
  const { owner, furunoCompany1, furunoCompany2, ...rest } = data;

  return {
    ...rest,
    shipOwner: owner,
    distributor: `${furunoCompany1}${furunoCompany2}`,
    language,
  };
}

/**
 * 指定船舶に関連情報を返す URL 一覧を取得
 *
 * @param shipId 船舶番号
 * @returns
 */
export async function fetchShipUrls(shipId: BookmarkShip["fShipNo"]) {
  const webapi = await getAuthedWebapi();
  const data = webapi.getShipsFShipNoUrls({ fShipNo: shipId });
  return data;
}

/**
 * キャッシュ対象のリソース取得
 *
 * @description
 * 1. S3 系 URL 一覧と通常 URL 一覧を各生成
 * 2. 各リソースを取得して返す
 *
 * @param targetUrls URL 一覧
 * @param signal
 */
export async function fetchCacheResources(
  targetUrls: CacheKey["urls"],
  signal?: AbortSignal
) {
  const s3Urls = targetUrls.filter(isS3Url);
  const cacheTargets = convertCacheTargetObject(targetUrls);
  const fetchResourceFn = await fetchResource(signal);

  // Web API の 各種 URL から保持するためのキー(URL)とレスポンス結果を取得
  const targetCacheKeys = await Promise.all(
    cacheTargets.map(async (target) =>
      fetchResourceFn(target.url, target.language as LangCode)
    )
  );

  // AWS S3 バケットの各種 URL から保持するためのキー(URL)とレスポンス結果を取得
  const s3UrlsCacheKeys = await Promise.all(
    s3Urls.map(async (url) => fetchFileResource(url, signal))
  );

  const storedCacheKeys: UnknownResource[] = [
    ...targetCacheKeys,
    ...s3UrlsCacheKeys,
  ];
  return storedCacheKeys;
}

/**
 * 通常 URL のリソースを取得
 *
 * @description
 *
 * 0. カリー化で予め認証トークンをヘッダーインスタンスに設定
 * 1. ヘッダーインスタンスに言語を設定
 * 2. Fetch API によるリクエスト処理
 *    レスポンスをバイナリに変換
 *    ※ Fetch API で 4xx エラーは reject されないため ok で判定
 * 3. レスポンスとして、言語・キー(URL)・バイナリを返す
 *
 * @param signal
 * @returns
 */
async function fetchResource(signal?: AbortSignal) {
  const headers = new Headers();
  const token = await getAccessToken();
  headers.set("authorization", token);

  return async function (url: string, language: LangCode) {
    headers.set("accept-language", language);
    let value: Blob;
    try {
      const response = await fetch(url, { headers, signal });
      if (response.ok) {
        value = await response.blob();
      } else {
        throw new TypeError(response.statusText);
      }
    } catch (e: any) {
      if (e.name === "AbortError") {
        throw new AppErrorFetchAborted(e);
      } else {
        value = new Blob([]);
      }
    }
    return { language, key: url, value };
  };
}

/**
 * S3 系 URL のリソース取得
 *
 * @description
 *
 * 0. URL からクエリ以降を省く
 * 1. Fetch API によるリクエスト処理
 *    レスポンスをバイナリに変換
 *    ※ Fetch API で 4xx エラーは reject されないため ok で判定
 * 2. レスポンスとして、言語・キー(URL)・バイナリを返す
 *
 * @param url
 * @param signal
 * @returns
 */
async function fetchFileResource(url: string, signal?: AbortSignal) {
  const key = excludeQuery(url);
  let value: Blob;
  try {
    const response = await fetch(url, { signal });
    if (response.ok) {
      value = await response.blob();
    } else {
      throw new TypeError(response.statusText);
    }
  } catch (e: any) {
    if (e.name === "AbortError") {
      throw new AppErrorFetchAborted(e);
    } else {
      value = new Blob([]);
    }
  }
  return { language: "en", key, value };
}

/**
 * 全てのブックマークを削除
 */
export function removeAllBookmark() {
  const actionsFns = [
    actions.bookmark.shipsAllRemoved,
    actions.bookmark.shipAppendixAllRemoved,
    actions.bookmark.cacheKeyAllRemoved,
  ];
  actionsFns.map((action) => store.dispatch(action()));
}
