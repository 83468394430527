import {
  isMaintenanceJSON,
  maintenanceInfo,
  MaintenanceInfo,
} from "../../models/maintenance";
import store, { actions } from "../../store";

export async function checkCurrentMaintenanceState() {
  let info: MaintenanceInfo;
  try {
    const response = await fetch("/maintenance.json");
    if (!response.ok) throw new Error("HTTP Error");
    const data: unknown = await response.json();

    if (!isMaintenanceJSON(data))
      throw new Error("Unexpected maintenance.json format");
    info = maintenanceInfo(data);
  } catch {
    // メンテナンス情報取得エラーは 404 含め、メンテナンス状態でないとみなす
    return;
  }

  if (info.state === "maintenance")
    store.dispatch(actions.site.maintenanceDetected(info));
}
