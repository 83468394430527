import { FC, useEffect, useRef } from "react";
import { useBoundActions, useBoundSelector } from "../../store";
import { auth } from "../../usecases";

type Props = {};

/**
 * このコンポーネントの責務は通信状況の変更を購読すること
 */
const ConnectionGate: FC<Props> = ({ children }) => {
  useWindowConnectionEventHandler();
  return <>{children}</>;
};
export default ConnectionGate;

/**
 * 通信状況切り替えイベントの購読
 */
function useWindowConnectionEventHandler() {
  const { setting } = useBoundActions();
  const connection = useBoundSelector((state) => state.setting.connection);
  const prevConnection = useRef(connection);

  useEffect(() => {
    // 永続化対象の設定空間で持たれているため初期値を更新
    setting.setUserMode(true);

    const dispatcher = () => {
      const payload = window.navigator.onLine;
      if (payload) {
        setting.setUserMode(payload);
      }
      setting.connectionReceived(payload);
    };
    dispatcher();

    window.addEventListener("online", dispatcher);
    window.addEventListener("offline", dispatcher);

    return () => {
      window.removeEventListener("online", dispatcher);
      window.removeEventListener("offline", dispatcher);
    };
  }, [setting]);

  useEffect(() => {
    // オフラインからオンラインに復帰した場合、認証済みかを確認
    if (connection && !prevConnection.current) {
      auth.ensureLoggedIn();
    }

    return () => {
      prevConnection.current = connection;
    };
  }, [connection]);
}
