import type { FC } from "react";

type Props = {
  value?: Date;
};

/**
 * このコンポーネントの責務は Date インスタンスをこのプロジェクト特有の日付表示方法で
 * フォーマットした文字列に変換すること
 */
const Date: FC<Props> = ({ value }) => {
  return value && !invalidDate(value) ? <>{format(value)}</> : null;
};
export default Date;

export function format(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
}

function invalidDate(d: Date) {
  return Number.isNaN(d.getTime());
}
