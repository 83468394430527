import { MemoCategory, MentionUser } from "../webapi/generated";
import type { DrawingInstance } from "./drawing";
import { User } from "./user";

/**
 * メモ
 *
 * 利用者によって書き込まれた、船や結線図などの一部に対する追加情報
 */
export type Memo = MemoActive | MemoDeleted;
export interface MemoActive {
  id: number;
  title: string;
  detail: string;
  instances?: DrawingInstance[];
  createdAt: Date;
  updatedAt: Date;
  isDeleted: false;
  author: User;
  comments: Comment[];
  files: AttachmentFile[];
  draft?: DraftWithTemporaries;
  categories?: MemoCategory[];
  mentionedUsers?: MentionUser[];
  isUnreadMention?: boolean;
}
export type MemoDeleted = Omit<
  MemoActive,
  "title" | "detail" | "paths" | "files" | "isDeleted"
> & { isDeleted: true };

/**
 * 下書き種別
 */
export type DraftType = "create" | "edit";

/**
 * 新規作成された下書きか判定
 *
 * ※ ID の持つ新規作成は編集下書きの残骸
 *
 * @param draft
 * @returns
 */
export function isCreatedDraft(draft: MemoDraft | CommentDraft) {
  return !draft.id && draft.type === "create";
}
/**
 * 既存編集の下書きか判定
 *
 * @param draft
 * @returns
 */
export function isEditedDraft(draft: MemoDraft | CommentDraft) {
  return draft.type === "edit";
}

/**
 * 下書き型ガード（無理矢理）
 *
 * @param v
 * @returns
 */
export function isDraft<T>(v: any): v is T {
  return v;
}

export type RequiredDiagramId<T> = Omit<T, "diagramId"> & { diagramId: string };
export type RawDraftProperty<T> = Omit<T, "key" | "createdAt" | "updatedAt">;

/**
 * 下書きメモ
 */
export type MemoDraft = Pick<
  MemoActive,
  "title" | "detail" | "createdAt" | "updatedAt" | "mentionedUsers"
> & {
  id?: number;
  key: string;
  fShipNo: string;
  diagramId?: string;
  equipmentId?: string;
  serviceReportId?: number;
  type: DraftType;
  path?: string;
  categories?: number[];
};

/**
 * 下書きメモ追加引数
 */
export type MemoDraftAdd = RawDraftProperty<MemoDraft>;

/**
 * 下書きメモ更新引数
 */
export interface MemoDraftUpdate {
  key: MemoDraft["key"];
  memo: Partial<UpdateMemoDraftArgType>;
}

/**
 * 編集下書きメモ一時保持
 */
export type MemoDraftTemporary = Pick<
  MemoDraft,
  "key" | "title" | "detail" | "path" | "id" | "type" | "mentionedUsers"
> & { categories?: MemoCategory[] };

/**
 * 下書き判定
 */
export interface Draft {
  key?: MemoDraft["key"];
  type?: DraftType;
}
/**
 * 下書き判定と編集下書き一時保持配列
 */
export type DraftWithTemporaries = Draft & {
  temporaries: CommentDraftTemporary[];
};

export const diagramMemoPageSize = 10;

export function nonDeletedCommentNumber(memo: Memo) {
  return memo.comments.filter((memo) => !memo.isDeleted).length;
}

/**
 * 下書き判定
 *
 * @param draft
 * @returns
 */
export function hasDraft(draft?: Draft) {
  return !!draft?.key;
}

/**
 * メモの下書き判定
 *
 * @param memo
 * @returns
 */
export function hasDraftMemo(memo: Memo) {
  return (
    hasDraft(memo.draft) ||
    !!memo.comments.filter((c) => hasDraft(c.draft)).length
  );
}

/**
 * 任意ID付き
 */
export type WithOptionalId<T> = T & { id?: number };

/**
 * 任意下書き種別付き
 */
export type WithOptionalDraftType<T> = T & { type?: DraftType };

/**
 * 下書きメモ作成引数
 */
export type CreateMemoDraftArgType = WithOptionalId<
  Pick<MemoDraft, "title" | "detail">
>;

/**
 * 下書きメモ更新引数
 */
export type UpdateMemoDraftArgType = WithOptionalDraftType<
  Pick<MemoDraft, "title" | "detail">
>;

/**
 * 下書きコメント作成引数
 */
export type CreateCommentDraftArgType = WithOptionalId<
  Pick<CommentDraft, "message">
>;

/**
 * 下書きコメント更新引数
 */
export type UpdateCommentDraftArgType = WithOptionalDraftType<
  Partial<Pick<CommentDraft, "message">>
>;

/**
 * 添付ファイル
 */
export interface AttachmentFile {
  id: number;
  fileName: string;
  url: string;
  available: boolean;
}

/**
 * 添付ファイルのファイル種別
 */
export type AttachmentFileType = "image" | "video" | "other";

const imageExts = ["png", "jpg", "jpeg", "gif", "bmp"];
const videoExts = ["mp4", "mov", "mpg", "wmv", "avi"];
/**
 * 添付ファイルのファイル種別をファイル名 (拡張子) から判定する
 * @see https://erp.fecrc.net/issues/7389
 */
export function detectAttachmentFileTypeByName(
  name: string
): AttachmentFileType {
  const ext = name.split(".").slice(-1)[0].toLowerCase();
  if (imageExts.includes(ext)) return "image";
  if (videoExts.includes(ext)) return "video";
  return "other";
}

/**
 * コメント
 *
 * 利用者によって書き込まれた、メモ内での一つの意見
 */
export type Comment = CommentActive | CommentDeleted;
export interface CommentActive {
  id: number;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: false;
  author: User;
  files: AttachmentFile[];
  draft?: Draft;
  mentionedUsers?: MentionUser[];
  isUnreadMention?: boolean;
}
export type CommentDeleted = Omit<CommentActive, "files" | "isDeleted"> & {
  isDeleted: true;
};

/**
 * 下書きコメント
 */
export type CommentDraft = Pick<
  CommentActive,
  "message" | "createdAt" | "updatedAt" | "mentionedUsers"
> & {
  id?: number;
  key: string;
  fShipNo: string;
  diagramId?: string;
  equipmentId?: string;
  serviceReportId?: number;
  memoId: number;
  type: DraftType;
};

/**
 * 下書きコメント追加引数
 */
export type CommentDraftAdd = RawDraftProperty<CommentDraft>;

/**
 * 下書きコメント更新引数
 */
export interface CommentDraftUpdate {
  key: MemoDraft["key"];
  comment: WithOptionalDraftType<Partial<Pick<CommentDraft, "message">>>;
}

/**
 * 編集下書きコメント一時保持
 */
export type CommentDraftTemporary = Partial<
  Pick<CommentDraft, "key" | "message" | "id" | "type" | "mentionedUsers">
>;

/**
 * メモに登録されているカテゴリ一覧と絞り込み対象のカテゴリ一覧を比較し重複判定
 *
 * @param categories メモに登録されているカテゴリ一覧
 * @param queryCategories 絞り込み対象のカテゴリ一覧
 */
export function isDuplicateCategory(
  categories: number[],
  queryCategories: number[]
) {
  const categoryCountMap: { [key: number]: number } = {};
  const concatedCategories = [...categories, ...queryCategories];

  concatedCategories.forEach((category) => {
    let categoryCount = categoryCountMap[category];
    if (!categoryCount) {
      categoryCount = 0;
    }
    categoryCount++;
    categoryCountMap[category] = categoryCount;
  });

  const duplicateCategories = Object.keys(categoryCountMap)
    .map(Number)
    .filter((category) => categoryCountMap[category] > 1);

  return duplicateCategories.length > 0;
}
