import { useEffect, FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {};

/**
 * このコンポーネントの責務は言語の変更を購読すること
 */
const LanguageSubscriptor: FC<Props> = () => {
  useSubscribe();
  return null;
};
export default LanguageSubscriptor;

function useSubscribe() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.querySelector("html")?.setAttribute("lang", i18n.language);
  }, [i18n.language]);
}
