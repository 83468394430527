import { useCallback } from "react";
import { useNavigate } from "react-router";

/**
 * メモ遷移処理用パラメータの削除
 *
 * @param {number=} memoId
 */
export function useMemoTransitionResetter(memoId?: number) {
  const navigate = useNavigate();

  return useCallback(() => {
    if (!memoId) return;

    const params = new URLSearchParams(window.location.search);
    params.delete("memoId");
    params.delete("page");
    const search = "?" + params;

    navigate({ search });
  }, [memoId, navigate]);
}
