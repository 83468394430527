import { createSlice } from "@reduxjs/toolkit";
import {
  ManagementUnit,
  Model,
  SapliUser,
  ServiceType,
  ShipFlag,
  ShipSpecies,
} from "../../models";
import { Warehouse } from "../../models/ship-other";

type State = {
  shipFlags: ShipFlag[];
  shipSpecies: ShipSpecies[];
  managementUnits: ManagementUnit[];
  users: SapliUser[];
  models: Model[];
  serviceTypes: ServiceType[];
  warehouses: Warehouse[];
};

export const { actions, reducer } = createSlice({
  name: "master",
  initialState: {
    shipFlags: [],
    shipSpecies: [],
    managementUnits: [],
    users: [],
    models: [],
    serviceTypes: [],
    warehouses: [],
  } as State,
  reducers: {
    shipFlagsReceived(state, action: { payload: State["shipFlags"] }) {
      state.shipFlags = action.payload;
    },
    shipSpeciesReceived(state, action: { payload: State["shipSpecies"] }) {
      state.shipSpecies = action.payload;
    },
    managementUnitsReceived(
      state,
      action: { payload: State["managementUnits"] }
    ) {
      state.managementUnits = action.payload;
    },
    usersReceived(state, action: { payload: State["users"] }) {
      state.users = action.payload;
    },
    modelsReceived(state, action: { payload: State["models"] }) {
      state.models = action.payload;
    },
    serviceTypesReceived(state, action: { payload: State["serviceTypes"] }) {
      state.serviceTypes = action.payload;
    },
    warehousesReceived(state, action: { payload: State["warehouses"] }) {
      state.warehouses = action.payload;
    },
  },
});
