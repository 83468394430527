import {
  SERVICE_REPORT_OFFLINE_STATUS,
  ServiceReportDetailDetail,
  ServiceReportDetailDraft,
  ServiceReportDetailSignatureDraft,
  ServiceReportSign,
  ServiceReportSignatureDraft,
} from "../../models";
import { AppErrorDraftNotFound } from "../../models/error";
import store, { actions, selectors } from "../../store";
import { updateHasServiceReportDrafts } from "./service-report-draft";

/**
 * 明細署名下書き追加時の型
 */
type postNewServiceReportDetailSignatureDraftParams = Omit<
  ServiceReportSign,
  "type"
> & {
  key: string;
  id?: number;
  serviceReportKey?: string;
  serviceReportId?: number;
};

/**
 * 下書きのサービスレポート明細を参照用のサービスレポート明細に保存する
 * @param key 下書きの識別子
 */
export function fetchDraftServiceReportDetail(key: string) {
  const state = store.getState();
  const draft = selectors.draft.serviceReportDetailsSelectors.selectById(
    state.draft.serviceReportDetails,
    key
  );
  if (!draft) {
    throw new AppErrorDraftNotFound(
      `couldn't find service report detail draft. (Key: ${key}）`
    );
  }
  const account = state.account.user;

  const workers: string[] = [];
  draft.workingTimes.forEach((wt) => {
    if (!!wt.workerName) workers.push(wt.workerName);
  });

  const convert: ServiceReportDetailDetail = {
    ...draft,
    id: draft.id || draft.key,
    isLatest: true,
    isTransmitted: false,
    status: SERVICE_REPORT_OFFLINE_STATUS,
    hasDraft: true,
    lastUpdatedUserId: account!.id,
    lastUpdatedUserName: account!.name,
    workers: workers.join(" / "),
    versions: [],
    checkers: [],
  };
  store.dispatch(actions.serviceReport.serviceReportDetailUpdate(convert));
}

/**
 * サービスレポートの明細情報の下書き登録処理
 *
 * @param data 登録内容
 */
export function postNewServiceReportDetailDraft(
  data: ServiceReportDetailDraft
) {
  const action = actions.draft.serviceReportDetailAdded(data);
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートの明細情報の下書き更新処理
 *
 * @param key 下書きの識別子
 * @param data 更新内容
 */
export function updateServiceReportDetailDraft(
  key: string,
  data: Omit<ServiceReportDetailDraft, "key">
) {
  const payload = {
    key,
    ...data,
  };
  const action = actions.draft.serviceReportDetailUpdated(payload);
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートの明細情報の下書き削除処理
 *
 * @param key 下書きの識別子
 */
export function deleteServiceReportDetailDraft(key: string, fShipNo: string) {
  store.dispatch(actions.draft.serviceReportDetailRemoved(key));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートの明細情報の下書きをサービスレポート明細ID を使用して取得する
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId サービスレポート明細ID
 */
export function getServiceReportHeaderDraftById(
  serviceReportId: string,
  serviceReportDetailId: number | string
) {
  const serviceReportDetail = selectors.draft.customSelectors.serviceReportDetailsByServiceReportIdDetailId(
    store.getState().draft,
    serviceReportId,
    serviceReportDetailId
  );
  return serviceReportDetail;
}

/**
 * サービスレポート明細に対して署名の下書きの登録処理
 *
 * @param fShipNo 船舶番号
 * @param data 登録内容
 */
export function postNewServiceReportDetailSignatureDraft(
  fShipNo: string,
  data: postNewServiceReportDetailSignatureDraftParams
) {
  const payload: ServiceReportDetailSignatureDraft = {
    id: data.id,
    key: data.key,
    fShipNo: fShipNo,
    serviceReportId: data.serviceReportId,
    serviceReportKey: data.serviceReportKey,
    customerSignature: data.signature,
    customerRemarks: data.reason,
    customerSignedAt: new Date().toISOString(),
  };

  store.dispatch(actions.draft.serviceReportDetailSignatureAdded(payload));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポート明細の署名の下書きの更新処理
 *
 * @param fShipNo 船舶番号
 * @param key 識別子
 * @param data 更新内容
 */
export function updateServiceReportDetailSignatureDraft(
  fShipNo: string,
  key: ServiceReportSignatureDraft["key"],
  data: ServiceReportSign
) {
  const payload: Omit<any, "key" | "fShipNo"> = {
    customerSignature: data.signature,
    customerRemarks: data.reason,
    customerSignedAt: new Date().toISOString(),
  };

  store.dispatch(
    actions.draft.serviceReportSignatureDetailUpdated({
      key,
      data: payload,
    })
  );
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * 署名登録後、明細に紐づく署名下書きを削除する
 *
 * @param fShipNo 船舶番号
 * @param serviceReportDetailKey サービスレポート明細の下書きの識別子
 * @param serviceReportDetailId サービスレポート詳細ID
 */
export function deleteServiceReportDetailSignatureDraft(
  fShipNo: string,
  serviceReportDetailKey: string,
  serviceReportDetailId?: number
) {
  // ヘッダ情報の下書きに紐づく署名の下書きを取得
  const signatures = selectors.draft.serviceReportDetailSignaturesSelectors.selectAll(
    store.getState().draft.serviceReportDetailSignatures
  );
  const signature = signatures.find((s) => {
    let condition = s.key === serviceReportDetailKey;
    if (typeof serviceReportDetailId !== "undefined") {
      condition = condition || s.id === serviceReportDetailId;
    }
    return condition;
  });

  // 上記で取得した署名の下書きを削除
  if (signature) {
    store.dispatch(
      actions.draft.serviceReportDetailSignatureRemoved(signature.key)
    );
  }
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートのヘッダ情報登録後に紐づく下書きにサービスレポートID を付与する
 *
 * @param key ヘッダ情報の下書きの識別子
 * @param serviceReportDetailId サービスレポート詳細ID
 */
export function attachServiceReportDetailId(
  detailKey: string,
  serviceReportDetailId: number
) {
  // ヘッダ情報の下書きに紐づく署名の下書きを取得
  const signature = selectors.draft.serviceReportDetailSignaturesSelectors
    .selectAll(store.getState().draft.serviceReportDetailSignatures)
    .find((s) => s.key === detailKey);

  // 上記で取得した署名の下書きに設定されたサービスレポートID を更新
  if (signature) {
    const key = signature.key;

    store.dispatch(
      actions.draft.serviceReportSignatureDetailUpdated({
        key,
        data: { ...signature, id: serviceReportDetailId },
      })
    );
  }
}

/**
 * 指定サービスレポートに紐づくサービスレポート明細署名の下書きを削除する
 *
 * @param serviceReportKey サービスレポートの下書きの識別子
 * @param serviceReportId サービスレポートID
 */
export function deleteServiceReportDetailSignatureDraftsByServiceReport(
  serviceReportKey?: string,
  serviceReportId?: number
) {
  // 両方指定がない場合は後続処理を実施しない
  if (!serviceReportId && !serviceReportKey) return;

  // 指定サービスレポートに紐づくサービスレポート明細署名の下書きを取得
  const signatures = selectors.draft.serviceReportDetailSignaturesSelectors
    .selectAll(store.getState().draft.serviceReportDetailSignatures)
    .filter((state) => {
      let condition = state.serviceReportKey === serviceReportKey;
      if (typeof serviceReportId !== "undefined") {
        condition = condition || state.serviceReportId === serviceReportId;
      }
      return condition;
    });

  // 上記で取得したサービスレポート明細署名の下書きを削除
  signatures.forEach((signature) => {
    const action = actions.draft.serviceReportDetailSignatureRemoved(
      signature.key
    );
    store.dispatch(action);
  });
}
