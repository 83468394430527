import { combineReducers } from "redux";
import {
  actions as accountActions,
  reducer as accountReducer,
} from "./account";
import {
  actions as serviceOrderActions,
  reducer as serviceOrderReducer,
  serviceOrdersSelectors,
} from "./serviceOrder";
import {
  actions as settingActions,
  reducer as settingReducer,
  selectors as settingSelectors,
} from "./setting";
import {
  actions as shipActions,
  categoriesSelectors,
  diagramMemosSelectors,
  equipmentMemosSelectors,
  reducer as shipReducer,
  serviceReportMemosSelectors,
  shipMemosSelectors,
  shipsSelectors,
} from "./ship";
import { actions as siteActions, reducer as siteReducer } from "./site";
import {
  actions as bookmarkActions,
  reducer as bookmarkReducer,
  selectors as bookmarkSelectors,
  adapters as bookmarkAdapters,
} from "./bookmark";
import {
  actions as draftActions,
  reducer as draftReducer,
  adapters as draftAdapters,
  selectors as draftSelectors,
} from "./draft";
import {
  actions as readMentionsActions,
  reducer as readMentionReducer,
} from "./readMention";
import {
  actions as serviceReportActions,
  reducer as serviceReportReducer,
  selectors as serviceReportSelector,
} from "./serviceReport";
import {
  actions as workOrderActions,
  reducer as workOrderReducer,
  workOrdersSelectors,
} from "./workOrder";
import { actions as masterActions, reducer as masterReducer } from "./master";

export const actions = {
  account: accountActions,
  serviceOrder: serviceOrderActions,
  setting: settingActions,
  ship: shipActions,
  site: siteActions,
  bookmark: bookmarkActions,
  draft: draftActions,
  readMention: readMentionsActions,
  serviceReport: serviceReportActions,
  workOrder: workOrderActions,
  master: masterActions,
};

export const reducer = combineReducers({
  account: accountReducer,
  serviceOrder: serviceOrderReducer,
  setting: settingReducer,
  ship: shipReducer,
  site: siteReducer,
  bookmark: bookmarkReducer,
  draft: draftReducer,
  readMention: readMentionReducer,
  serviceReport: serviceReportReducer,
  workOrder: workOrderReducer,
  master: masterReducer,
});

export const selectors = {
  diagramMemo: diagramMemosSelectors,
  equipmentMemo: equipmentMemosSelectors,
  category: categoriesSelectors,
  serviceOrder: serviceOrdersSelectors,
  ship: shipsSelectors,
  shipMemo: shipMemosSelectors,
  bookmark: bookmarkSelectors,
  draft: draftSelectors,
  setting: settingSelectors,
  serviceReportMemo: serviceReportMemosSelectors,
  workOrder: workOrdersSelectors,
  serviceReport: serviceReportSelector,
};

export const adapters = {
  bookmark: bookmarkAdapters,
  draft: draftAdapters,
};

export type Actions = typeof actions;
export type RootState = ReturnType<typeof reducer>;
