import {
  convertNotificationSortQuery,
  getNotificationBreadcrumbs,
  getNotificationKey,
  Memo,
  NotificationSortType,
  memoType,
  Ship,
} from "../../models";
import { ReadMention } from "../../models/read-mention";
import store, { actions } from "../../store";
import { withLanguageHeader } from "../../webapi";
import { getAuthedWebapi } from "./custom-webapi";
import { readMention } from "..";
import { getMemoTransitionParams } from "./memo";

/**
 * 更新通知一覧取得
 *
 * @param shipId 船舶番号
 * @param sort 並び替えキー
 */
export async function fetchNotifications(
  shipId: Ship["fShipNo"],
  page: number,
  language: string,
  sort?: NotificationSortType
) {
  // ログインユーザの最終ログイン日時を取得
  const { account } = store.getState();
  const lastLoginAt = account.user
    ? new Date(account.user.lastLoginAt)
    : undefined;

  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const { count, notifications } = await withLang.getShipsFShipNoNotifications({
    fShipNo: shipId,
    page,
    sort: convertNotificationSortQuery(sort),
  });

  // 新規通知判定フラグを追加
  const convertedNotifications = (notifications || []).map((notification) => {
    const files = (notification.files || []).map((file) => ({
      ...file,
      available: true,
    }));

    const isNew = !!(
      lastLoginAt && lastLoginAt.getTime() < notification.updatedAt.getTime()
    );

    const values = {
      ...notification,
      files,
      isNew,
      isUnread: false,
      breadcrumbs: getNotificationBreadcrumbs(notification),
      key: getNotificationKey(notification),
    };

    if (!isNew) return values;

    const isTarget = notification.mentionedUsers?.some(
      (user) => user.id === account.user?.id
    );
    if (!isTarget) return values;

    const compareMention: ReadMention = {
      id: notification.id,
      userId: account.user?.id!,
      memoId: notification.memoId,
      diagramId: notification.diagramId,
      equipmentId: notification.equipmentId,
      serviceReportId: notification.serviceReportId,
      updatedAt: notification.updatedAt,
    };
    values.isUnread = readMention.isUnread(compareMention);

    if (values.isUnread) {
      store.dispatch(
        actions.readMention.mentionAdded({
          ...compareMention,
          updatedAt: compareMention.updatedAt.toISOString(),
        })
      );
    }

    return values;
  });

  return { count: count || 0, notifications: convertedNotifications };
}

/**
 * 新規お知らせの存在確認
 *
 * ※ オフライン時、処理しない
 *
 * @param shipId 船舶番号
 * @returns
 */
export async function fetchNotificationCheck(shipId: Ship["fShipNo"]) {
  const { connection } = store.getState().setting;
  if (!connection) return;

  const webapi = await getAuthedWebapi();
  const { hasNewNotification } = await webapi.getShipsFShipNoNotificationCheck({
    fShipNo: shipId,
  });
  store.dispatch(actions.ship.hasNewNotificationUpdated(hasNewNotification));
}

/**
 * 更新通知画面から特定のメモを表示するためのパスを取得
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param type メモ種別
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param serviceReportId サービスレポートID
 * @returns
 */
export async function getMemoTransitionPath(
  shipId: Ship["fShipNo"],
  memoId: Memo["id"],
  type: number,
  diagramId?: string,
  equipmentId?: number,
  serviceReportId?: number
) {
  const params = await getMemoTransitionParams(
    shipId,
    memoId!,
    diagramId,
    equipmentId,
    serviceReportId
  );

  let path = "";
  switch (type) {
    case memoType.SHIP:
      const combinedParams = new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
        ...Object.fromEntries(params),
      });
      path = window.location.pathname + `?${combinedParams}`;
      break;
    case memoType.DIAGRAM:
      path = `/ships/${shipId}/diagrams/${diagramId}?${params}`;
      break;
    case memoType.EQUIPMENT:
      path = `/ships/${shipId}/models/${equipmentId}/units?${params}`;
      break;
    case memoType.SERVICE_REPORT:
      path = `/ships/${shipId}/service-reports/${serviceReportId}?${params}`;
      break;
  }

  return path;
}
