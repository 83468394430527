import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import type { Ship, ShipNotification } from "../../models";
import { MemoCategory } from "../../webapi/generated";
import { SerializedMemo } from "../serializers";

type State = {
  ships: EntityState<Ship>;
  shipMemos: EntityState<SerializedMemo>;
  /** shipMemos の総数 (未取得状態の場合は undefined) */
  shipMemoCount?: number;
  diagramMemos: EntityState<SerializedMemo>;
  /** diagramMemos の総数 (未取得状態の場合は undefined) */
  diagramMemoCount?: number;
  equipmentMemos: EntityState<SerializedMemo>;
  /** equipmentMemos の総数 (未取得状態の場合は undefined) */
  equipmentMemoCount?: number;
  serviceReportMemos: EntityState<SerializedMemo>;
  /** serviceReportMemos の総数 (未取得状態の場合は undefined) */
  serviceReportMemoCount?: number;
  categories: EntityState<MemoCategory>;
  /** 船舶に付随するメモまたはコメントの更新通知用 */
  notification: ShipNotification;
};

const shipsAdapter = createEntityAdapter<Ship>({
  selectId: (ship) => ship.fShipNo,
});
export const shipsSelectors = shipsAdapter.getSelectors();

const shipMemosAdapter = createEntityAdapter<SerializedMemo>();
export const shipMemosSelectors = shipMemosAdapter.getSelectors();

const diagramMemosAdapter = createEntityAdapter<SerializedMemo>();
export const diagramMemosSelectors = diagramMemosAdapter.getSelectors();

const equipmentMemosAdapter = createEntityAdapter<SerializedMemo>();
export const equipmentMemosSelectors = equipmentMemosAdapter.getSelectors();

const serviceReportMemosAdapter = createEntityAdapter<SerializedMemo>();
export const serviceReportMemosSelectors = serviceReportMemosAdapter.getSelectors();

const categoriesAdapter = createEntityAdapter<MemoCategory>();
export const categoriesSelectors = categoriesAdapter.getSelectors();

export const { actions, reducer } = createSlice({
  name: "ship",
  initialState: {
    ships: shipsAdapter.getInitialState(),
    shipMemos: shipMemosAdapter.getInitialState(),
    diagramMemos: diagramMemosAdapter.getInitialState(),
    equipmentMemos: equipmentMemosAdapter.getInitialState(),
    serviceReportMemos: serviceReportMemosAdapter.getInitialState(),
    categories: categoriesAdapter.getInitialState(),
    notification: { hasNewNotification: false },
  } as State,
  reducers: {
    shipsReceived(state, action: { payload: Ship[] }) {
      shipsAdapter.setAll(state.ships, action.payload);
    },
    shipMemosReceived(
      state,
      action: { payload: { memos: SerializedMemo[]; count: number } }
    ) {
      shipMemosAdapter.setAll(state.shipMemos, action.payload.memos);
      state.shipMemoCount = action.payload.count;
    },
    diagramMemosReceived(
      state,
      action: { payload: { memos: SerializedMemo[]; count: number } }
    ) {
      diagramMemosAdapter.setAll(state.diagramMemos, action.payload.memos);
      state.diagramMemoCount = action.payload.count;
    },
    equipmentMemosReceived(
      state,
      action: { payload: { memos: SerializedMemo[]; count: number } }
    ) {
      equipmentMemosAdapter.setAll(state.equipmentMemos, action.payload.memos);
      state.equipmentMemoCount = action.payload.count;
    },
    serviceReportMemosReceived(
      state,
      action: { payload: { memos: SerializedMemo[]; count: number } }
    ) {
      serviceReportMemosAdapter.setAll(
        state.serviceReportMemos,
        action.payload.memos
      );
      state.serviceReportMemoCount = action.payload.count;
    },
    categoriesReceived(
      state,
      action: { payload: { categories: MemoCategory[] } }
    ) {
      categoriesAdapter.setAll(state.categories, action.payload.categories);
    },
    hasNewNotificationUpdated(state, action: { payload: boolean }) {
      state.notification.hasNewNotification = action.payload;
    },
  },
});
