import ExtensibleCustomError from "extensible-custom-error";
import {
  array,
  assert,
  Describe,
  enums,
  Infer,
  literal,
  number,
  object,
  optional,
  string,
  union,
} from "superstruct";
import {
  createPath,
  DrawingInstance,
  Ellipse,
  Line,
  Path,
  PathColor,
  pathColors,
  Rect,
  Transform,
} from "./drawing";

const PathColorSchema: Describe<PathColor> = enums(pathColors);
const TransformSchema: Describe<Transform> = object({
  scale: optional(object({ x: number(), y: number() })),
  rotate: optional(number()),
  translate: optional(object({ x: number(), y: number() })),
});
const PathSchema: Describe<Path> = object({
  id: string(),
  shape: literal("path"),
  transform: optional(TransformSchema),
  points: array(
    object({
      x: number(),
      y: number(),
    })
  ),
  color: PathColorSchema,
  width: number(),
});
const RectSchema: Describe<Rect> = object({
  id: string(),
  shape: literal("rect"),
  transform: optional(TransformSchema),
  cx: number(),
  cy: number(),
  width: number(),
  height: number(),
  color: PathColorSchema,
  strokeWidth: number(),
});
const EllipseSchema: Describe<Ellipse> = object({
  id: string(),
  shape: literal("ellipse"),
  transform: optional(TransformSchema),
  cx: number(),
  cy: number(),
  rx: number(),
  ry: number(),
  color: PathColorSchema,
  strokeWidth: number(),
});
const LineSchema: Describe<Line> = object({
  id: string(),
  shape: literal("line"),
  transform: optional(TransformSchema),
  x1: number(),
  x2: number(),
  y1: number(),
  y2: number(),
  color: PathColorSchema,
  strokeWidth: number(),
});
export const DrawingInstanceSchema = union([
  PathSchema,
  RectSchema,
  EllipseSchema,
  LineSchema,
]);
const DeserializedSchemaBase = object();
const DeserializedSchemaV1 = object({
  version: literal(undefined),
  paths: array(
    object({
      points: array(
        object({
          x: number(),
          y: number(),
        })
      ),
      color: PathColorSchema,
      width: number(),
    })
  ),
});
const DeserializedSchemaV2: Describe<{
  version: 2;
  instances: DrawingInstance[];
}> = object({
  version: literal(2),
  instances: array(union([PathSchema, RectSchema, EllipseSchema, LineSchema])),
});

/**
 * 手書きインスタンスを (復元可能な形で) 文字列化する
 */
export function serializeDrawingInstances(
  instances: DrawingInstance[] = []
): string {
  const v2: Infer<typeof DeserializedSchemaV2> = { version: 2, instances };
  return JSON.stringify(v2);
}

/**
 * メモに含まれる手書き情報を文字列から復元する
 */
export function deserializeDrawingInstances(data: string): DrawingInstance[] {
  try {
    const unsafe = JSON.parse(data);

    assert(unsafe, DeserializedSchemaBase);
    if (unsafe.version === 2) {
      assert(unsafe, DeserializedSchemaV2);
      return unsafe.instances;
    }

    assert(unsafe, DeserializedSchemaV1);
    return unsafe.paths.map((path) =>
      // id, shape は v1 では存在しなかったので v2 移行のため補完
      createPath(path)
    );
  } catch (err) {
    // deserialize に失敗した場合はログに出力つつ、空のデータを返す
    console.warn(
      new AppErrorMemoPathsDeserialization(
        "failed to deserialize memo path",
        err
      )
    );
    return [];
  }
}

/**
 * メモに含まれる手書き情報の復元の失敗を表すエラー
 */
export class AppErrorMemoPathsDeserialization extends ExtensibleCustomError {}
