import i18n from "../i18n";

/**
 * 無効を示すメッセージの取得
 *
 * @param t 変換用関数
 * @param key 識別子
 * @returns 無効を示すメッセージ
 */
export function getInvalidMessage(t: typeof i18n.t, key: string) {
  const message = t("selected %v is invalid");
  return message.replace("%v", t(key));
}

/**
 * 必須を示すメッセージの取得
 *
 * @param t 変換用関数
 * @param key 識別子
 * @returns 必須を示すメッセージ
 */
export function getRequiredMessage(t: typeof i18n.t, key: string) {
  const message = t("%v is a required field");
  return message.replace("%v", t(key));
}
