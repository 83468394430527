import classNames from "classnames";
import type { ComponentProps, FC } from "react";
import Button from "../Button";
import styles from "./SubmitButton.module.css";

type Props = ComponentProps<typeof Button> & {};

/**
 * このコンポーネントの責務はフォーム入力を確定させるボタンを提供すること
 */
const SubmitButton: FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Button
      type="submit"
      className={classNames(styles.host, className)}
      {...rest}
    >
      {children}
    </Button>
  );
};
export default SubmitButton;
