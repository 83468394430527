/**
 * kv-storage は一括操作に弱いため ブックマーク機能は IndexedDB で処理
 *
 * kv-storage から読み込まれる事を想定して以下を用意
 * - KV_Store
 * - PREFIX
 */

import { getBookmarkStorageArea } from "../../models";

const KV_STORE = "store";
const PREFIX = "kv-storage:";

interface IDBPayload {
  key: IDBValidKey;
  value: any;
}

/**
 * kv-storage 用の名前空間を作成
 *
 * @param language
 * @returns
 */
export function createStorageName(language: string) {
  return `${PREFIX}${getBookmarkStorageArea(language)}`;
}

/**
 * 一括追加・更新
 *
 * @param dbName
 * @param payloads
 * @returns
 */
export async function bulkPut(dbName: string, payloads: IDBPayload[]) {
  return process(dbName, async (store) => {
    return await Promise.all(
      payloads.map((resource) => {
        const result = store.put(resource.value, resource.key);
        return new Promise((resolve, reject) => {
          result.onsuccess = resolve;
          result.onerror = reject;
        });
      })
    );
  });
}

/**
 * 一括削除
 *
 * @param dbName
 * @param keys
 * @returns
 */
export async function bulkDelete(dbName: string, keys: IDBPayload["key"][]) {
  return await process(dbName, async (store) => {
    await Promise.all(
      keys.map(async (key) => {
        const result = store.delete(key);
        return new Promise((resolve, reject) => {
          result.onsuccess = resolve;
          result.onerror = reject;
        });
      })
    );
  });
}

/**
 * トランザクション管理
 *
 * @param dbName
 * @param callback
 * @returns
 */
async function process(
  dbName: string,
  callback: (store: IDBObjectStore) => Promise<unknown>
) {
  const tx = await ready(dbName, "readwrite");
  const store = tx.objectStore(KV_STORE);

  await callback(store);

  return new Promise((resolve, reject) => {
    tx.oncomplete = resolve;
    tx.onerror = reject;
  });
}

/**
 * 初期準備
 *
 * @param dbName
 * @param mode
 * @returns
 */
async function ready(dbName: string, mode: IDBTransactionMode) {
  const idb = await new Promise<IDBDatabase>((resolve) =>
    Object.assign(window.indexedDB.open(dbName), {
      onsuccess: (e: any) => resolve(e.target.result),
      onupgradeneeded: (e: any) => e.target.result.createObjectStore(KV_STORE),
    })
  );
  return idb.transaction(KV_STORE, mode);
}

/**
 * ブックマーク用 IndexedDB の削除
 *
 * @returns
 */
export async function deleteIDB() {
  const databases = ["en", "ja"].map(createStorageName);

  return await Promise.all(
    databases.map((dbName) => {
      const req = window.indexedDB.deleteDatabase(dbName);

      return new Promise((resolve, reject) => {
        req.onsuccess = resolve;
        req.onerror = reject;
      });
    })
  );
}
