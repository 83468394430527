import { ButtonHTMLAttributes, forwardRef } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {};

/**
 * このコンポーネントの責務はアプリケーション共通のボタン要素を提供すること
 *
 * - CSS リセット (button セレクタ経由)
 * - type="button" のデフォルト化
 * - title の aria-label での補完
 */
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    { type = "button", "aria-label": ariaLabel, title = ariaLabel, ...rest },
    ref
  ) => {
    /* eslint-disable */
    return (
      <button
        type={type}
        aria-label={ariaLabel}
        title={title}
        {...rest}
        ref={ref}
      />
    );
    /* eslint-enable */
  }
);
export default Button;
