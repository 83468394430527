import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { v4 } from "uuid";
import {
  CommentDraftAdd,
  CommentDraftUpdate,
  MemoDraftAdd,
  MemoDraftUpdate,
  RequiredDiagramId,
  RequiredEquipmentId,
  isDuplicateCategory,
  ServiceReportSignatureDraft,
  ServiceReportHeaderDraft,
  ServiceReportDetailDraft,
  ServiceReportDetailSignatureDraft,
} from "../../models";
import {
  draftCommentToJson,
  draftMemoToJson,
  jsonToDraftComment,
  jsonToDraftMemo,
  SerializedCommentDraft,
  SerializedMemoDraft,
} from "../serializers";
import {
  jsonToServiceReportHeaderDraft,
  SerializedServiceReportHeaderDraft,
} from "../serializers/serviceReport";
import { isNumber } from "../../usecases/modules/number";

type State = {
  shipMemos: EntityState<SerializedMemoDraft>;
  shipComments: EntityState<SerializedCommentDraft>;
  diagramMemos: EntityState<SerializedMemoDraft>;
  diagramComments: EntityState<SerializedCommentDraft>;
  equipmentMemos: EntityState<SerializedMemoDraft>;
  equipmentComments: EntityState<SerializedCommentDraft>;
  serviceReportMemos: EntityState<SerializedMemoDraft>;
  serviceReportComments: EntityState<SerializedCommentDraft>;
  serviceReportHeaders: EntityState<SerializedServiceReportHeaderDraft>;
  serviceReportDetails: EntityState<ServiceReportDetailDraft>;
  serviceReportSignatures: EntityState<ServiceReportSignatureDraft>;
  serviceReportDetailSignatures: EntityState<ServiceReportDetailSignatureDraft>;
};

const shipMemosAdapter = createEntityAdapter<SerializedMemoDraft>({
  selectId: (memo) => memo.key,
});
const shipMemosSelectors = shipMemosAdapter.getSelectors();

const shipCommentsAdapter = createEntityAdapter<SerializedCommentDraft>({
  selectId: (comment) => comment.key,
});
const shipCommentsSelectors = shipCommentsAdapter.getSelectors();

const diagramMemosAdapter = createEntityAdapter<SerializedMemoDraft>({
  selectId: (memo) => memo.key,
});
const diagramMemosSelectors = diagramMemosAdapter.getSelectors();

const diagramCommentsAdapter = createEntityAdapter<SerializedCommentDraft>({
  selectId: (comment) => comment.key,
});
const diagramCommentsSelectors = diagramCommentsAdapter.getSelectors();

const equipmentMemosAdapter = createEntityAdapter<SerializedMemoDraft>({
  selectId: (memo) => memo.key,
});
const equipmentMemosSelectors = diagramMemosAdapter.getSelectors();

const equipmentCommentsAdapter = createEntityAdapter<SerializedCommentDraft>({
  selectId: (comment) => comment.key,
});
const equipmentCommentsSelectors = diagramCommentsAdapter.getSelectors();

const serviceReportMemosAdapter = createEntityAdapter<SerializedMemoDraft>({
  selectId: (memo) => memo.key,
});
const serviceReportMemosSelectors = serviceReportMemosAdapter.getSelectors();

const serviceReportCommentsAdapter = createEntityAdapter<SerializedCommentDraft>(
  {
    selectId: (comment) => comment.key,
  }
);
const serviceReportCommentsSelectors = serviceReportCommentsAdapter.getSelectors();

const serviceReportHeadersAdapter = createEntityAdapter<SerializedServiceReportHeaderDraft>(
  {
    selectId: (serviceReport) => serviceReport.key,
  }
);
const serviceReportHeadersSelectors = serviceReportHeadersAdapter.getSelectors();

const serviceReportDetailsAdapter = createEntityAdapter<ServiceReportDetailDraft>(
  {
    selectId: (serviceReport) => serviceReport.key,
  }
);
const serviceReportDetailsSelectors = serviceReportDetailsAdapter.getSelectors();

const serviceReportSignaturesAdapter = createEntityAdapter<ServiceReportSignatureDraft>(
  {
    selectId: (serviceReport) => serviceReport.key,
  }
);
const serviceReportSignaturesSelectors = serviceReportSignaturesAdapter.getSelectors();

const serviceReportDetailSignaturesAdapter = createEntityAdapter<ServiceReportDetailSignatureDraft>(
  {
    selectId: (serviceReport) => serviceReport.key,
  }
);
const serviceReportDetailSignaturesSelectors = serviceReportDetailSignaturesAdapter.getSelectors();

/**
 * 指定船舶番号に付随する下書きメモ/コメントの合計件数を取得
 */
const shipCount = createSelector(
  (state: State) => shipMemosSelectors.selectAll(state.shipMemos),
  (state: State) => shipCommentsSelectors.selectAll(state.shipComments),
  (_state: State, fShipNo: string) => fShipNo,
  (memoDrafts, commentDrafts, fShipNo) => {
    const memos = memoDrafts.filter((m) => m.fShipNo === fShipNo);
    const comments = commentDrafts.filter((c) => c.fShipNo === fShipNo);
    return memos.length + comments.length;
  }
);

/**
 * 指定船舶番号の各図面に付随する下書きメモ/コメントの合計件数を取得
 */
const diagramCountMap = createSelector(
  (state: State) => diagramMemosSelectors.selectAll(state.diagramMemos),
  (state: State) => diagramCommentsSelectors.selectAll(state.diagramComments),
  (_state: State, fShipNo: string) => fShipNo,
  (memoDrafts, commentDrafts, fShipNo) => {
    const map = new Map<string, number>();

    const memos = memoDrafts.filter((m) => m.fShipNo === fShipNo);
    const comments = commentDrafts.filter((c) => c.fShipNo === fShipNo);
    memos.map((memo) =>
      map.set(memo.diagramId!, (map.get(memo.diagramId!) || 0) + 1)
    );
    comments.map((comment) =>
      map.set(comment.diagramId!, (map.get(comment.diagramId!) || 0) + 1)
    );

    return map;
  }
);

/**
 * 指定船舶番号の各機器に付随する下書きメモ/コメントの合計件数を取得
 */
const equipmentCountMap = createSelector(
  (state: State) => equipmentMemosSelectors.selectAll(state.equipmentMemos),
  (state: State) =>
    equipmentCommentsSelectors.selectAll(state.equipmentComments),
  (_state: State, fShipNo: string) => fShipNo,
  (memoDrafts, commentDrafts, fShipNo) => {
    const map = new Map<string, number>();

    const memos = memoDrafts.filter((m) => m.fShipNo === fShipNo);
    const comments = commentDrafts.filter((c) => c.fShipNo === fShipNo);
    memos.map((memo) =>
      map.set(memo.equipmentId!, (map.get(memo.equipmentId!) || 0) + 1)
    );
    comments.map((comment) =>
      map.set(comment.equipmentId!, (map.get(comment.equipmentId!) || 0) + 1)
    );

    return map;
  }
);

/**
 * 指定船舶番号の各サービスレポートに付随する下書きメモ/コメントの合計件数を取得
 */
const serviceReportMemoCountMap = createSelector(
  (state: State) =>
    serviceReportMemosSelectors.selectAll(state.serviceReportMemos),
  (state: State) =>
    serviceReportCommentsSelectors.selectAll(state.serviceReportComments),
  (_state: State, fShipNo: string) => fShipNo,
  (memoDrafts, commentDrafts, fShipNo) => {
    const map = new Map<number, number>();

    const memos = memoDrafts.filter((m) => m.fShipNo === fShipNo);
    const comments = commentDrafts.filter((c) => c.fShipNo === fShipNo);
    memos.map((memo) =>
      map.set(memo.serviceReportId!, (map.get(memo.serviceReportId!) || 0) + 1)
    );
    comments.map((comment) =>
      map.set(
        comment.serviceReportId!,
        (map.get(comment.serviceReportId!) || 0) + 1
      )
    );

    return map;
  }
);

/**
 * 指定船舶の下書き船舶メモ一覧と船舶コメント一覧を取得
 */
const shipDraftByFShipNo = createSelector(
  (state: State) => shipMemosSelectors.selectAll(state.shipMemos),
  (state: State) => shipCommentsSelectors.selectAll(state.shipComments),
  (_state: State, props: { fShipNo: string; categories: number[] }) => props,
  (memosState, commentsState, props) => {
    const memos = memosState
      .filter((m) => {
        const isTarget = m.fShipNo === props.fShipNo;

        // 指定カテゴリを少なくとも一つ所有するか判定
        const hasQueryCategory = !!props.categories.length
          ? isDuplicateCategory(m.categories || [], props.categories)
          : true;

        return isTarget && hasQueryCategory;
      })
      .map(jsonToDraftMemo);

    const comments = commentsState
      .filter((m) => m.fShipNo === props.fShipNo)
      .map(jsonToDraftComment);

    return { memos, comments };
  }
);

/**
 * 指定船舶の下書き図面メモ一覧と図面コメント一覧を取得
 */
const diagramDraftByFShipNo = createSelector(
  (state: State) => diagramMemosSelectors.selectAll(state.diagramMemos),
  (state: State) => diagramCommentsSelectors.selectAll(state.diagramComments),
  (_state: State, props: { fShipNo: string; diagramId: string }) => props,
  (memos, comments, props) => ({
    memos: memos
      .filter(
        (m) => m.fShipNo === props.fShipNo && m.diagramId === props.diagramId
      )
      .map(jsonToDraftMemo),
    comments: comments
      .filter(
        (m) => m.fShipNo === props.fShipNo && m.diagramId === props.diagramId
      )
      .map(jsonToDraftComment),
  })
);

/**
 * 指定船舶の下書き機器メモ一覧と機器コメント一覧を取得
 */
const equipmentDraftByFShipNo = createSelector(
  (state: State) => equipmentMemosSelectors.selectAll(state.equipmentMemos),
  (state: State) =>
    equipmentCommentsSelectors.selectAll(state.equipmentComments),
  (
    _state: State,
    props: { fShipNo: string; equipmentId: string; categories: number[] }
  ) => props,
  (memosState, commentsState, props) => {
    const memos = memosState
      .filter((m) => {
        const isTarget =
          m.fShipNo === props.fShipNo && m.equipmentId === props.equipmentId;

        // 指定カテゴリを少なくとも一つ所有するか判定
        const hasQueryCategory = !!props.categories.length
          ? isDuplicateCategory(m.categories || [], props.categories)
          : true;

        return isTarget && hasQueryCategory;
      })
      .map(jsonToDraftMemo);

    const comments = commentsState
      .filter(
        (m) =>
          m.fShipNo === props.fShipNo && m.equipmentId === props.equipmentId
      )
      .map(jsonToDraftComment);

    return { memos, comments };
  }
);

/**
 * 指定船舶の下書き図面メモ一覧と図面コメント一覧を取得
 */
const serviceReportMemoDraftByFShipNo = createSelector(
  (state: State) =>
    serviceReportMemosSelectors.selectAll(state.serviceReportMemos),
  (state: State) =>
    serviceReportCommentsSelectors.selectAll(state.serviceReportComments),
  (_state: State, props: { fShipNo: string; serviceReportId: number }) => props,
  (memos, comments, props) => ({
    memos: memos
      .filter(
        (m) =>
          m.fShipNo === props.fShipNo &&
          m.serviceReportId === props.serviceReportId
      )
      .map(jsonToDraftMemo),
    comments: comments
      .filter(
        (m) =>
          m.fShipNo === props.fShipNo &&
          m.serviceReportId === props.serviceReportId
      )
      .map(jsonToDraftComment),
  })
);

/**
 * 指定船舶番号の下書きのサービスレポート・署名・明細・明細署名の有無を取得
 */
const hasServiceReport = createSelector(
  (state: State) =>
    serviceReportHeadersSelectors.selectAll(state.serviceReportHeaders),
  (state: State) =>
    serviceReportSignaturesSelectors.selectAll(state.serviceReportSignatures),
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (state: State) =>
    serviceReportDetailSignaturesSelectors.selectAll(
      state.serviceReportDetailSignatures
    ),
  (_state: State, fShipNo: string) => fShipNo,
  (
    serviceReportDrafts,
    serviceReportSignatureDrafts,
    serviceReportDetails,
    serviceReportDetailSignatures,
    fShipNo
  ) => {
    const hasServiceReports = (serviceReportDrafts as any[]).some(
      (s) => s.fShipNo === fShipNo
    );
    const hasServiceReportSignatures = serviceReportSignatureDrafts.some(
      (s) => s.fShipNo === fShipNo
    );
    const hasServiceReportDetails = serviceReportDetails.some(
      (s) => s.fShipNo === fShipNo
    );
    const hasServiceReportDetailSignatures = serviceReportDetailSignatures.some(
      (s) => s.fShipNo === fShipNo
    );
    return (
      hasServiceReports ||
      hasServiceReportSignatures ||
      hasServiceReportDetails ||
      hasServiceReportDetailSignatures
    );
  }
);

/**
 * 指定オーダー番号の下書きのサービスレポートを取得
 */
const serviceReportByOrderNo = createSelector(
  (state: State) =>
    serviceReportHeadersSelectors.selectAll(state.serviceReportHeaders),
  (_state: State, orderNo: string) => orderNo,
  (serviceReportDrafts, orderNo) => {
    const serviceReportDraft = serviceReportDrafts
      .filter((s) => s.orderNo === orderNo)
      .map(jsonToServiceReportHeaderDraft);

    if (!serviceReportDraft.length) return;
    const latest = serviceReportDraft.reduce((a, b) => {
      return a.updatedAt > b.updatedAt ? a : b;
    });
    return latest;
  }
);

/**
 * 指定サービスレポートIDの下書きのサービスレポートを取得
 */
const serviceReportByFShipNo = createSelector(
  (state: State) =>
    serviceReportHeadersSelectors.selectAll(state.serviceReportHeaders),
  (_state: State, fShipNo: string) => fShipNo,
  (serviceReportDrafts, fShipNo) => {
    const serviceReportDraft = serviceReportDrafts.filter(
      (s) => s.fShipNo === fShipNo
    );

    return serviceReportDraft.map(jsonToServiceReportHeaderDraft);
  }
);

/**
 * 指定船舶番号のサービスレポートに付随する下書きメモ/コメントの有無を取得
 */
const hasServiceReportMemo = createSelector(
  (state: State) =>
    serviceReportMemosSelectors.selectAll(state.serviceReportMemos),
  (state: State) =>
    serviceReportCommentsSelectors.selectAll(state.serviceReportComments),
  (_state: State, fShipNo: string) => fShipNo,
  (memoDrafts, commentDrafts, fShipNo) => {
    const hasMemos = memoDrafts.some((m) => m.fShipNo === fShipNo);
    const hasComments = commentDrafts.some((c) => c.fShipNo === fShipNo);

    return hasMemos || hasComments;
  }
);

/**
 * 指定サービスレポートID の下書き署名を取得する
 */
const serviceReportSignatureByServiceReportId = createSelector(
  (state: State) =>
    serviceReportSignaturesSelectors.selectAll(state.serviceReportSignatures),
  (_state: State, serviceReportId: number | undefined) => serviceReportId,
  (signatures, serviceReportId) =>
    signatures.find((s) => s.id === serviceReportId)
);
/**
 * 指定のサービスレポート明細ID を持つ明細署名の下書きを取得する
 */
const serviceReportSignatureByServiceReportDetailId = createSelector(
  (state: State) =>
    serviceReportDetailSignaturesSelectors.selectAll(
      state.serviceReportDetailSignatures
    ),
  (_state: State, serviceReportDetailId: number | undefined) =>
    serviceReportDetailId,
  (signatures, serviceReportDetailId) =>
    signatures.find((s) => s.id === serviceReportDetailId)
);
/**
 * 指定のサービスレポート明細署名の下書きの識別子を持つ明細署名の下書きを取得する
 */
const serviceReportSignatureByServiceReportDetailKey = createSelector(
  (state: State) =>
    serviceReportDetailSignaturesSelectors.selectAll(
      state.serviceReportDetailSignatures
    ),
  (_state: State, serviceReportDetailDraftKey: string | undefined) =>
    serviceReportDetailDraftKey,
  (signatures, serviceReportDetailDraftKey) =>
    signatures.find((s) => s.key === serviceReportDetailDraftKey)
);

/**
 * サービスレポートの下書きから ID を一覧で取得する
 *
 * ※ ID を所有する=登録済みレポートの編集下書き
 */
const serviceReportDraftIds = createSelector(
  (state: State) =>
    serviceReportHeadersSelectors.selectAll(state.serviceReportHeaders),
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (state: State) =>
    serviceReportSignaturesSelectors.selectAll(state.serviceReportSignatures),
  (state: State) =>
    serviceReportDetailSignaturesSelectors.selectAll(
      state.serviceReportDetailSignatures
    ),
  (state: State) =>
    serviceReportMemosSelectors.selectAll(state.serviceReportMemos),
  (state: State) =>
    serviceReportCommentsSelectors.selectAll(state.serviceReportComments),
  (
    serviceReportHeadersState,
    serviceReportsDetailsState,
    signatureState,
    detailSignatureState,
    memoState,
    commentState
  ) => {
    const serviceReports = serviceReportHeadersState
      .filter((s) => !!s.id)
      .map((sr) => ({ key: sr.key, id: sr.id }));
    const signatures = signatureState
      .filter((s) => !!s.id)
      .map((s) => ({ key: s.key, id: s.id }));
    const memos = memoState
      .filter((s) => s.id)
      .map((s) => ({ key: s.key, id: s.serviceReportId }));
    const comments = commentState
      .filter((s) => s.id)
      .map((s) => ({ key: s.key, id: s.serviceReportId }));
    const details = serviceReportsDetailsState
      .filter((d) => !!d.serviceReportId)
      .map((d) => ({
        key: d.key,
        // 明細のサービスレポートIDには 下書きのサービスレポートヘッダID(文字列) または 登録済みのサービスレポートID(数値) のどちらかが設定されるため、数値の場合はnumberに変換する
        id: isNumber(d.serviceReportId)
          ? Number(d.serviceReportId)
          : d.serviceReportId,
      }));
    const detailSignatures = detailSignatureState
      .filter((s) => !!s.serviceReportId)
      .map((s) => ({ key: s.key, id: s.serviceReportId }));
    return {
      serviceReports,
      signatures,
      memos,
      comments,
      details,
      detailSignatures,
    };
  }
);

/**
 * 指定サービスレポートIDを持つ下書きのサービスレポートヘッダを取得
 *
 * @param serviceReportId サービスレポートID
 */
const serviceReportHeaderById = createSelector(
  (state: State) =>
    serviceReportHeadersSelectors.selectAll(state.serviceReportHeaders),
  (
    _state: State,
    serviceReportId:
      | SerializedServiceReportHeaderDraft["id"]
      | SerializedServiceReportHeaderDraft["key"]
  ) => serviceReportId,
  (serviceReportHeaderDrafts, serviceReportId) => {
    const serviceReportHeaderDraft = serviceReportHeaderDrafts.find(
      (s) => s.id === Number(serviceReportId) || s.key === serviceReportId
    );

    return serviceReportHeaderDraft
      ? jsonToServiceReportHeaderDraft(serviceReportHeaderDraft)
      : null;
  }
);

/**
 * 指定サービスレポートIDを持つ下書きのサービスレポート明細を取得
 *
 * @param serviceReportId サービスレポートID
 */
const serviceReportDetailsByServiceReportId = createSelector(
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (
    _state: State,
    serviceReportId: ServiceReportDetailDraft["serviceReportId"]
  ) => serviceReportId,
  (serviceReportsDetails, serviceReportId) => {
    const serviceReportHeaderDraft = serviceReportsDetails.filter(
      (s) => s.serviceReportId === serviceReportId
    );

    return serviceReportHeaderDraft;
  }
);

/**
 * 指定サービスレポートIDと明細ID持つ下書きのサービスレポート明細を取得
 *
 * @param serviceReportId サービスレポートID
 */
const serviceReportDetailsByServiceReportIdDetailId = createSelector(
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (
    _state: State,
    serviceReportId: ServiceReportDetailDraft["serviceReportId"]
  ) => serviceReportId,
  (
    _state: State,
    _serviceReportId: ServiceReportDetailDraft["serviceReportId"],
    serviceReportDetailId:
      | ServiceReportDetailDraft["id"]
      | ServiceReportDetailDraft["key"]
  ) => serviceReportDetailId,
  (serviceReportsDetails, serviceReportId, serviceReportDetailId) => {
    const serviceReportHeaderDraft = serviceReportsDetails.find(
      (s) =>
        s.serviceReportId === serviceReportId &&
        (s.id === serviceReportDetailId || s.key === serviceReportDetailId)
    );
    return serviceReportHeaderDraft;
  }
);

/**
 * サービスレポート明細の下書きから登録済み明細の編集下書きを取得する
 *
 * ※ ID が number = 登録済み明細の編集下書き
 */
const serviceReportDetailEditDraft = createSelector(
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (serviceReportDetails) => {
    const edit = serviceReportDetails.filter((d) => d.id && isNumber(d.id));
    return edit;
  }
);

/**
 * サービスレポート明細署名の下書きから登録済み明細に紐づく明細署名下書きを取得する
 *
 * ※ ID がある = 登録済み明細に紐づく明細署名下書き
 */
const serviceReportDetailSignatureEditDraft = createSelector(
  (state: State) =>
    serviceReportDetailSignaturesSelectors.selectAll(
      state.serviceReportDetailSignatures
    ),
  (serviceReportDetailsSignature) => {
    const edit = serviceReportDetailsSignature.filter((ds) => !!ds.id);
    return edit;
  }
);

/**
 * 指定サービスレポートIDと指定サービスレポート明細IDを持つ下書きのサービスレポート明細を取得
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId サービスレポート明細ID
 */
const serviceReportDetailById = createSelector(
  (state: State) =>
    serviceReportDetailsSelectors.selectAll(state.serviceReportDetails),
  (
    _state: State,
    serviceReportId:
      | SerializedServiceReportHeaderDraft["id"]
      | SerializedServiceReportHeaderDraft["key"]
  ) => serviceReportId,
  (
    _state: State,
    _serviceReportId:
      | SerializedServiceReportHeaderDraft["id"]
      | SerializedServiceReportHeaderDraft["key"],
    serviceReportDetailId:
      | ServiceReportDetailDraft["id"]
      | ServiceReportDetailDraft["key"]
  ) => serviceReportDetailId,
  (serviceReportDetailDrafts, serviceReportId, serviceReportDetailId) => {
    const serviceReportDetailDraft = serviceReportDetailDrafts.find(
      (detail) =>
        detail.serviceReportId === serviceReportId &&
        (detail.id === serviceReportDetailId ||
          detail.key === serviceReportDetailId)
    );
    return serviceReportDetailDraft;
  }
);

export const adapters = {
  serviceReportHeadersAdapter,
  serviceReportDetailsAdapter,
  serviceReportSignaturesAdapter,
  serviceReportDetailSignaturesAdapter,
  serviceReportMemosAdapter,
  serviceReportCommentsAdapter,
};

export const selectors = {
  shipMemosSelectors,
  shipCommentsSelectors,
  diagramMemosSelectors,
  diagramCommentsSelectors,
  equipmentMemosSelectors,
  equipmentCommentsSelectors,
  serviceReportHeadersSelectors,
  serviceReportDetailsSelectors,
  serviceReportSignaturesSelectors,
  serviceReportDetailSignaturesSelectors,
  serviceReportMemosSelectors,
  serviceReportCommentsSelectors,
  customSelectors: {
    shipCount,
    diagramCountMap,
    equipmentCountMap,
    serviceReportMemoCountMap,
    shipDraftByFShipNo,
    diagramDraftByFShipNo,
    equipmentDraftByFShipNo,
    serviceReportMemoDraftByFShipNo,
    hasServiceReport,
    hasServiceReportMemo,
    serviceReportByOrderNo,
    serviceReportByFShipNo,
    serviceReportSignatureByServiceReportId,
    serviceReportSignatureByServiceReportDetailId,
    serviceReportSignatureByServiceReportDetailKey,
    serviceReportDraftIds,
    serviceReportHeaderById,
    serviceReportDetailsByServiceReportId,
    serviceReportDetailsByServiceReportIdDetailId,
    serviceReportDetailEditDraft,
    serviceReportDetailSignatureEditDraft,
    serviceReportDetailById,
  },
};

export const { actions, reducer } = createSlice({
  name: "draft",
  initialState: {
    shipMemos: shipMemosAdapter.getInitialState(),
    shipComments: shipCommentsAdapter.getInitialState(),
    diagramMemos: diagramMemosAdapter.getInitialState(),
    diagramComments: diagramCommentsAdapter.getInitialState(),
    equipmentMemos: equipmentMemosAdapter.getInitialState(),
    equipmentComments: equipmentCommentsAdapter.getInitialState(),
    serviceReportMemos: serviceReportMemosAdapter.getInitialState(),
    serviceReportComments: serviceReportCommentsAdapter.getInitialState(),
    serviceReportHeaders: serviceReportHeadersAdapter.getInitialState(),
    serviceReportDetails: serviceReportDetailsAdapter.getInitialState(),
    serviceReportSignatures: serviceReportSignaturesAdapter.getInitialState(),
    serviceReportDetailSignatures: serviceReportDetailSignaturesAdapter.getInitialState(),
  } as State,
  reducers: {
    shipMemoAdded(state, action: { payload: MemoDraftAdd }) {
      const memo = draftMemoToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      shipMemosAdapter.addOne(state.shipMemos, memo);
    },
    shipMemoUpdated(state, action: { payload: MemoDraftUpdate }) {
      const { key, memo } = action.payload;
      shipMemosAdapter.updateOne(state.shipMemos, { id: key, changes: memo });
    },
    shipMemoRemoved(state, action: { payload: SerializedMemoDraft["key"] }) {
      shipMemosAdapter.removeOne(state.shipMemos, action.payload);
    },
    shipMemoAllRemoved(state) {
      shipMemosAdapter.removeAll(state.shipMemos);
    },
    shipCommentAdded(state, action: { payload: CommentDraftAdd }) {
      const comment = draftCommentToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      shipCommentsAdapter.addOne(state.shipComments, comment);
    },
    shipCommentUpdated(state, action: { payload: CommentDraftUpdate }) {
      const { key, comment } = action.payload;
      shipCommentsAdapter.updateOne(state.shipComments, {
        id: key,
        changes: comment,
      });
    },
    shipCommentRemoved(
      state,
      action: { payload: SerializedCommentDraft["key"] }
    ) {
      shipCommentsAdapter.removeOne(state.shipComments, action.payload);
    },
    shipCommentAllRemoved(state) {
      shipCommentsAdapter.removeAll(state.shipComments);
    },
    diagramMemoAdded(
      state,
      action: { payload: RequiredDiagramId<MemoDraftAdd> }
    ) {
      const memo = draftMemoToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      diagramMemosAdapter.addOne(state.diagramMemos, memo);
    },
    diagramMemoUpdated(state, action: { payload: MemoDraftUpdate }) {
      const { key, memo } = action.payload;
      diagramMemosAdapter.updateOne(state.diagramMemos, {
        id: key,
        changes: memo,
      });
    },
    diagramMemoRemoved(state, action: { payload: SerializedMemoDraft["key"] }) {
      diagramMemosAdapter.removeOne(state.diagramMemos, action.payload);
    },
    diagramMemoAllRemoved(state) {
      diagramMemosAdapter.removeAll(state.diagramMemos);
    },
    diagramCommentAdded(
      state,
      action: { payload: RequiredDiagramId<CommentDraftAdd> }
    ) {
      const comment = draftCommentToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      diagramCommentsAdapter.addOne(state.diagramComments, comment);
    },
    diagramCommentUpdated(state, action: { payload: CommentDraftUpdate }) {
      const { key, comment } = action.payload;
      diagramCommentsAdapter.updateOne(state.diagramComments, {
        id: key,
        changes: comment,
      });
    },
    diagramCommentRemoved(
      state,
      action: { payload: SerializedCommentDraft["key"] }
    ) {
      diagramCommentsAdapter.removeOne(state.diagramComments, action.payload);
    },
    diagramCommentAllRemoved(state) {
      diagramCommentsAdapter.removeAll(state.diagramComments);
    },
    equipmentMemoAdded(
      state,
      action: { payload: RequiredEquipmentId<MemoDraftAdd> }
    ) {
      const memo = draftMemoToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      equipmentMemosAdapter.addOne(state.equipmentMemos, memo);
    },
    equipmentMemoUpdated(state, action: { payload: MemoDraftUpdate }) {
      const { key, memo } = action.payload;
      equipmentMemosAdapter.updateOne(state.equipmentMemos, {
        id: key,
        changes: memo,
      });
    },
    equipmentMemoRemoved(
      state,
      action: { payload: SerializedMemoDraft["key"] }
    ) {
      equipmentMemosAdapter.removeOne(state.equipmentMemos, action.payload);
    },
    equipmentMemoAllRemoved(state) {
      equipmentMemosAdapter.removeAll(state.equipmentMemos);
    },
    equipmentCommentAdded(
      state,
      action: { payload: RequiredEquipmentId<CommentDraftAdd> }
    ) {
      const comment = draftCommentToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      equipmentCommentsAdapter.addOne(state.equipmentComments, comment);
    },
    equipmentCommentUpdated(state, action: { payload: CommentDraftUpdate }) {
      const { key, comment } = action.payload;
      equipmentCommentsAdapter.updateOne(state.equipmentComments, {
        id: key,
        changes: comment,
      });
    },
    equipmentCommentRemoved(
      state,
      action: { payload: SerializedCommentDraft["key"] }
    ) {
      equipmentCommentsAdapter.removeOne(
        state.equipmentComments,
        action.payload
      );
    },
    equipmentCommentAllRemoved(state) {
      equipmentCommentsAdapter.removeAll(state.equipmentComments);
    },
    serviceReportMemoAdded(state, action: { payload: MemoDraftAdd }) {
      const memo = draftMemoToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      serviceReportMemosAdapter.addOne(state.serviceReportMemos, memo);
    },
    serviceReportMemoUpdated(state, action: { payload: MemoDraftUpdate }) {
      const { key, memo } = action.payload;
      serviceReportMemosAdapter.updateOne(state.serviceReportMemos, {
        id: key,
        changes: memo,
      });
    },
    serviceReportMemoRemoved(
      state,
      action: { payload: SerializedMemoDraft["key"] }
    ) {
      serviceReportMemosAdapter.removeOne(
        state.serviceReportMemos,
        action.payload
      );
    },
    serviceReportMemoAllRemoved(state) {
      serviceReportMemosAdapter.removeAll(state.serviceReportMemos);
    },
    serviceReportCommentAdded(state, action: { payload: CommentDraftAdd }) {
      const comment = draftCommentToJson({
        ...action.payload,
        key: v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      serviceReportCommentsAdapter.addOne(state.serviceReportComments, comment);
    },
    serviceReportCommentUpdated(
      state,
      action: { payload: CommentDraftUpdate }
    ) {
      const { key, comment } = action.payload;
      serviceReportCommentsAdapter.updateOne(state.serviceReportComments, {
        id: key,
        changes: comment,
      });
    },
    serviceReportCommentRemoved(
      state,
      action: { payload: SerializedCommentDraft["key"] }
    ) {
      serviceReportCommentsAdapter.removeOne(
        state.serviceReportComments,
        action.payload
      );
    },
    serviceReportCommentAllRemoved(state) {
      serviceReportCommentsAdapter.removeAll(state.serviceReportComments);
    },
    serviceReportHeaderEdit2CreateConverted(
      state,
      action: { payload: SerializedServiceReportHeaderDraft["key"] }
    ) {
      serviceReportHeadersAdapter.updateOne(state.serviceReportHeaders, {
        id: action.payload,
        changes: { id: undefined },
      });
    },
    serviceReportHeaderAdded(
      state,
      action: {
        payload: SerializedServiceReportHeaderDraft;
      }
    ) {
      serviceReportHeadersAdapter.addOne(
        state.serviceReportHeaders,
        action.payload
      );
    },
    serviceReportHeaderUpdated(
      state,
      action: { payload: SerializedServiceReportHeaderDraft }
    ) {
      const { key, ...rest } = action.payload;
      serviceReportHeadersAdapter.updateOne(state.serviceReportHeaders, {
        id: key,
        changes: { ...rest, key },
      });
    },
    serviceReportHeaderRemoved(
      state,
      action: {
        payload: ServiceReportHeaderDraft["key"];
      }
    ) {
      serviceReportHeadersAdapter.removeOne(
        state.serviceReportHeaders,
        action.payload
      );
    },
    serviceReportDetailAdded(
      state,
      action: {
        payload: ServiceReportDetailDraft;
      }
    ) {
      serviceReportDetailsAdapter.addOne(
        state.serviceReportDetails,
        action.payload
      );
    },
    serviceReportDetailUpdated(
      state,
      action: { payload: ServiceReportDetailDraft }
    ) {
      const { key, ...rest } = action.payload;
      serviceReportDetailsAdapter.updateOne(state.serviceReportDetails, {
        id: key,
        changes: { ...rest, key },
      });
    },
    serviceReportDetailRemoved(
      state,
      action: {
        payload: ServiceReportDetailDraft["key"];
      }
    ) {
      serviceReportDetailsAdapter.removeOne(
        state.serviceReportDetails,
        action.payload
      );
    },
    /**
     * 指定したサービスレポートIDの明細下書きをすべて削除する
     */
    serviceReportDetailRemovedByServiceReportId(
      state,
      action: {
        payload: ServiceReportDetailDraft["serviceReportId"];
      }
    ) {
      const details = serviceReportDetailsAdapter
        .getSelectors()
        .selectAll(state.serviceReportDetails);
      const filtered = details.filter(
        (d) => d.serviceReportId === action.payload
      );
      if (filtered.length === 0) return;
      serviceReportDetailsAdapter.removeMany(
        state.serviceReportDetails,
        filtered.map((f) => f.key)
      );
    },
    /**
     * 指定したサービスレポート明細IDの明細下書きを削除する
     */
    serviceReportDetailRemovedById(
      state,
      action: {
        payload: ServiceReportDetailDraft["id"];
      }
    ) {
      const details = serviceReportDetailsAdapter
        .getSelectors()
        .selectAll(state.serviceReportDetails);
      const find = details.find((d) => d.id === action.payload);
      if (!find) return;
      serviceReportDetailsAdapter.removeOne(
        state.serviceReportDetails,
        find.key
      );
    },
    serviceReportDetailEdit2CreateConverted(
      state,
      action: { payload: ServiceReportDetailDraft["key"] }
    ) {
      serviceReportDetailsAdapter.updateOne(state.serviceReportDetails, {
        id: action.payload,
        changes: { id: undefined },
      });
    },
    serviceReportSignatureAdded(
      state,
      action: { payload: ServiceReportSignatureDraft }
    ) {
      serviceReportSignaturesAdapter.addOne(
        state.serviceReportSignatures,
        action.payload
      );
    },
    serviceReportSignatureUpdated(
      state,
      action: {
        payload: {
          key: ServiceReportSignatureDraft["key"];
          data: Omit<ServiceReportSignatureDraft, "key" | "fShipNo">;
        };
      }
    ) {
      const { key, data } = action.payload;
      serviceReportSignaturesAdapter.updateOne(state.serviceReportSignatures, {
        id: key,
        changes: data,
      });
    },
    serviceReportSignatureRemoved(
      state,
      action: { payload: ServiceReportSignatureDraft["key"] }
    ) {
      serviceReportSignaturesAdapter.removeOne(
        state.serviceReportSignatures,
        action.payload
      );
    },
    /**
     * 指定したサービスレポートIDの技術者書名を未登録に上書きする
     */
    serviceReportTechnicianSignaturedEmptyUpdate(
      state,
      action: { payload: ServiceReportSignatureDraft["key"] }
    ) {
      const signature = serviceReportSignaturesAdapter
        .getSelectors()
        .selectAll(state.serviceReportSignatures);
      // 指定したサービスレポートの下書き署名を取得する
      const filtered = signature.filter((d) => d.key === action.payload);
      if (filtered.length === 0) return;

      serviceReportSignaturesAdapter.updateOne(state.serviceReportSignatures, {
        id: action.payload,
        changes: {
          technicianSignature: undefined,
          technicianSignedAt: undefined,
        },
      });
    },
    /**
     * 指定したサービスレポートIDのお客様書名を未登録に上書きする
     */
    serviceReportCustomerSignatureEmptyUpdate(
      state,
      action: { payload: ServiceReportSignatureDraft["key"] }
    ) {
      const signature = serviceReportSignaturesAdapter
        .getSelectors()
        .selectAll(state.serviceReportSignatures);
      // 指定したサービスレポートの下書き署名を取得する
      const filtered = signature.filter((d) => d.key === action.payload);
      if (filtered.length === 0) return;

      serviceReportSignaturesAdapter.updateOne(state.serviceReportSignatures, {
        id: action.payload,
        changes: {
          customerSignature: undefined,
          customerRemarks: undefined,
          customerSignedAt: undefined,
        },
      });
    },
    /**
     * 指定したサービスレポートIDの書名下書きが全て空白の場合削除する
     */
    serviceReportEmptySignatureDelete(
      state,
      action: { payload: ServiceReportSignatureDraft["key"] }
    ) {
      const signature = serviceReportSignaturesAdapter
        .getSelectors()
        .selectAll(state.serviceReportSignatures);
      // 指定したサービスレポートの下書き署名を取得する
      const find = signature.find((d) => d.key === action.payload);
      if (
        find === undefined ||
        !!find.customerSignature ||
        !!find.customerRemarks ||
        !!find.technicianSignature
      )
        return;

      serviceReportSignaturesAdapter.removeOne(
        state.serviceReportSignatures,
        find.key
      );
    },
    /**
     * 指定したサービスレポートIDの署名下書きを削除する
     */
    serviceReportSignatureRemovedByServiceReportId(
      state,
      action: { payload: string }
    ) {
      const signatures = serviceReportSignaturesAdapter
        .getSelectors()
        .selectAll(state.serviceReportSignatures);
      const find = signatures.find(
        (d) => d.id === Number(action.payload) || d.key === action.payload
      );
      if (!find) return;
      serviceReportSignaturesAdapter.removeOne(
        state.serviceReportSignatures,
        find.key
      );
    },
    serviceReportDetailSignatureAdded(
      state,
      action: { payload: ServiceReportDetailSignatureDraft }
    ) {
      serviceReportDetailSignaturesAdapter.addOne(
        state.serviceReportDetailSignatures,
        action.payload
      );
    },
    serviceReportSignatureDetailUpdated(
      state,
      action: {
        payload: {
          key: ServiceReportDetailSignatureDraft["key"];
          data: Omit<ServiceReportDetailSignatureDraft, "key" | "fShipNo">;
        };
      }
    ) {
      const { key, data } = action.payload;
      serviceReportDetailSignaturesAdapter.updateOne(
        state.serviceReportDetailSignatures,
        {
          id: key,
          changes: data,
        }
      );
    },
    serviceReportDetailSignatureRemoved(
      state,
      action: { payload: ServiceReportDetailSignatureDraft["key"] }
    ) {
      serviceReportDetailSignaturesAdapter.removeOne(
        state.serviceReportDetailSignatures,
        action.payload
      );
    },
    // サービスレポート明細署名下書きの新しい明細と紐付ける
    serviceReportDetailSignatureConverted(
      state,
      action: {
        payload: {
          id: ServiceReportDetailSignatureDraft["id"];
          key: ServiceReportDetailSignatureDraft["key"];
        };
      }
    ) {
      const signatures = serviceReportDetailSignaturesAdapter
        .getSelectors()
        .selectAll(state.serviceReportDetailSignatures);
      const find = signatures.find(
        (d) => d.id === action.payload.id || d.key === action.payload.key
      );
      if (!find) return;
      serviceReportDetailSignaturesAdapter.updateOne(
        state.serviceReportDetailSignatures,
        {
          id: find.key,
          changes: { id: undefined, key: action.payload.key },
        }
      );
    },
  },
});
