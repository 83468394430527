import classNames from "classnames";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./GoToDashboard.module.css";
import { ReactComponent as Icon } from "./ic_60_dashboard.svg";
import { selectors, useBoundSelector } from "../../../store";

type Props = {};

/**
 * このコンポーネントの責務はダッシュボード画面へ遷移するボタンを表示すること
 */
const GoToDashboard: FC<Props> = () => {
  const inDashboard = useLocation().pathname.startsWith("/dashboard");

  const fulfilledConnect = useBoundSelector((s) =>
    selectors.setting.customSelectors.fulfilledConnectSelector(s.setting)
  );

  return inDashboard ? (
    <span className={classNames(styles.host, styles.disabled)}>
      <Icon />
    </span>
  ) : (
    <Link
      to={fulfilledConnect ? "/dashboard" : "/bookmarks"}
      className={styles.host}
    >
      <Icon />
    </Link>
  );
};
export default GoToDashboard;
