import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { ServiceOrder } from "../../models";

type State = {
  serviceOrders: EntityState<ServiceOrder>;
};

const serviceOrdersAdapter = createEntityAdapter<ServiceOrder>({
  selectId: (serviceOrder) => serviceOrder.serviceOrderNo,
});
export const serviceOrdersSelectors = serviceOrdersAdapter.getSelectors();

export const { actions, reducer } = createSlice({
  name: "ship",
  initialState: {
    serviceOrders: serviceOrdersAdapter.getInitialState(),
  } as State,
  reducers: {
    serviceOrdersReceived(state, action: { payload: ServiceOrder[] }) {
      serviceOrdersAdapter.setAll(state.serviceOrders, action.payload);
    },
  },
});
