import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AppErrorLoginSessionExpiration,
  convertToError,
  isExpectedError,
  isInvalidLoginSessionError,
} from "../../models/error";
import { useBoundActions, useBoundSelector } from "../../store";
import { auth, maintenance } from "../../usecases";
import SystemError from "../ErrorBoundary/SystemError";

type Props = {};

/**
 * このコンポーネントの責務はエラー発生時、エラーを画面に表示すること
 */
const ErrorGate: FC<Props> = ({ children }) => {
  const errors = useBoundSelector((s) => s.site.errors ?? []);
  useWindowErrorEventHandler();

  if (errors.length > 0) return <SystemError errors={errors} />;
  return <>{children}</>;
};
export default ErrorGate;

/**
 * ログアウト済みか
 *
 * 複数回アラートメッセージが表示されないようにするために必要。
 * ログアウト後は location.href = '/login'; で画面遷移するためグローバル変数で問題ない
 */
let loggedOut = false;

function useWindowErrorEventHandler() {
  const { t } = useTranslation();
  const { site } = useBoundActions();

  useEffect(() => {
    const detected = (x: unknown) => {
      (async () => {
        try {
          if (x instanceof AppErrorLoginSessionExpiration) {
            if (!loggedOut) {
              loggedOut = true;
              alert(t("Your login session has expired. Logged out."));
              await auth.logout();
            }
            return;
          }

          if (await isInvalidLoginSessionError(x)) {
            await auth.logout();
            return;
          }

          if (isExpectedError(x)) return; // 発生することが想定内であるエラーについては無視する

          const error = await convertToError(x);
          site.errorDetected(error);

          gtag("event", "exception", {
            description: JSON.stringify(error),
            fatal: true,
          });

          // エラー発生時はメンテナンス状態をチェックする
          // メンテナンスモードの時、MaintenanceGate コンポーネントにより
          // メンテナンス画面が表示されるようになる
          await maintenance.checkCurrentMaintenanceState();
        } catch {
          // 無限ループにならないように全てのエラーを無視する
        }
      })();
    };
    const onError = (event: ErrorEvent) => detected(event);
    const onUnhandledRejection = (event: PromiseRejectionEvent) =>
      detected(event.reason);

    window.addEventListener("error", onError);
    window.addEventListener("unhandledrejection", onUnhandledRejection);

    return () => {
      window.removeEventListener("error", onError);
      window.removeEventListener("unhandledrejection", onUnhandledRejection);
    };
  }, [site, t]);
}
