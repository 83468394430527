import type {
  Comment,
  CommentActive,
  CommentDeleted,
  MemoDraft,
  Memo,
  MemoActive,
  MemoDeleted,
  CommentDraft,
} from "../../models";

type SerializedDateProperties<
  T extends { createdAt: Date; updatedAt: Date }
> = Omit<T, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
};

type SerializedMemoType<
  T extends { createdAt: Date; updatedAt: Date; comments: Comment[] }
> = Omit<SerializedDateProperties<T>, "comments"> & {
  comments: SerializedComment[];
};
export type SerializedMemo =
  | SerializedMemoType<MemoActive>
  | SerializedMemoType<MemoDeleted>;

export function memoToJson(memo: Memo): SerializedMemo {
  return {
    ...memo,
    createdAt: memo.createdAt.toISOString(),
    updatedAt: memo.updatedAt.toISOString(),
    comments: memo.comments.map(commentToJson),
  };
}

export function jsonToMemo(json: SerializedMemo): Memo {
  return {
    ...json,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
    comments: json.comments.map(jsonToComment),
  };
}

export type SerializedMemoDraft = SerializedDateProperties<MemoDraft>;

export function draftMemoToJson(memo: MemoDraft): SerializedMemoDraft {
  return {
    ...memo,
    createdAt: memo.createdAt.toISOString(),
    updatedAt: memo.updatedAt.toISOString(),
  };
}

export function jsonToDraftMemo(json: SerializedMemoDraft): MemoDraft {
  return {
    ...json,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
  };
}

export type SerializedComment =
  | SerializedDateProperties<CommentActive>
  | SerializedDateProperties<CommentDeleted>;

export function commentToJson(comment: Comment): SerializedComment {
  return {
    ...comment,
    createdAt: comment.createdAt.toISOString(),
    updatedAt: comment.updatedAt.toISOString(),
  };
}

export function jsonToComment(json: SerializedComment): Comment {
  return {
    ...json,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
  };
}

export type SerializedCommentDraft = SerializedDateProperties<CommentDraft>;

export function draftCommentToJson(
  comment: CommentDraft
): SerializedCommentDraft {
  return {
    ...comment,
    createdAt: comment.createdAt.toISOString(),
    updatedAt: comment.updatedAt.toISOString(),
  };
}

export function jsonToDraftComment(json: SerializedCommentDraft): CommentDraft {
  return {
    ...json,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
  };
}
