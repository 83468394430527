import classNames from "classnames";
import {
  forwardRef,
  Fragment,
  MutableRefObject,
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Custom.module.css";
import { ReactComponent as OffIcon } from "./ic_40_checkbox_off.svg";
import { ReactComponent as OnIcon } from "./ic_40_checkbox_on.svg";
import { ReactComponent as CloseIcon } from "./ic_40_close_bl.svg";

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  unselectedText?: string;
  options: Array<{ value: string; text?: string }>;
  onFocusChange?: (focus: boolean) => void;
  className?: string;
  onSelected?: (selected: string[]) => void;
  bottomPadding?: number;
  max?: number;
  invalid?: boolean;
  resizeFlag?: boolean;
};

/**
 * このコンポーネントの責務は選択肢の中から項目を一つ選ぶ機能を
 * 標準の select の代わりに独自の見た目を実装して提供すること
 *
 * 注意: <select multiple> 相当の複数選択専用
 */
const Custom = forwardRef<HTMLSelectElement, Props>(
  (
    {
      unselectedText,
      options,
      name,
      defaultValue,
      onFocusChange,
      className,
      onSelected,
      bottomPadding = 0,
      max = options.length + 1,
      invalid,
      resizeFlag,
    },
    ref
  ) => {
    const [focus, setFocusRaw] = useState(false);
    const setFocus = (focus: boolean) => {
      setFocusRaw(focus);
      onFocusChange?.(focus);
    };

    const [selected, setSelected] = useState<string[]>(() => {
      const x = defaultValue ?? [];
      return Array.isArray(x) ? x : [String(x)];
    });

    const optionState = useMemo(
      () =>
        options.map((opt) => ({
          ...opt,
          selected: selected.includes(opt.value),
          disabled: selected.includes(opt.value)
            ? false
            : selected.length >= max,
        })),
      [options, selected, max]
    );

    const onToggleOption = useCallback(
      (value: string) => {
        const filtered = selected.includes(value)
          ? selected.filter((s) => s !== value) // remove
          : [...selected, value]; // append

        setSelected(filtered);
        // 親へ選択した項目を伝播
        onSelected?.(filtered);
      },
      [selected, onSelected]
    );

    const listboxRef = useRef<HTMLDivElement | null>(null);
    const visibleActiveOptionLength = useVisibleActiveOptionLength(
      listboxRef,
      optionState,
      resizeFlag
    );
    const itemsRef = useRefForHeightWithinViewport(bottomPadding);

    return (
      <div className={styles.host}>
        <select
          hidden
          name={name}
          multiple
          value={optionState
            .filter((opt) => opt.selected)
            .map((opt) => opt.value)}
          onChange={(event) => {
            setSelected(
              Array.from(event.target.selectedOptions, (option) => option.value)
            );
          }}
          ref={ref}
        >
          {optionState.map(({ value, text = value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </select>
        <div
          className={classNames(
            styles.listBox,
            className,
            invalid && styles.invalid
          )}
          tabIndex={0}
          role="listbox"
          aria-multiselectable={true}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={listboxRef}
        >
          {unselectedText &&
          optionState.filter((opt) => opt.selected).length === 0 ? (
            <span className={styles.unselectedText}>{unselectedText}</span>
          ) : null}
          {optionState
            .filter((opt) => opt.selected)
            .map(({ value, text = value }, index, array) => (
              <Fragment key={value}>
                <span
                  className={styles.activeOption}
                  hidden={index >= visibleActiveOptionLength}
                >
                  <span className={styles.activeOptionText}>{text}</span>
                  <span
                    className={styles.close}
                    onClick={() => {
                      onToggleOption(value);
                      listboxRef.current?.focus();
                    }}
                    aria-label="remove category"
                  >
                    <CloseIcon />
                  </span>
                </span>
                {array.length > visibleActiveOptionLength &&
                  index === visibleActiveOptionLength - 1 && (
                    <span className={styles.activeOptionEllipsis}>…</span>
                  )}
              </Fragment>
            ))}
          {focus && (
            <div className={styles.items} ref={itemsRef}>
              {optionState.map(
                ({ value, text = value, selected, disabled }) => (
                  <div
                    key={value}
                    className={classNames(
                      styles.option,
                      disabled && styles.disabled
                    )}
                    role="option"
                    aria-selected={selected}
                    aria-disabled={disabled}
                    onClick={() => onToggleOption(value)}
                  >
                    {selected ? <OnIcon /> : <OffIcon />}
                    <span className={styles.optionText}>{text}</span>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
export default Custom;

/**
 * listbox の選択肢のオーバーレイ表示が画面外にはみ出て表示されないようにするための ref
 */
function useRefForHeightWithinViewport(bottomPadding: number) {
  return useCallback(
    (elem: HTMLElement | null) => {
      if (!elem) return;

      const { y } = elem.getBoundingClientRect();
      const maxHeight = window.innerHeight - y - bottomPadding;

      elem.style.maxHeight = `${maxHeight}px`;
    },
    [bottomPadding]
  );
}

/**
 * 現在選択状態の選択を listbox からはみ出さない範囲で表示できる option の数
 */
function useVisibleActiveOptionLength(
  listboxRef: MutableRefObject<HTMLDivElement | null>,
  optionState: unknown,
  resizeFlag?: boolean
) {
  const [length, setLength] = useState(Infinity);

  useEffect(() => {
    const listbox = listboxRef.current;
    if (!listbox) return;
    const listboxRect = listbox.getBoundingClientRect();

    const activeOptions = Array.from(listbox.children).filter((e) =>
      e.classList.contains(styles.activeOption)
    );

    for (let i = 0; i < activeOptions.length; i++) {
      const optionRect = activeOptions[i].getBoundingClientRect();
      const isContain =
        optionRect.x > listboxRect.x &&
        optionRect.x + optionRect.width <
          listboxRect.x + listboxRect.width - 30 &&
        optionRect.y > listboxRect.y &&
        optionRect.y + optionRect.height < listboxRect.y + listboxRect.height;
      if (!isContain) {
        break;
      } else {
        setLength(i + 1);
      }
    }
  }, [listboxRef, optionState, resizeFlag]);

  return length;
}
