import { forwardRef, InputHTMLAttributes } from "react";
import styles from "./CategoryBadge.module.css";

type Props = InputHTMLAttributes<HTMLInputElement>;

/**
 * このコンポーネントの責務はメモに紐づいたカテゴリを表示すること
 **/
const CategoryBadge = forwardRef<HTMLInputElement, Props>(
  ({ id, children, ...rest }, ref) => {
    return (
      <>
        <input
          type="checkbox"
          id={id}
          {...rest}
          ref={ref}
          className={styles.checkbox}
        />
        <label htmlFor={id} className={styles.label}>
          {children}
        </label>
      </>
    );
  }
);

export default CategoryBadge;
