import {
  MemoType as WebApiMemoType,
  Mention,
  SearchMemo as WebApiSearchMemo,
} from "../webapi/generated";
import { AttachmentFile } from "./diagram";

export type SearchMemo = WebApiSearchMemo;
export type SearchMemoWithBreadcrumbs = WebApiSearchMemo & {
  breadcrumbs: string[];
  files: AttachmentFile[];
};

export type MentionMemoWithBreadcrumbs = Mention & {
  breadcrumbs: string[];
  files: AttachmentFile[];
  isUnread: boolean;
};

/**
 * メモ種別
 */
export const memoType = {
  SHIP: 1,
  DIAGRAM: 2,
  EQUIPMENT: 3,
  SERVICE_REPORT: 4,
} as const;

/**
 * メモ種別
 */
export type MemoType = typeof memoType[keyof typeof memoType];

/**
 * メモ種別名
 */
export const memoTypeName = {
  [memoType.SHIP]: "Ship Memo",
  [memoType.DIAGRAM]: "Diagram Memo",
  [memoType.EQUIPMENT]: "Equipment Memo",
  [memoType.SERVICE_REPORT]: "Service Report Memo",
};

/**
 * メモ検索用種別名
 */
export const memoSearchTypeName = {
  [memoType.SHIP]: "Ship",
  [memoType.DIAGRAM]: "Diagram",
  [memoType.EQUIPMENT]: "Equipment",
  [memoType.SERVICE_REPORT]: "Service Report",
};

/**
 * メモ種別（Union）から Enum 変換処理
 *
 * @param type メモ種別
 * @returns
 */
export function convertMemoTypeUnionToEnum(type: string) {
  switch (type) {
    case `${memoType.SHIP}`:
      return WebApiMemoType.NUMBER_1;
    case `${memoType.DIAGRAM}`:
      return WebApiMemoType.NUMBER_2;
    case `${memoType.EQUIPMENT}`:
      return WebApiMemoType.NUMBER_3;
    case `${memoType.SERVICE_REPORT}`:
      return WebApiMemoType.NUMBER_4;
    default:
      return WebApiMemoType.NUMBER_1;
  }
}

/**
 * メモ詳細リダイレクト元
 */
export const memoRedirectFrom = {
  NOTIFICATION: 1,
  MEMO_SEARCH: 2,
  MENTION_MODAL: 3,
  DASHBOARD: 4,
} as const;

/**
 * メモ詳細リダイレクト元
 */
export type MemoRedirectFrom = typeof memoRedirectFrom[keyof typeof memoRedirectFrom];

/**
 * メモ詳細リダイレクト元名
 */
export const memoRedirectFromName = {
  [memoRedirectFrom.NOTIFICATION]: "Notification",
  [memoRedirectFrom.MEMO_SEARCH]: "Memo Search",
};
