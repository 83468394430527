import ExtensibleCustomError from "extensible-custom-error";
import { assert, array, Describe, Infer, number, object } from "superstruct";
import { DrawingInstance } from "./drawing";
import { DrawingInstanceSchema } from "./drawing-serializer";
import { SignatureFormula } from "../webapi/generated";

export type BackgroundSize = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const ViewboxSchema = object({
  x: number(),
  y: number(),
  width: number(),
  height: number(),
});

const DeserializedSchema: Describe<{
  version: number;
  viewbox: BackgroundSize;
  instances: DrawingInstance[];
}> = object({
  version: number(),
  viewbox: ViewboxSchema,
  instances: array(DrawingInstanceSchema),
});

/**
 * 署名式(サイン種別)
 *
 * 1: 一括
 * 2: 分割
 */
export const signatureFormulaType = {
  BULK: SignatureFormula.NUMBER_1,
  SPLIT: SignatureFormula.NUMBER_2,
};
export type SignatureFormulaType = typeof signatureFormulaType[keyof typeof signatureFormulaType];

/**
 * 署名内容
 */
export type Signature = {
  viewbox: BackgroundSize;
  instances: DrawingInstance[];
};

/**
 * 署名インスタンスを（復元可能な形で）文字列化する
 */
export function serializeSignature(signature: Signature) {
  const schema: Infer<typeof DeserializedSchema> = {
    version: 2,
    instances: signature.instances,
    viewbox: signature.viewbox,
  };
  return JSON.stringify(schema);
}

/**
 * 署名情報を文字列から復元する
 */
export function deserializeSignature(data: string): Signature {
  try {
    const unsafe = JSON.parse(data);
    assert(unsafe, object());
    assert(unsafe, DeserializedSchema);
    return unsafe;
  } catch (err) {
    if (err instanceof Error) {
      console.warn(
        new AppErrorSignatureDeserialization(
          "failed to deserialize signature",
          err
        )
      );
    }
    throw err;
  }
}

/**
 * 署名情報の復元の失敗を表すエラー
 */
export class AppErrorSignatureDeserialization extends ExtensibleCustomError {}
