/**
 * このファイルは各画面が自身のページレイアウトを指定できるようにするための機能を提供する
 */

import {
  cloneElement,
  createContext,
  DependencyList,
  FC,
  ReactElement,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

type LayoutContextType = {
  layoutElements: Array<ReactElement>;
  setLayoutElements: (elements: ReactElement[]) => void;
};

const LayoutContext = createContext<LayoutContextType>({
  layoutElements: [],
  setLayoutElements: () => {},
});

export const Layout: FC = ({ children }) => {
  const [layoutElements, setLayoutElements] = useState<ReactElement[]>([]);
  return (
    <LayoutContext.Provider value={{ layoutElements, setLayoutElements }}>
      {layoutElements.reduceRight((children, element) => {
        // 開発環境なら子コンポーネントが既に与えられているかチェックする
        if (process.env.NODE_ENV === "development" && element.props.children)
          console.warn(
            "useLayouts に与えるレイアウト用のコンポーネントの children 自動的に埋め込まれます。指定しないでください"
          );

        return cloneElement(element, { children });
      }, children)}
    </LayoutContext.Provider>
  );
};

export function useLayouts(
  layoutElements: ReactElement[],
  deps: DependencyList
) {
  const { setLayoutElements } = useContext(LayoutContext);
  useLayoutEffect(() => {
    setLayoutElements(layoutElements);
    return () => setLayoutElements([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, setLayoutElements]);
}
