import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { adapters, selectors } from "../modules";
import { PersistedRootStateV3 } from "./v3";
import { SerializedCommentDraft, SerializedMemoDraft } from "../serializers";
import { ServiceReportDetailDraft } from "../../models";
import { SerializedServiceReportHeaderDraft } from "../serializers/serviceReport";

/**
 * v4 (ph7.5 以降のステート)
 */
export type PersistedRootStateV4 = Omit<PersistedRootStateV3, "draft"> & {
  draft: Omit<PersistedRootStateV3["draft"], "serviceReports"> & {
    serviceReportHeaders: EntityState<SerializedServiceReportHeaderDraft>;
    serviceReportDetails: EntityState<ServiceReportDetailDraft>;
  };
};

/**
 * v4 移行
 *
 * @param state v3 のステート定義
 * @returns v4 のステート定義
 */
function migrate(state: PersistedRootStateV3): PersistedRootStateV4 {
  const { draft, bookmark } = adapters;

  // サービスレポートのメモ・コメント用のステート操作用アダプタを宣言
  const memoAdapter = createEntityAdapter<SerializedMemoDraft>();
  const commentAdapter = createEntityAdapter<SerializedCommentDraft>();

  // ブックマークに登録済みの船情報が持つサービスレポート系の下書き所有判定を一律で False とする
  let shipAppendices = selectors.bookmark.shipAppendicesSelectors.selectAll(
    state.bookmark.shipAppendices
  );
  shipAppendices = shipAppendices.map((ship) => ({
    ...ship,
    hasServiceReportDrafts: false,
    hasServiceReportMemoDrafts: false,
  }));
  const result = bookmark.shipAppendicesAdapter.setAll(
    state.bookmark.shipAppendices,
    shipAppendices
  );

  return {
    ...state,
    bookmark: {
      ...state.bookmark,
      shipAppendices: result,
    },
    draft: {
      ...state.draft,
      serviceReportHeaders: draft.serviceReportHeadersAdapter.getInitialState(),
      serviceReportDetails: draft.serviceReportDetailsAdapter.getInitialState(),
      // サービスレポートに紐づくメモ・コメントは ph7.5 以降非表示となる。
      // また導線も削除されるため下書きは当マイグレーションで削除する。
      // 追々定義自体が削除になる可能性はあるが、仕様上は残すため以下の対応（内容をリセット）を取る
      serviceReportMemos: memoAdapter.getInitialState(),
      serviceReportComments: commentAdapter.getInitialState(),
    },
  };
}

export default migrate;
