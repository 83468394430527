import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { information } from "../../../usecases";
import { Information } from "../../../webapi/generated";
import Date from "../../Date";
import ExternalLink from "../../ExternalLink";
import { useProcessingContext } from "../../Processing";
import { ReactComponent as ErrorIcon } from "./btn_60_error.svg";
import styles from "./Informations.module.css";

type Props = {
  isAuthed?: boolean;
  setHeightContentFit?: (flag: boolean) => void;
};

/**
 * このコンポーネントの責務は Information 一覧を表示すること
 */
const Informations: FC<Props> = ({ isAuthed, setHeightContentFit }) => {
  const { t } = useTranslation();
  const informations = useInformations(isAuthed ?? false);

  // 取得完了後、データが 0 件だった場合は、モーダル高さを content-fit にする
  useEffect(() => {
    setHeightContentFit?.(!!informations && !informations.length);
  }, [informations, setHeightContentFit]);

  if (!informations) return null;
  return (
    <div className={styles.host}>
      {informations.length ? (
        informations?.map((info) => (
          <div key={info.id} className={styles.item}>
            <div className={styles.date}>
              <Date value={info.publishStart} />
            </div>
            <div className={styles.title}>{info.title}</div>
            <div>{info.detail}</div>
            {info.url ? (
              <div className={styles.link}>
                <ExternalLink to={info.url}>{info.url}</ExternalLink>
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className={styles.noInformation}>
          <ErrorIcon />
          <span>{t("There is no information to display.")}</span>
        </div>
      )}
    </div>
  );
};
export default Informations;

function useInformations(isAuthed: boolean) {
  const [informations, setInformations] = useState<Information[]>();

  const { wait } = useProcessingContext();
  useEffect(() => {
    const task = (async () => {
      const informations = await information.fetchInformations(isAuthed);
      setInformations(informations);
    })();
    wait(task);
  }, [isAuthed, wait]);

  return informations;
}
