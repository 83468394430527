import { FC, lazy, useEffect } from "react";
import { useBoundSelector } from "../../store";
import { maintenance } from "../../usecases";

/**
 * 通常、PageRouter によってページごとに自動的に遅延ロードされるが、
 * このコンポーネント MaintenanceGate は PageRouter より上位で使用される想定なため
 * その仕組みが働かない。メンテナンス時以外にメンテナンスページのためのオーバーヘッドが
 * 発生することを避けるため、個別に遅延ロードする。
 */
const MaintenanceInfo = lazy(() => import("./MaintenanceInfo"));

type Props = {};

/**
 * このコンポーネントの責務はメンテナンスモードになったときに検知し、その情報を表示すること
 */
const MaintenanceGate: FC<Props> = ({ children }) => {
  const [isMaintenance, message] = useIsMaintenance();

  return isMaintenance ? (
    <MaintenanceInfo message={message} />
  ) : (
    <>{children}</>
  );
};
export default MaintenanceGate;

function useIsMaintenance() {
  useEffect(() => {
    maintenance.checkCurrentMaintenanceState();
  }, []);

  const isMaintenance = useBoundSelector(
    (s) => s.site.maintenance?.state === "maintenance"
  );

  const message = useBoundSelector((s) => s.site.maintenance?.message);

  return [isMaintenance, message] as const;
}
