import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { WorkOrder } from "../../models";

type State = {
  workOrders: EntityState<WorkOrder>;
};

const workOrdersAdapter = createEntityAdapter<WorkOrder>({
  selectId: (workOrder) => workOrder.workOrderNo,
});
export const workOrdersSelectors = workOrdersAdapter.getSelectors();

export const { actions, reducer } = createSlice({
  name: "work-order",
  initialState: {
    workOrders: workOrdersAdapter.getInitialState(),
  } as State,
  reducers: {
    workOrdersReceived(state, action: { payload: WorkOrder[] }) {
      workOrdersAdapter.setAll(state.workOrders, action.payload);
    },
  },
});
