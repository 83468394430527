import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import type { NotificationModel as NM } from "../../../models";
import { ship } from "../../../usecases";
import CategoryBadge from "../../MemoEditor/CategoryBadge";
import Button from "../../Button";
import AttachmentFiles from "../../AttachmentFiles";
import AttachmentButton from "../../AttachmentButton";
import DraftAttentionBadge from "../../DraftAttentionBadge";
import styles from "./NotificationCard.module.css";
import MentionButton from "../../MentionButton";
import DateTimeDisplay from "../../DateTimeDisplay";

type NotificationModel = Omit<NM, "key" | "isNew"> & {
  isNew?: boolean;
  isUnread?: boolean;
};

type Props = {
  notification: NotificationModel;
  showMoreButton?: boolean;
  className?: string;
  onMemoTransition?: (notification: NotificationModel) => void;
};

/**
 * このコンポーネントの責務は 更新通知の内容を表示すること
 */
const NotificationCard: FC<Props> = ({
  notification,
  showMoreButton = true,
  className,
  onMemoTransition,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={classNames(styles.host, className)}>
      {notification.isNew && (
        <DraftAttentionBadge isNumIcon className={styles.attention} />
      )}

      <div className={styles.container}>
        <ul className={styles.breadcrumbs}>
          {notification.breadcrumbs.map((breadcrumb, index, selfArray) => (
            <li key={index}>
              {breadcrumb}
              {selfArray[index + 1] && (
                <span className={styles.separator}>{">"}</span>
              )}
            </li>
          ))}
        </ul>

        {notification.categories && (
          <div className={styles.categories}>
            {notification.categories.map((category) => (
              <CategoryBadge key={category.id} defaultChecked disabled>
                {category.name}
              </CategoryBadge>
            ))}
          </div>
        )}

        {!notification.isComment && (
          <h2 className={styles.title}>{notification.title}</h2>
        )}
        <p
          className={styles.detail}
          aria-hidden={!showMore}
          dangerouslySetInnerHTML={{
            __html: ship.replaceSanitizedHtml(notification.detail!, true),
          }}
        />

        {showMoreButton && (
          <>
            <Button
              className={styles.button}
              onClick={() => setShowMore(true)}
              aria-label="show more notification"
            >
              {t("more..")}
            </Button>

            {showMore && <AttachmentFiles attachments={notification.files} />}
          </>
        )}

        <div className={styles.meta}>
          <div className={styles.leftItem}>
            <DateTimeDisplay
              createdAt={notification.createdAt}
              updatedAt={notification.updatedAt}
            />
            <div className={styles.separator} />
            <p className={styles.name}>{notification.author?.name}</p>
          </div>
          <div className={styles.rightItem}>
            <span className={styles.icons}>
              {!!notification.files.length && !showMore && (
                <AttachmentButton readonly />
              )}
              {!!notification.mentionedUsers?.length && (
                <MentionButton
                  mentionedUsers={notification.mentionedUsers.map(
                    (user) => user.name
                  )}
                  isUnread={notification.isUnread}
                  tooltipTailPosition="right"
                />
              )}
            </span>
            <Button
              className={classNames(styles.button, styles.detailButton)}
              onClick={() => onMemoTransition?.(notification)}
              aria-label="detail memo transition"
            >
              {t("Detail")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
