import { createSlice } from "@reduxjs/toolkit";
import type { MemoRedirectFrom } from "../../models";
import type { ErrorValue } from "../../models/error";
import { MaintenanceInfo } from "../../models/maintenance";

/**
 * 検索画面情報
 */
type SearchState = {
  ships: { query: string | null };
  // 船に紐づくサービスレポート検索の再検索フラグ
  useServiceReportsByShipRefresh: boolean;
};

/**
 * メモ系画面情報(Context API で代替不可な定義)
 */
type MemoState = {
  redirect?: MemoRedirectState;
  useNavigateBlock: boolean;
};

type State = {
  maintenance?: MaintenanceInfo;
  errors?: ErrorValue[];
  search: SearchState;
  memo: MemoState;
};

export const { actions, reducer } = createSlice({
  name: "site",
  initialState: {
    search: {
      ships: { query: null },
    },
    memo: {
      useNavigateBlock: false,
    },
  } as State,
  reducers: {
    maintenanceDetected(state, action: { payload: MaintenanceInfo }) {
      state.maintenance = action.payload;
    },
    errorDetected(state, action: { payload: ErrorValue }) {
      if (!state.errors) state.errors = [];
      state.errors.push(action.payload);
    },
    searchShipsQueryReceived(state, action: { payload: string | null }) {
      state.search.ships.query = action.payload;
    },
    memoRedirectPathReceived(
      state,
      action: { payload: MemoRedirectState | undefined }
    ) {
      state.memo.redirect = action.payload;
    },
    memoNavigateBlockerToggle(state, action: { payload: boolean }) {
      state.memo.useNavigateBlock = action.payload;
    },
    refreshServiceReportsByShip(state, action: { payload: boolean }) {
      state.search.useServiceReportsByShipRefresh = action.payload;
    },
  },
});

type MemoRedirectState = {
  redirectPath: string;
  redirectFrom: MemoRedirectFrom;
};
