import type {
  AttachmentFile,
  CacheKey,
  Comment,
  CommentDraft,
  CommentDraftTemporary,
  CommentDraftUpdate,
  DraftWithTemporaries,
  Memo,
  MemoDraft,
  MemoDraftTemporary,
  MemoDraftUpdate,
  User,
} from "../../models";
import {
  isDraft,
  isCreatedDraft,
  deserializeDrawingInstances,
} from "../../models";
import store, { actions } from "../../store";
import { MemoCategory } from "../../webapi/generated";

interface TargetProperty {
  memos: Memo[];
  count?: number;
  categories?: MemoCategory[];
}
interface DraftProperty {
  memos: MemoDraft[];
  comments: CommentDraft[];
  author: User;
}
interface DeletedTargetDraft<T> {
  index: number;
  property: T;
}
interface DispatchCallback {
  updateMemoDraft: (arg: MemoDraftUpdate) => void;
  updateCommentDraft: (arg: CommentDraftUpdate) => void;
}
type DraftType = MemoDraft | CommentDraft;
type DraftTemporaryType = DraftWithTemporaries | CommentDraftTemporary;
type TargetType = Memo | Comment;

/**
 * メモマージ処理
 *
 * @param page
 * @param target
 * @param draft
 * @param dispatch
 * @param connection
 * @param cacheKey
 * @returns
 */
export function mergeMemos(
  page: number,
  target: TargetProperty,
  draft: DraftProperty,
  dispatch: DispatchCallback,
  connection: boolean,
  cacheKey?: CacheKey
) {
  // 編集下書き: フォーム上の値上書き用メモ一覧
  const temporaryMemos: MemoDraftTemporary[] = [];

  // 既存メモが削除済みの編集下書き一覧
  const deletedTargets: DeletedTargetDraft<MemoDraft>[] = [];

  // 変換済みメモ一覧
  const memos = target.memos.map(parseMemo);
  const totalMemoCount = target.count || 0;

  // 削除済みメモの編集下書きを新規作成に変換して指定位置に代入
  deletedTargets.forEach((d) => {
    const identify = totalMemoCount + Math.random();
    memos.splice(
      d.index,
      0,
      convertDraftToTarget(
        d.property,
        draft.author,
        identify,
        target.categories
      )
    );
  });

  if (page === 1) {
    // 新規作成の下書きメモ一覧
    // ※ id の降順で並び替え
    const created = draft.memos
      .filter(isCreatedDraft)
      .map((m, index) => {
        const identify = totalMemoCount + 1 + index;
        return convertDraftToTarget(
          m,
          draft.author,
          identify,
          target.categories
        );
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));

    memos.unshift(...created);
  }

  return {
    models: memos,
    draftTemporaryMemos: temporaryMemos,
    count: target.count || 0,
  };

  /**
   * メモ変換処理
   *
   * @param memo
   * @param index
   * @returns
   */
  function parseMemo(memo: Memo, index: number): Memo {
    const { comments, draftTemporaryComments } = mergeComments(
      memo,
      draft,
      dispatch.updateCommentDraft,
      connection,
      cacheKey
    );
    const base = { ...memo, comments };

    const dm = findDraftById(draft.memos, base.id);

    if (base.isDeleted) {
      if (dm) {
        const payload = { ...dm, type: "create" as const };
        deletedTargets.push({ index, property: payload });
        dispatch.updateMemoDraft({ key: dm.key, memo: payload });
      }
      return parse({ ...base, isDeleted: true }, [], {
        temporaries: draftTemporaryComments,
      });
    }

    if (dm) {
      const categories = (target.categories || [])?.filter((category) =>
        dm.categories?.includes(category.id)
      );
      temporaryMemos.push({ ...dm, categories });
    }

    const files = convertFilesWithAvailableProperty(
      base.files!,
      connection,
      cacheKey
    );
    return parse(base, files, {
      key: dm?.key,
      type: dm?.type,
      temporaries: draftTemporaryComments,
    });
  }
}

/**
 * コメントマージ処理
 *
 * @param memo
 * @param draft
 * @param dispatch
 * @param connection
 * @param cacheKey
 * @returns
 */
function mergeComments(
  memo: Memo,
  draft: DraftProperty,
  dispatch: DispatchCallback["updateCommentDraft"],
  connection: boolean,
  cacheKey?: CacheKey
) {
  // 編集下書き: フォーム上の値上書き用コメント一覧
  const temporaryComments: CommentDraftTemporary[] = [];

  // 既存コメントが削除済みの編集下書き一覧
  const deletedTargets: DeletedTargetDraft<CommentDraft>[] = [];

  // 変換済みコメント一覧
  const comments = memo.comments.map(parseComment);
  const totalCommentCount = comments.length;

  // 削除済みコメントの編集下書きを新規作成に変換して指定位置に代入
  deletedTargets.forEach((d) => {
    const identify = totalCommentCount + Math.random();
    comments.splice(
      d.index,
      0,
      convertDraftToTarget(d.property, draft.author, identify)
    );
  });

  // 新規作成の下書きコメント一覧
  // ※ id の降順で並び替え
  const created = draft.comments
    .filter((c) => c.memoId === memo.id && isCreatedDraft(c))
    .map((c, index) => {
      const identify = totalCommentCount + 1 + index;
      return convertDraftToTarget(c, draft.author, identify);
    })
    .sort((a, b) => (a.id < b.id ? 1 : -1));

  comments.unshift(...created);

  return { comments, draftTemporaryComments: temporaryComments };

  /**
   * コメント変換処理
   *
   * @param comment
   * @param index
   * @returns
   */
  function parseComment(comment: Comment, index: number): Comment {
    const dc = findDraftById(draft.comments, comment.id);

    if (comment.isDeleted) {
      if (dc) {
        const payload = { ...dc, type: "create" as const };
        deletedTargets.push({ index, property: payload });
        dispatch({ key: dc.key, comment: payload });
      }
      return parse({ ...comment, isDeleted: true }, []);
    }

    const property = {
      key: dc?.key,
      id: dc?.id,
      message: dc?.message,
      type: dc?.type,
      mentionedUsers: dc?.mentionedUsers,
    };

    if (dc) temporaryComments.push(property);

    const files = convertFilesWithAvailableProperty(
      comment.files!,
      connection,
      cacheKey
    );
    return parse(comment, files, property);
  }
}

/**
 * 下書き一覧から取得
 *
 * @param drafts
 * @param id
 * @returns
 */
function findDraftById<T extends DraftType>(drafts: T[], id: number) {
  return drafts.find((d) => d.id === id);
}

/**
 * 下書きから通常変換処理
 *
 * @param target
 * @param files
 * @param draft
 * @returns
 */
function parse<T extends TargetType, U extends DraftTemporaryType>(
  target: T,
  files: AttachmentFile[] = [],
  draft?: U
): T {
  return { ...target, files, draft };
}

/**
 * ファイルの利用可否を含めて一覧で返す
 *
 * @param files ファイル一覧
 * @param connection 通信状況
 * @param cacheKey キャッシュ済のURL一覧を持つオブジェクト
 * @returns
 */
function convertFilesWithAvailableProperty(
  files: Omit<AttachmentFile, "available">[],
  connection: boolean,
  cacheKey?: CacheKey
): AttachmentFile[] {
  return files.map((file) => {
    const url = new URL(file.url);
    const available =
      !connection && cacheKey
        ? cacheKey.urls.includes(`${url.origin}${url.pathname}`)
        : true;
    return { ...file, available };
  });
}

/**
 * 新規作成下書きを通常変換
 *
 * @param draft
 * @param author
 * @param identify
 * @returns
 */
function convertDraftToTarget<T extends DraftType>(
  draft: T,
  author: User,
  identify: number,
  categories: MemoCategory[] = []
): T extends MemoDraft ? Memo : Comment {
  const payload = { id: identify, author, files: [] } as any;
  const property = { key: draft.key, type: draft.type };

  if (isDraft<MemoDraft>(draft)) {
    const instances = draft.path
      ? deserializeDrawingInstances(draft.path)
      : undefined;
    return {
      ...draft,
      ...payload,
      instances,
      comments: [],
      isDeleted: false,
      draft: { ...property, temporaries: [] },
      categories: categories.filter((category) =>
        draft.categories?.includes(category.id)
      ),
    };
  } else {
    return { ...draft, ...payload, isDeleted: false, draft: property };
  }
}

/**
 * 全ての下書きを削除
 */
export function removeAllDraft() {
  const actionFns = [
    actions.draft.shipMemoAllRemoved,
    actions.draft.shipCommentAllRemoved,
    actions.draft.diagramMemoAllRemoved,
    actions.draft.diagramCommentAllRemoved,
    actions.draft.equipmentMemoAllRemoved,
    actions.draft.equipmentCommentAllRemoved,
  ];
  actionFns.map((action) => store.dispatch(action()));
}
