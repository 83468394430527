import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBoundActions } from "../../../../store";
import Select from "../../../Select";
import SubmitButton from "../../../SubmitButton";
import styles from "./LanguageSettingForm.module.css";

/**
 * セレクトボックスで使うラベル文字列
 * キーは言語 ID, 値は翻訳データが存在する単語でなければならない
 */
const languageLabels = {
  en: "English",
  ja: "Japanese",
};

type FormData = {
  language: keyof typeof languageLabels;
};

type Props = {
  onComplete?: () => void;
};

/**
 * このコンポーネントの責務は LanguageSetting 画面の入力フォームを提供すること
 */
const LanguageSettingForm: FC<Props> = ({ onComplete }) => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, register } = useForm<FormData>();

  const onSubmit = useOnSubmit(onComplete, i18n);

  const selectOptions = Object.entries(languageLabels).map(([k, v]) => ({
    value: k,
    text: t(v, { lng: k }),
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select
        name="language"
        label={t("Language")}
        options={selectOptions}
        defaultValue={i18n.language}
        ref={register}
      />
      <SubmitButton className={styles.submitButton}>{t("Save")}</SubmitButton>
    </form>
  );
};
export default LanguageSettingForm;

function useOnSubmit(
  onComplete: Props["onComplete"],
  i18n: { changeLanguage: (lng: string) => void }
) {
  const { setting } = useBoundActions();

  return useCallback(
    (data: FormData) => {
      i18n.changeLanguage(data.language);
      setting.languageChanged(data.language);
      onComplete?.();
    },
    [i18n, onComplete, setting]
  );
}
