import { FC, useRef, useState } from "react";
import styles from "./MentionButton.module.css";
import { ReactComponent as Icon } from "./ic_60_mention.svg";
import Button from "../Button";
import classNames from "classnames";

type Props = {
  mentionedUsers?: string[];
  isUnread?: boolean;
  tooltipTailPosition?: "center" | "right" | "left";
};

/**
 * このコンポーネントの責務は メンション先表示ボタンの表示
 */
const MentionButton: FC<Props> = ({
  mentionedUsers,
  isUnread,
  tooltipTailPosition = "center",
}) => {
  const [isOpen, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const openList = () => {
    if (mentionedUsers) setOpen(true);
  };

  return (
    <div
      className={styles.host}
      ref={divRef}
      onFocus={() => openList()}
      onBlur={() => setOpen(false)}
      tabIndex={0}
    >
      <Button
        className={classNames(
          styles.mentionButton,
          !mentionedUsers && styles.cursorDefault
        )}
        disabled={!isOpen}
      >
        <Icon className={styles.mentionIcon} />
        {isUnread && <span className={styles.unreadBadge}></span>}
      </Button>
      {isOpen && !!mentionedUsers?.length && (
        <div
          className={classNames(
            styles.mentionPullUp,
            styles[tooltipTailPosition]
          )}
        >
          <div className={styles.userList}>
            {mentionedUsers?.map((user, index) => {
              return (
                <div key={index} className={styles.user}>
                  {user}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default MentionButton;
