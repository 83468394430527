import type {
  CommentDraft,
  CommentDraftAdd,
  CommentDraftUpdate,
  CreateCommentDraftArgType,
  CreateMemoDraftArgType,
  MemoDraft,
  MemoDraftAdd,
  MemoDraftUpdate,
  UpdateCommentDraftArgType,
  UpdateMemoDraftArgType,
} from "../../models";
import store, { actions, selectors } from "../../store";
import { jsonToMemo, memoToJson } from "../../store/serializers";
import { mergeMemos } from "./draft";

/**
 * 下書きメモ登録
 *
 * ※ 下書き種別: memo.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memo
 */
export function postNewDiagramDraftMemo(
  shipId: MemoDraft["fShipNo"],
  diagramId: NonNullable<MemoDraft["diagramId"]>,
  memo: CreateMemoDraftArgType
) {
  const payload: MemoDraftAdd = {
    fShipNo: shipId,
    ...memo,
    type: memo.id ? "edit" : "create",
  };
  store.dispatch(actions.draft.diagramMemoAdded({ ...payload, diagramId }));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書きメモ更新
 *
 * @param shipId
 * @param diagramId
 * @param key
 * @param memo
 */
export function updateDiagramDraftMemo(
  shipId: MemoDraft["fShipNo"],
  diagramId: NonNullable<MemoDraft["diagramId"]>,
  key: MemoDraft["key"],
  memo: UpdateMemoDraftArgType
) {
  store.dispatch(actions.draft.diagramMemoUpdated({ key, memo }));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書きメモ削除
 *
 * @param shipId
 * @param diagramId
 * @param key
 */
export function deleteDiagramDraftMemo(
  shipId: MemoDraft["fShipNo"],
  diagramId: NonNullable<MemoDraft["diagramId"]>,
  key: MemoDraft["key"]
) {
  store.dispatch(actions.draft.diagramMemoRemoved(key));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書きコメント登録
 *
 * ※ 下書き種別: comment.id がある場合 edit, ない場合 create
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param comment
 */
export function postNewDiagramDraftComment(
  shipId: CommentDraft["fShipNo"],
  diagramId: NonNullable<CommentDraft["diagramId"]>,
  memoId: CommentDraft["memoId"],
  comment: CreateCommentDraftArgType
) {
  const payload: CommentDraftAdd = {
    fShipNo: shipId,
    ...comment,
    type: comment.id ? "edit" : "create",
    memoId,
  };
  store.dispatch(actions.draft.diagramCommentAdded({ ...payload, diagramId }));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書きコメント更新
 *
 * @param shipId
 * @param diagramId
 * @param key
 * @param comment
 */
export function updateDiagramDraftComment(
  shipId: CommentDraft["fShipNo"],
  diagramId: NonNullable<CommentDraft["diagramId"]>,
  key: CommentDraft["key"],
  comment: UpdateCommentDraftArgType
) {
  store.dispatch(actions.draft.diagramCommentUpdated({ key, comment }));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書きコメント削除
 *
 * @param shipId
 * @param diagramId
 * @param key
 */
export function deleteDiagramDraftComment(
  shipId: CommentDraft["fShipNo"],
  diagramId: NonNullable<CommentDraft["diagramId"]>,
  key: CommentDraft["key"]
) {
  store.dispatch(actions.draft.diagramCommentRemoved(key));
  updateHasDiagramMemoDrafts(shipId, diagramId);
}

/**
 * 下書き所有判定の更新
 *
 * @param shipId
 * @param diagramId
 */
export function updateHasDiagramMemoDrafts(
  shipId: MemoDraft["fShipNo"],
  diagramId: NonNullable<MemoDraft["diagramId"]>
) {
  const diagramCountMap = selectors.draft.customSelectors.diagramCountMap(
    store.getState().draft,
    shipId
  );
  const count = diagramCountMap.get(diagramId) || 0;
  store.dispatch(
    actions.bookmark.shipAppendixUpdated({
      id: shipId,
      shipAppendix: { hasDiagramMemoDrafts: count > 0 },
    })
  );
}

/**
 * 下書きと通常メモ/コメントを取り出しマージ後、更新
 *
 * @param shipId
 * @param diagramId
 * @param page
 * @returns
 */
export function pullDraft(shipId: string, diagramId: string, page: number) {
  const { bookmark, draft, setting, account, ship } = store.getState();
  const {
    memos,
    comments,
  } = selectors.draft.customSelectors.diagramDraftByFShipNo(draft, {
    fShipNo: shipId,
    diagramId,
  });
  const diagramMemos = selectors.diagramMemo
    .selectAll(ship.diagramMemos)
    .map(jsonToMemo);
  const cacheKey = selectors.bookmark.cacheKeysSelectors.selectById(
    bookmark.cacheKeys,
    shipId
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );

  if (!account.user) return [];

  const draftObject = {
    author: account.user,
    memos,
    comments,
  };
  const diagramObject = {
    memos: diagramMemos,
    count: ship.diagramMemoCount,
  };
  const dispatch = {
    updateMemoDraft: (arg: MemoDraftUpdate) =>
      store.dispatch(actions.draft.diagramMemoUpdated(arg)),
    updateCommentDraft: (arg: CommentDraftUpdate) =>
      store.dispatch(actions.draft.diagramCommentUpdated(arg)),
  };

  const { models, draftTemporaryMemos, count } = mergeMemos(
    page,
    diagramObject,
    draftObject,
    dispatch,
    fulfilledConnect,
    cacheKey
  );

  store.dispatch(
    actions.ship.diagramMemosReceived({ memos: models.map(memoToJson), count })
  );

  return draftTemporaryMemos;
}
