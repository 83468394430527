import { createSlice } from "@reduxjs/toolkit";
import { ReadMention } from "../../models/read-mention";

type StoreReadMention = Omit<ReadMention, "updatedAt"> & {
  updatedAt: string;
};

export const { actions, reducer } = createSlice({
  name: "read-mention",
  initialState: {
    readMentions: [] as StoreReadMention[],
  },
  reducers: {
    mentionAdded(state, action: { payload: StoreReadMention }) {
      // equipmentIdはstring型とnumber型が混在するので型判定を行いnumber型に統一する
      const mention = action.payload;
      if (mention.equipmentId && typeof mention.equipmentId !== "number") {
        mention.equipmentId = Number(mention.equipmentId);
      }
      state.readMentions.push(mention);
    },
    mentionDeleted(state, action: { payload: number }) {
      state.readMentions = state.readMentions.filter(
        (s) => s.userId !== action.payload
      );
    },
  },
});
