import classNames from "classnames";
import { ComponentProps, FC } from "react";
import ReactModal from "react-modal";
import Button from "../Button";
import { ReactComponent as CloseIcon } from "./ic_60_close_wh.svg";
import styles from "./Modal.module.css";

type Props = ComponentProps<typeof ReactModal> & {
  title?: string;
};

/**
 * このコンポーネントの責務はアプリケーションに共通のモーダル機能を提供すること
 */
const Modal: FC<Props> = ({
  title,
  className,
  overlayClassName,
  children,
  ...rest
}) => (
  <ReactModal
    className={classNames(styles.host, className)}
    overlayClassName={classNames(styles.overlay, overlayClassName)}
    {...rest}
  >
    {title && (
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <Button
          className={styles.closeButton}
          onClick={rest.onRequestClose}
          aria-label="close modal"
        >
          <CloseIcon />
        </Button>
      </div>
    )}
    <div className={styles.children}>{children}</div>
  </ReactModal>
);
export default Modal;
