/* eslint-disable local/no-react-class */

import { Component, ErrorInfo } from "react";
import type { ErrorValue } from "../../models/error";
import { convertToString, newErrorValue } from "../../models/error";
import SystemError from "./SystemError";

type Props = {};
type State = {
  error?: ErrorValue;
};

/**
 * このコンポーネントの責務は全てのエラーを補足し、エラー画面を表示すること
 *
 * このプロジェクトでは React クラスコンポーネントでなく関数コンポーネントのみを使用するが、
 * componentDidCatch は React Hooks で再現できないため、ここのみ使用する
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    (async () => {
      const value = await newErrorValue({
        title: "Rendering Error",
        detail:
          (await convertToString(error)) + "\n" + errorInfo.componentStack,
      });
      this.setState({ error: value });

      gtag("event", "exception", {
        description: JSON.stringify(value),
        fatal: true,
      });
    })();
  }

  render() {
    const error = this.state.error;
    return error ? <SystemError errors={[error]} /> : this.props.children;
  }
}
export default ErrorBoundary;
