import { AttachedType } from "../webapi/generated";

/**
 * 添付ファイルの添付先種別
 *
 * 1: メモ
 * 2: 作業レポート
 */
export const fileAttachedType = {
  MEMO: AttachedType.NUMBER_1,
  CONSTRUCTION: AttachedType.NUMBER_2,
};

export const TEMP_IMAGE_FILENAME = "sample.png";
