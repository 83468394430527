import { FC, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import store, { useBoundSelector } from "../../../store";
import { actions } from "../../../store/modules/setting";
import OfflineModal from "../../OfflineModal";

type Props = {
  mounted?: boolean;
  onMounted?: (payload: boolean) => void;
};

/**
 * このコンポーネントの責務は接続状態がオフラインになったときに表示するモーダルを提供すること
 */
const ShowOfflineModal: FC<Props> = ({ onMounted }) => {
  const { showOfflineModal, lastLoginAt } = useBoundSelector((s) => ({
    showOfflineModal: s.setting.showOfflineModal,
    lastLoginAt: s.account.user
      ? new Date(s.account.user.lastLoginAt)
      : undefined,
  }));

  const navigate = useNavigate();

  const onRequestAccept = useCallback(() => {
    store.dispatch(actions.showModal(false));
    store.dispatch(actions.setUserMode(false));
    navigate({ pathname: "/bookmarks" });
    onMounted?.(true);
  }, [navigate, onMounted]);

  const onRequestCancel = useCallback(() => {
    store.dispatch(actions.showModal(false));
  }, []);

  // 接続状態を監視し、オフライン切り替わり時にオフラインモーダルを表示フラグを活性化する
  useEffect(() => {
    if (!showOfflineModal) onMounted?.(false);
  }, [showOfflineModal, onMounted]);

  return (
    <OfflineModal
      isOpen={showOfflineModal}
      onRequestOK={onRequestAccept}
      onRequestClose={onRequestCancel}
      lastLoginAt={lastLoginAt}
    />
  );
};
export default ShowOfflineModal;
