import type { FC, ReactElement } from "react";
import styles from "./LayoutGamma.module.css";

type Props = {
  header?: ReactElement;
  sidebar?: ReactElement;
};

/**
 * このコンポーネントの責務はページレイアウトとしてヘッダーおよびサイドバーを与えること
 *
 * (`Gamma` という名前はヘッダーとサイドバーの位置がギリシア文字 Γ に似てることから)
 */
const LayoutGamma: FC<Props> = ({ header, sidebar, children }) => {
  return (
    <div className={styles.host}>
      <div className={styles.header}>{header}</div>
      <div className={styles.sidebar}>{sidebar}</div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};
export default LayoutGamma;
