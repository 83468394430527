import {
  detectAttachmentFileTypeByName,
  Memo,
  memoType,
  MemoType,
  memoTypeName,
  SearchMemo,
} from "../../models";
import { ReadMention } from "../../models/read-mention";
import store, { actions, selectors } from "../../store";
import { MentionUser } from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";
import { readMention } from "..";
import { jsonToMemo, memoToJson } from "../../store/serializers";

/**
 * 添付ファイルをアップロードする
 */
export async function uploadAttachmentFile(
  file: File,
  preSignedUrlFetcher: () => Promise<{ objectKey: string; uploadUrl: string }>
): Promise<{ objectKey: string; fileName: string }> {
  // アップロード用 Pre-Signed URL の発行
  const { objectKey, uploadUrl } = await preSignedUrlFetcher();

  // Content-Disposition ヘッダーの決定
  const type = detectAttachmentFileTypeByName(file.name);
  let contentDisposition: string;
  switch (type) {
    case "image":
    case "video": {
      contentDisposition = "inline";
      break;
    }
    case "other": {
      contentDisposition = `attachment; filename*=UTF-8''${encodeURIComponent(
        file.name
      )}`;
      break;
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = type;
      throw new Error(`unknown attachment file type ${type} detected`);
    }
  }

  // ファイルアップロード HTTP リクエスト
  const response = await fetch(uploadUrl, {
    method: "PUT",
    headers: { "Content-Disposition": contentDisposition },
    body: file,
  });
  if (!response.ok) throw new Error("failed to upload file: " + file.name);

  return { objectKey, fileName: file.name };
}

/**
 * メモ検索画面の検索結果の各メモパンくずリストを取得
 *
 * @param memo
 * @return パンくずリスト
 */
export function getSearchMemoBreadcrumbs(memo: SearchMemo) {
  const type = memo.type as MemoType;
  const breadcrumbs = [
    memo.shipName,
    memoTypeName[type],
    ...createPartialBreadcrumbsByMemoType(type, memo),
  ];
  return breadcrumbs;
}

/**
 * メモ種別毎に異なるパンくずの一部を作成
 *
 * @param type メモ種別
 * @param args パンくずの作成に必要な引数
 */
export function createPartialBreadcrumbsByMemoType(
  type: MemoType,
  args: MemoTypeNameToBreadcrumbsArgs
) {
  const partialBreadcrumbs = [];

  switch (type) {
    case memoType.DIAGRAM:
      if (args.diagramName) {
        partialBreadcrumbs.push(args.diagramName);
      }
      break;
    case memoType.EQUIPMENT:
      if (args.equipmentName) {
        partialBreadcrumbs.push(args.equipmentName);
      }
      break;
    default:
      break;
  }

  if (args.isComment && args.memoTitle) {
    partialBreadcrumbs.push(args.memoTitle);
  }

  return partialBreadcrumbs;
}
type MemoTypeNameToBreadcrumbsArgs = Pick<
  SearchMemo,
  "diagramName" | "equipmentName" | "isComment" | "memoTitle"
>;

/**
 * 更新通知画面から特定のメモを表示するためのパスを取得
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param serviceReportId サービスレポートID
 * @returns
 */
export async function getMemoTransitionParams(
  shipId: string,
  memoId: number,
  diagramId?: string,
  equipmentId?: number,
  serviceReportId?: number
) {
  const page = await fetchMemoPage(
    shipId,
    memoId!,
    diagramId,
    equipmentId,
    serviceReportId
  );
  const params = new URLSearchParams("");

  const queries = [
    { name: "memoId", value: memoId },
    { name: "page", value: page },
  ].map((query) => ({ ...query, value: query.value.toString() }));

  queries.forEach((query) => params.set(query.name, query.value));

  return params;
}

/**
 * メモが存在するページ番号を取得
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param serviceReportId サービスレポートID
 * @returns
 */
async function fetchMemoPage(
  shipId: string,
  memoId: number,
  diagramId?: string,
  equipmentId?: number,
  serviceReportId?: number
) {
  const webapi = await getAuthedWebapi();

  const { page } = await webapi.getShipsFShipNoMemosMemoIdPage({
    fShipNo: shipId,
    memoId,
    diagramId,
    equipmentId,
    serviceReportId,
  });

  return page;
}

/**
 * メモやコメントが未読か判定する（既読リスト追加処理込み）
 *
 * @param id 固有のID
 * @param mentionedUsers 通知先ユーザー
 * @param comparedAt 比較日時
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @returns
 */
function isUnreadMemoMentionWithSave(
  id: number,
  mentionedUsers: MentionUser[],
  comparedAt: Date,
  diagramId?: string,
  equipmentId?: string,
  serviceReportId?: number,
  memoId?: number
) {
  const { account } = store.getState();
  const userId = account.user?.id;

  const unread = isUnreadMemoMention(
    id,
    mentionedUsers,
    comparedAt,
    diagramId,
    equipmentId,
    serviceReportId,
    memoId
  );

  if (unread) {
    store.dispatch(
      actions.readMention.mentionAdded({
        id: id,
        memoId: memoId,
        diagramId: diagramId ? encodeURIComponent(diagramId) : diagramId,
        equipmentId: equipmentId,
        serviceReportId: serviceReportId,
        updatedAt: comparedAt.toISOString(),
        userId: userId!,
      })
    );
  }

  return unread;
}

/**
 * メモやコメントのメンションが未読か判定する
 *
 * @param id 固有のID
 * @param mentionedUsers 通知先ユーザー
 * @param comparedAt 比較日時
 * @param diagramId 結線図ID
 * @param equipmentId 機器ID
 * @param serviceReportId サービスレポートID
 * @param memoId メモID
 * @returns
 */
export function isUnreadMemoMention(
  id: number,
  mentionedUsers: MentionUser[],
  comparedAt: Date,
  diagramId?: string,
  equipmentId?: string,
  serviceReportId?: number,
  memoId?: number
) {
  if (!mentionedUsers || mentionedUsers.length === 0) return false;

  const { account } = store.getState();
  const lastLoginAt = account.user
    ? new Date(account.user.lastLoginAt)
    : undefined;
  const userId = account.user?.id;

  const isNew = !!(lastLoginAt && lastLoginAt.getTime() < comparedAt.getTime());
  if (!isNew) return false;

  const isTarget = mentionedUsers.some((user) => user.id === userId);
  if (!isTarget) return false;

  const compareMention: ReadMention = {
    id: id,
    memoId: memoId,
    diagramId: diagramId ? encodeURIComponent(diagramId) : diagramId,
    equipmentId: equipmentId,
    serviceReportId: serviceReportId,
    updatedAt: comparedAt,
    userId: userId!,
  };

  return readMention.isUnread(compareMention);
}

/**
 * メモに既読判定を設ける
 * ※ 既読メンション情報の追加処理も行う
 *
 * @param type メモ種別
 * @param diagramId 図面ID
 * @param equipmentId 機器ID
 * @returns
 */
export function saveAsReadMentionedMemos(
  type: MemoType,
  diagramId?: string,
  equipmentId?: string,
  serviceReportId?: number
) {
  const { ship } = store.getState();

  let memos: Memo[] = [];
  let memoCount;
  let actionFn;

  switch (type) {
    case memoType.SHIP:
      memos = selectors.shipMemo.selectAll(ship.shipMemos).map(jsonToMemo);
      memoCount = ship.shipMemoCount;
      actionFn = actions.ship.shipMemosReceived;
      break;
    case memoType.DIAGRAM:
      memos = selectors.diagramMemo
        .selectAll(ship.diagramMemos)
        .map(jsonToMemo);
      memoCount = ship.diagramMemoCount;
      actionFn = actions.ship.diagramMemosReceived;
      break;
    case memoType.EQUIPMENT:
      memos = selectors.equipmentMemo
        .selectAll(ship.equipmentMemos)
        .map(jsonToMemo);
      memoCount = ship.equipmentMemoCount;
      actionFn = actions.ship.equipmentMemosReceived;
      break;
    case memoType.SERVICE_REPORT:
      memos = selectors.serviceReportMemo
        .selectAll(ship.serviceReportMemos)
        .map(jsonToMemo);
      memoCount = ship.serviceReportMemoCount;
      actionFn = actions.ship.serviceReportMemosReceived;
      break;
    default:
      throw new Error("mismatched memo type");
  }

  const attachedMemos = memos.map((memo) => {
    const comments = memo.comments.map((comment) => {
      const isUnreadMention = isUnreadMemoMentionWithSave(
        comment.id,
        comment.mentionedUsers!,
        comment.updatedAt,
        diagramId,
        equipmentId,
        serviceReportId,
        memo.id
      );
      return { ...comment, isUnreadMention };
    });

    const isUnreadMention = isUnreadMemoMentionWithSave(
      memo.id,
      memo.mentionedUsers!,
      memo.updatedAt,
      diagramId,
      equipmentId,
      serviceReportId,
      undefined
    );

    return { ...memo, comments, isUnreadMention };
  });

  store.dispatch(
    actionFn({ memos: attachedMemos.map(memoToJson), count: memoCount || 0 })
  );
}
