import { ComponentType, createElement } from "react";

/**
 * 動的インポートを通常のコンポーネントとしてレンダリングすることができるようにする。
 *
 * この関数は React.lazy 相当の機能を提供する。
 * @see https://ja.reactjs.org/docs/code-splitting.html#reactlazy
 * React.lazy を使わずこの関数を必要とする理由は、Suspense と React.lazy を併用したとき
 * 動的読み込みが一瞬であっても Suspense の fallback が表示されてしまうので、その対策機能を
 * 持つため。fallback が表示されると画面が「チラついて」見えてしまい、UX が悪くなる。
 *
 * 対策方法は動的コンポーネントのプリフェッチ (= 事前読み込み) をし、読み込み完了済みであれば
 * fallback を全く表示しないようにすること。
 *
 * この関数は (現在 experimental でしか使用できない) React Concurrent Mode が
 * 使えるようになると不要になる。単にこの関数の代わりに React.lazy を使用し、
 * 画面遷移を引き起こす関数を startTransition でラップすればよい。
 * @see https://ja.reactjs.org/docs/concurrent-mode-patterns.html
 * そのようにすると一瞬の読み込みでは画面が維持されるため、UX が悪化しない。
 * React.lazy そのものにはプリフェッチ機能はないが、このプロジェクトでは ServiceWorker
 * による積極的なキャッシュが裏で実行されているので、ほとんどの場合はキャッシュ済み。
 */
export default function lazyWithPrefetch<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
) {
  const loadState = new LoadState(factory);

  // 他の処理が終わったらプリフェッチを実行する
  Promise.all([loaded(), idle()])
    .then(() => loadState.getResolvedComponent())
    // プリフェッチ処理では例外などが発生しても他に影響を与えないので握り潰す
    .catch(() => {});

  return function Lazy() {
    const C = loadState.getResolvedComponent();
    return createElement(C);
  };
}

class LoadState<T extends ComponentType<any>> {
  private state:
    | { type: "initial" }
    | { type: "pending"; promise: Promise<void> }
    | { type: "resolved"; component: T }
    | { type: "rejected"; error: unknown } = { type: "initial" };

  constructor(private readonly factory: () => Promise<{ default: T }>) {}

  public getResolvedComponent(): T {
    switch (this.state.type) {
      case "initial": {
        const promise = this.factory()
          .then((module) => {
            this.state = { type: "resolved", component: module.default };
          })
          .catch((error) => {
            this.state = { type: "rejected", error };
          });
        this.state = { type: "pending", promise };
        throw promise;
      }
      case "pending": {
        throw this.state.promise;
      }
      case "resolved": {
        return this.state.component;
      }
      case "rejected": {
        throw this.state.error;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _: never = this.state;
        throw new Error("invalid state: " + JSON.stringify(this.state));
      }
    }
  }
}

let loadedPromise: Promise<void>;
/** ページ読み込み完了を待機する Promise を取得する */
function loaded() {
  if (!loadedPromise)
    loadedPromise = new Promise((r) => {
      if (document.readyState === "complete") return r();
      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") return r();
      });
    });
  return loadedPromise;
}

/** ブラウザがアイドル状態になることを待機する Promise を取得する */
function idle() {
  return new Promise((r) =>
    ((window as any).requestIdleCallback || window.requestAnimationFrame)(r)
  );
}
