import { WorkOrderSortType, convertWorkOrderSortQuery } from "../../models";
import { withLanguageHeader } from "../../webapi";
import { GetWorkOrdersRequest } from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";

/**
 * ワークオーダーを一覧で取得する
 *
 * @param params 条件
 */
export async function fetchWorkOrders(params: GetWorkOrdersRequest) {
  const webapi = await getAuthedWebapi();
  const { workOrders } = await webapi.getWorkOrders(params);
  return workOrders;
}

/**
 * 指定船舶に紐づくワークオーダーを一覧で取得する
 *
 * @param shipId 船舶番号
 * @param sort ソート順
 */
export async function fetchWorkOrdersByShip(
  shipId: string,
  sort: WorkOrderSortType
) {
  const webapi = await getAuthedWebapi();
  const { workOrders } = await webapi.getWorkOrders({
    fShipNo: shipId,
    sort: convertWorkOrderSortQuery(sort),
  });
  return workOrders;
}

/**
 * ワークオーダー詳細取得
 *
 * @param workOrderNo ワークオーダー番号
 * @param language 言語設定
 */
export async function fetchWorkOrder(
  workOrderNo: string,
  language: "en" | "ja"
) {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const workOrder = await withLang.getWorkOrdersWorkOrderNo({
    workOrderNo: workOrderNo,
  });

  return workOrder;
}
