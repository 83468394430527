import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { PersistedRootStateV1 } from "./v1";
import { ServiceReportSignatureDraft } from "../../models";
import { SerializedCommentDraft, SerializedMemoDraft } from "../serializers";
import { adapters } from "../modules";

/**
 * v2 (ph5 ~ ph7のステート)
 */
export type PersistedRootStateV2 = Omit<PersistedRootStateV1, "draft"> & {
  draft: PersistedRootStateV1["draft"] & {
    serviceReports: EntityState<SerializedServiceReportDraftV1>;
    serviceReportSignatures: EntityState<ServiceReportSignatureDraft>;
    serviceReportMemos: EntityState<SerializedMemoDraft>;
    serviceReportComments: EntityState<SerializedCommentDraft>;
  };
};

// 必要な定義のみ追加
export type SerializedServiceReportDraftV1 = {
  key: string;
  serviceOrderNo: string;
};

/**
 * v2 移行
 *
 * @param state v1 のステート定義
 * @returns v2 のステート定義
 */
function migrate(state: PersistedRootStateV1): PersistedRootStateV2 {
  const { draft } = adapters;
  // v4 で削除となったため再定義
  const customAdapter = createEntityAdapter<SerializedServiceReportDraftV1>({
    selectId: (state) => state.key,
  });

  return {
    ...state,
    draft: {
      ...state.draft,
      serviceReports: customAdapter.getInitialState(),
      serviceReportSignatures: draft.serviceReportSignaturesAdapter.getInitialState(),
      serviceReportMemos: draft.serviceReportMemosAdapter.getInitialState(),
      serviceReportComments: draft.serviceReportCommentsAdapter.getInitialState(),
    },
  };
}

export default migrate;
