import classNames from "classnames";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  License,
  privacyPolicyLink,
  termsOfUseLink,
} from "../../../models/eula";
import { selectors, useBoundSelector } from "../../../store";
import { account, auth, eula } from "../../../usecases";
import Button from "../../Button";
import TermsUpdateModal from "../../Eula/TermsUpdateModal";
import ExternalLink from "../../ExternalLink";
import Modal from "../../Modal";
import styles from "./AccountMenu.module.css";
import { ReactComponent as ArrowDownIcon } from "./ic_40_arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "./ic_40_arrow_up.svg";
import { ReactComponent as LinkIcon } from "./ic_40_link.svg";
import { ReactComponent as LanguageIcon } from "./ic_60_language.svg";
import { ReactComponent as PolicyIcon } from "./ic_60_policy.svg";
import { ReactComponent as ProfileIcon } from "./ic_60_profile.svg";
import { ReactComponent as TermsIcon } from "./ic_60_terms_of_use.svg";
import { ReactComponent as SignOutIcon } from "./ic_60_signout.svg";
import LanguageSettingForm from "./LanguageSettingForm";

type Props = {};

/**
 * このコンポーネントの責務はアカウントメニューを表示すること
 */
const AccountMenu: FC<Props> = () => {
  const { t, i18n } = useTranslation();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [visible, toggleVisible] = useMenuVisible(buttonRef);
  const [languageSettingVisible, setLanguageSettingVisible] = useState(false);
  const username = useAuthenticatedUserName();
  const license = useLicense();

  const fulfilledConnect = useBoundSelector((s) =>
    selectors.setting.customSelectors.fulfilledConnectSelector(s.setting)
  );

  return (
    <div className={classNames(styles.host, visible && styles.open)}>
      <Button
        className={styles.toggleButton}
        onClick={toggleVisible}
        aria-label="show account menu"
        ref={buttonRef}
      >
        <ProfileIcon />
        <span className={styles.username}>{username}</span>
        {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Button>
      {visible ? (
        <div className={styles.menu}>
          <Button
            className={styles.item}
            onClick={() => setLanguageSettingVisible(true)}
          >
            <LanguageIcon />
            <span>{t("Language Setting")}</span>
          </Button>

          <ExternalLink
            to={termsOfUseLink(i18n.language, license?.endUserLicenseVersion)}
            className={classNames(
              styles.item,
              !fulfilledConnect && styles.disabled
            )}
          >
            <TermsIcon />
            <span>{t("Terms of Use")}</span>
            <LinkIcon />
          </ExternalLink>
          <ExternalLink
            to={privacyPolicyLink(i18n.language)}
            className={classNames(
              styles.item,
              !fulfilledConnect && styles.disabled
            )}
          >
            <PolicyIcon />
            <span>{t("Privacy Policy")}</span>
            <LinkIcon />
          </ExternalLink>
          <Button
            className={classNames(
              styles.item,
              !fulfilledConnect && styles.disabled
            )}
            onClick={auth.logout}
          >
            <SignOutIcon />
            <span>{t("Sign Out")}</span>
          </Button>
        </div>
      ) : null}
      <Modal
        className={styles.languageSettingModal}
        title={t("Language Setting")}
        isOpen={languageSettingVisible}
        onRequestClose={() => setLanguageSettingVisible(false)}
      >
        <div className={styles.languageSettingForm}>
          <LanguageSettingForm
            onComplete={() => setLanguageSettingVisible(false)}
          />
        </div>
      </Modal>
      <TermsUpdateModal />
    </div>
  );
};
export default AccountMenu;

function useMenuVisible(buttonRef: RefObject<HTMLButtonElement>) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) return;

    const listener = (event: MouseEvent) => {
      if (event.target === buttonRef.current) return;
      setVisible(false);
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, [buttonRef, visible]);

  const toggleVisible = useCallback(() => {
    setVisible((s) => !s);
  }, []);

  return [visible, toggleVisible] as const;
}

function useAuthenticatedUserName() {
  const name = useBoundSelector((s) => s.account.user?.name);

  useEffect(() => {
    account.fetchUser();
  }, []);

  return name;
}

function useLicense() {
  const [license, setLicense] = useState<License>();

  useEffect(() => {
    eula.fetchLicense().then(setLicense);
  }, []);

  return license;
}
