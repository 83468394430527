import type { FC } from "react";
import classNames from "classnames";
import styles from "./DateTimeDisplay.module.css";
import { ReactComponent as Send } from "./ic_60_send.svg";
import { ReactComponent as Reload } from "./ic_60_reload.svg";
import Date from "../Date";

type Props = {
  createdAt: Date;
  updatedAt: Date;
  className?: string;
};

/**
 * このコンポーネントの責務は 登録日時と更新日時を表示すること
 */
const DateTimeDisplay: FC<Props> = ({ createdAt, updatedAt, className }) => {
  return (
    <div className={classNames(styles.dateInfo, className)}>
      <div className={styles.dateItem}>
        <Reload />
        <Date value={updatedAt} />
      </div>
      <div className={styles.dateItem}>
        <Send />
        <Date value={createdAt} />
      </div>
    </div>
  );
};
export default DateTimeDisplay;
