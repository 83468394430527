import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // debug: true,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  keySeparator: false,
});

export default i18n;

init();

/**
 * 局所的に利用する場合の考慮
 */
export const cloneI18n = i18n.cloneInstance();

/**
 * 初回読込時に多言語定義用ファイルを取得する
 *
 * @description
 * オンライン環境下で一度以上参照しないとオフライン環境下で参照できないケースが生じる
 * また XHR が Service Worker 側でイベントを検知できないため、こちらで対応を取る
 *
 * > 改修タイミングに応じて、i18next-xhr-backend が deprecated なため i18next-http-backend に変更予定
 * > 変更後不要であれば当処理を削除する
 */
function init() {
  const langs = ["en", "ja"];
  const namespace = "translation";
  langs.forEach((lang) =>
    fetch(`/locales/${lang}/${namespace}.json`)
      .then((response) => response.json())
      .then((json) => i18n.addResourceBundle(lang, namespace, json, true, true))
  );
}
