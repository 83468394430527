/**
 * 手書きインスタンス
 */
export type DrawingInstance = Path | Object;

/**
 * 手書き要素の変形情報
 */
export type Transform = {
  /** X, Y 方向の拡大変形倍率 */
  scale?: { x: number; y: number };
  /** 回転量 (ラジアン) */
  rotate?: number;
  /** X, Y 方向の平行移動量 (px) */
  translate?: { x: number; y: number };
};

export type Operation = "move" | "rotate" | ResizeOperation;
// @see https://developer.mozilla.org/ja/docs/Web/CSS/cursor
type ResizeOperation =
  | "n-resize"
  | "e-resize"
  | "s-resize"
  | "w-resize"
  | "ne-resize"
  | "nw-resize"
  | "se-resize"
  | "sw-resize";

export function isResizeOperation(x: Operation): x is ResizeOperation {
  return x.endsWith("-resize");
}

/**
 * パス
 *
 * 一筆書きで書かれた繋がった一本の線
 */
export type Path = {
  id: string;
  shape: "path";
  transform?: Transform;
  /** 線が辿る座標 */
  points: Point[];
  /** 線の色 */
  color: PathColor;
  /** 線の太さ (px) */
  width: number;
};

/**
 * 点
 *
 * 画像データの左上を原点とし、右下を正とする座標
 */
export type Point = {
  /** X 座標 (px) */
  x: number;
  /** Y 座標 (px) */
  y: number;
};

/**
 * パスの線の色一覧
 */
export const pathColors = [
  "red",
  "blue",
  "green",
  "brown",
  "pink",
  "orange",
  "gray",
  "black",
] as const;
/**
 * パスの線の色
 *
 * 色情報。CSS color value として扱える
 */
export type PathColor = typeof pathColors[number];

export function createPath(options: Omit<Path, "id" | "shape">): Path {
  return { ...options, id: genId(), shape: "path" };
}

/**
 * オブジェクト
 *
 * 特定の図形の手書きインスタンス
 */
export type Object = Rect | Ellipse | Line;

type CreateObjectOptions = {
  position: { cx: number; cy: number };
  color?: PathColor;
  strokeWidth?: number;
};
export function createObject(shape: "rect", options: CreateObjectOptions): Rect;
export function createObject(
  shape: "ellipse",
  options: CreateObjectOptions
): Ellipse;
export function createObject(shape: "line", options: CreateObjectOptions): Line;
export function createObject(
  shape: Shape,
  options: CreateObjectOptions
): Object;
export function createObject(
  shape: Shape,
  options: CreateObjectOptions
): Object {
  const { cx, cy } = options.position;
  const { color = "red", strokeWidth = 5 } = options;
  switch (shape) {
    case "rect":
      return {
        id: genId(),
        shape,
        cx,
        cy,
        width: 150,
        height: 150,
        color,
        strokeWidth,
      };
    case "ellipse":
      return {
        id: genId(),
        shape,
        cx,
        cy,
        rx: 150 / 2,
        ry: 150 / 2,
        color,
        strokeWidth,
      };
    case "line": {
      const x1 = cx - 190 / Math.sqrt(2) / 2;
      const x2 = cx + 190 / Math.sqrt(2) / 2;
      const y1 = cy + 190 / Math.sqrt(2) / 2;
      const y2 = cy - 190 / Math.sqrt(2) / 2;
      return { id: genId(), shape, x1, x2, y1, y2, color, strokeWidth };
    }
  }
}

/**
 * 矩形
 */
export type Rect = {
  id: string;
  shape: "rect";
  transform?: Transform;
  /** 中心 X 座標 (px) */
  cx: number;
  /** 中心 Y 座標 (px) */
  cy: number;
  /** X 方向の長さ (px) */
  width: number;
  /** Y 方向の長さ (px) */
  height: number;
  /** 線の色 */
  color: PathColor;
  /** 線の太さ (px) */
  strokeWidth: number;
};

/**
 * 円形
 */
export type Ellipse = {
  id: string;
  shape: "ellipse";
  transform?: Transform;
  /** 中心 X 座標 (px) */
  cx: number;
  /** 中心 Y 座標 (px) */
  cy: number;
  /** 半径 X (px) */
  rx: number;
  /** 半径 Y (px) */
  ry: number;
  /** 線の色 */
  color: PathColor;
  /** 線の太さ (px) */
  strokeWidth: number;
};

/**
 * 線形
 */
export type Line = {
  id: string;
  shape: "line";
  transform?: Transform;
  /** 始点の X 座標 (px) */
  x1: number;
  /** 終点の X 座標 (px) */
  x2: number;
  /** 始点の Y 座標 (px) */
  y1: number;
  /** 終点の Y 座標 (px) */
  y2: number;
  /** 線の色 */
  color: PathColor;
  /** 線の太さ (px) */
  strokeWidth: number;
};

/**
 * 図形
 *
 * 矩形 (rect), 円形 (ellipse), 線形 (line) の 3 要素の集合
 */
export type Shape = Object["shape"];

/** 手書きインスタンスがアクティブであることを表す枠の内側の空白サイズ (px) */
const activeFramePadding = 16;

/**
 * 手書きインスタンスが内部に収まる最小の矩形を返す
 * (Transform は scale のみ適用済みの結果が返る)
 */
function calcScaledInstanceBoundingBox(
  instance: DrawingInstance
): { x: number; y: number; width: number; height: number } {
  switch (instance.shape) {
    case "path": {
      let [minX, minY, maxX, maxY] = [Infinity, Infinity, -Infinity, -Infinity];
      for (const p of instance.points) {
        if (p.x < minX) minX = p.x;
        if (p.y < minY) minY = p.y;
        if (p.x > maxX) maxX = p.x;
        if (p.y > maxY) maxY = p.y;
      }
      const cx = (minX + maxX) / 2;
      const cy = (minY + maxY) / 2;
      const width = (maxX - minX) * (instance.transform?.scale?.x ?? 1);
      const height = (maxY - minY) * (instance.transform?.scale?.y ?? 1);
      return { x: cx - width / 2, y: cy - height / 2, width, height };
    }
    case "rect": {
      const { cx, cy } = instance;
      const width = instance.width * (instance.transform?.scale?.x ?? 1);
      const height = instance.height * (instance.transform?.scale?.y ?? 1);
      return { x: cx - width / 2, y: cy - height / 2, width, height };
    }
    case "ellipse": {
      const { cx, cy, rx, ry } = instance;
      const width = rx * 2 * (instance.transform?.scale?.x ?? 1);
      const height = ry * 2 * (instance.transform?.scale?.y ?? 1);
      return { x: cx - rx, y: cy - ry, width, height };
    }
    case "line": {
      const { x1, x2, y1, y2 } = instance;
      const minX = Math.min(x1, x2);
      const minY = Math.min(y1, y2);
      const maxX = Math.max(x1, x2);
      const maxY = Math.max(y1, y2);
      return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
    }
  }
}

/**
 * 手書きインスタンスがアクティブであることを表す枠の位置と大きさを計算する
 * (Transform は scale のみ適用済みの結果が返る)
 */
export function calcActiveInstanceFrame(instance: DrawingInstance) {
  const { x, y, width, height } = calcScaledInstanceBoundingBox(instance);
  return {
    x: x - activeFramePadding,
    y: y - activeFramePadding,
    width: width + activeFramePadding * 2,
    height: height + activeFramePadding * 2,
  };
}

/**
 * 手書きインスタンスの中心点を返す
 * (Transform は適用済みの結果が返る)
 */
export function calcCenter(instance: DrawingInstance): Point {
  const { x, y, width, height } = calcScaledInstanceBoundingBox(instance);
  const { x: dx = 0, y: dy = 0 } = instance.transform?.translate ?? {};
  return { x: x + width / 2 + dx, y: y + height / 2 + dy };
}

/**
 * 手書きインスタンスを指定量動かした場合の新しい手書きインスタンスを返す
 */
export function move(
  instance: DrawingInstance,
  { dx, dy }: { dx: number; dy: number }
): DrawingInstance {
  const { x, y } = instance.transform?.translate ?? { x: 0, y: 0 };
  return {
    ...instance,
    transform: { ...instance.transform, translate: { x: x + dx, y: y + dy } },
  };
}

/**
 * 手書きインスタンスを指定量回転させた場合の新しい手書きインスタンスを返す
 */
export function rotate(
  instance: DrawingInstance,
  { radian }: { radian: number }
): DrawingInstance {
  return {
    ...instance,
    transform: {
      ...instance.transform,
      rotate: (instance.transform?.rotate ?? 0) + radian,
    },
  };
}

/**
 * 手書きインスタンスを指定量リサイズさせた場合の新しい手書きインスタンスを返す
 */
export function resize(
  instance: DrawingInstance,
  { operation, dx, dy }: { operation: ResizeOperation; dx: number; dy: number }
): DrawingInstance {
  // Line の場合は (始点,終点) の座標をコントロールする (scale, rotate は扱わない)
  if (instance.shape === "line") {
    if (operation === "sw-resize") {
      const x1 = instance.x1 + dx;
      const y1 = instance.y1 + dy;
      return { ...instance, x1, y1 };
    } else {
      const x2 = instance.x2 + dx;
      const y2 = instance.y2 + dy;
      return { ...instance, x2, y2 };
    }
  }

  // dx, dy の変化量を手書きインスタンスのローカル座標系に変換する = rotate の逆回転行列
  const r = instance.transform?.rotate ?? 0;
  const x = Math.cos(-r) * dx - Math.sin(-r) * dy;
  const y = Math.sin(-r) * dx + Math.cos(-r) * dy;

  const frame = calcScaledInstanceBoundingBox(instance);
  let w = frame.width;
  let h = frame.height;

  const dir = operation.replace(/-resize$/, "");
  if (dir.includes("e")) w = Math.max(1, frame.width + x);
  if (dir.includes("w")) w = Math.max(1, frame.width - x);
  if (dir.includes("s")) h = Math.max(1, frame.height + y);
  if (dir.includes("n")) h = Math.max(1, frame.height - y);

  const dw = w - frame.width;
  const dh = h - frame.height;

  let scaled: DrawingInstance;
  switch (instance.shape) {
    // Path の場合は Transform で変形する
    case "path": {
      scaled = {
        ...instance,
        transform: {
          ...instance.transform,
          scale: {
            x: ((instance.transform?.scale?.x ?? 1) * w) / frame.width,
            y: ((instance.transform?.scale?.y ?? 1) * h) / frame.height,
          },
        },
      };
      break;
    }
    case "rect": {
      // Rect の場合は (width,height) の大きさをコントロールする (scale は扱わない)
      scaled = { ...instance, width: w, height: h };
      break;
    }
    case "ellipse": {
      // Ellipse の場合は (rx,ry) の大きさをコントロールする (scale は扱わない)
      const rx = Math.max(1, instance.rx + dw / 2);
      const ry = Math.max(1, instance.ry + dh / 2);
      scaled = { ...instance, rx, ry };
      break;
    }
  }

  return move(scaled, {
    dx:
      (dir.includes("e") ? 1 : -1) * (dw / 2) * Math.cos(r) -
      (dir.includes("s") ? 1 : -1) * (dh / 2) * Math.sin(r),
    dy:
      (dir.includes("e") ? 1 : -1) * (dw / 2) * Math.sin(r) +
      (dir.includes("s") ? 1 : -1) * (dh / 2) * Math.cos(r),
  });
}

function genId() {
  return Math.random().toString(32).substring(2);
}
