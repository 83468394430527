import sanitizeHtml from "sanitize-html";
import { MemoData } from "../../components/MemoEditor";
import type { NewCommentData } from "../../components/MemoEditor/MemoDetail";
import {
  AttachmentFile,
  Comment,
  Draft,
  isEditedDraft,
  Memo,
} from "../../models";
import { shipMemoPageSize } from "../../models";
import store, { actions, selectors } from "../../store";
import {
  jsonToDraftComment,
  jsonToDraftMemo,
  memoToJson,
} from "../../store/serializers";
import { withLanguageHeader } from "../../webapi";
import {
  GetShipsFShipNoModelsSortEnum,
  MentionUser,
} from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";
import { isUnreadMemoMention, uploadAttachmentFile } from "./memo";
import * as shipDraft from "./ship-draft";
import { fileAttachedType } from "../../models/file";

export async function fetchShip(shipId: string, language?: string) {
  let webapi = await getAuthedWebapi();
  if (language) webapi = withLanguageHeader(webapi, language);
  const data = await webapi.getShipsFShipNo({ fShipNo: shipId });
  return data;
}

type PostNewShipMemo = Omit<MemoData, "files" | "mentionedUsers"> & {
  files: File[];
} & {
  mentionedUsers: MentionUser[];
};

type UpdateShipMemo = Omit<PostNewShipMemo, "files"> & {
  files: Array<File | AttachmentFile>;
};

export async function fetchShipMemos(
  shipId: string,
  page: number,
  language: string,
  categories?: number[]
) {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const { memos, count } = await withLang.getShipsFShipNoMemos({
    fShipNo: shipId,
    page,
    limit: shipMemoPageSize,
    categories: categories?.length ? categories : undefined,
  });

  const models = memos.map(
    (x): Memo => {
      const comments = x.comments.map(
        (base): Comment => {
          if (base.isDeleted) return { ...base, isDeleted: true };
          const isUnread = isUnreadMemoMention(
            base.id,
            base.mentionedUsers!,
            base.updatedAt,
            undefined,
            undefined,
            undefined,
            x.id
          );
          return {
            ...base,
            isDeleted: false,
            isUnreadMention: isUnread,
            files: base.files!.map((f) => ({ ...f, available: true })),
          };
        }
      );
      const base = { ...x, comments };
      if (x.isDeleted) return { ...base, isDeleted: true };
      const isUnread = isUnreadMemoMention(
        x.id,
        x.mentionedUsers!,
        x.updatedAt
      );
      return {
        ...base,
        title: x.title!,
        detail: x.detail!,
        isDeleted: false,
        isUnreadMention: isUnread,
        files: x.files!.map((f) => ({ ...f, available: true })),
        categories: x.categories,
      };
    }
  );

  store.dispatch(
    actions.ship.shipMemosReceived({ memos: models.map(memoToJson), count })
  );
}

/**
 * メモ作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param data 登録内容
 * @param key 下書き識別子
 */
export async function postNewShipMemo(
  shipId: string,
  data: PostNewShipMemo,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.shipMemosSelectors.selectById(
    draft.shipMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const uploadedFiles = await Promise.all(
      data.files.map((file) =>
        uploadAttachmentFile(file, () =>
          webapi.getMemosFileUploadUrl({
            type: fileAttachedType.MEMO,
          })
        )
      )
    );

    // メモデータを作成する
    await webapi.postShipsFShipNoMemos({
      fShipNo: shipId,
      memoWithCategoryCreate: {
        ...data,
        files: uploadedFiles,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftMemo) shipDraft.deleteShipDraftMemo(shipId, draftMemo.key);
  } else {
    if (draftMemo) {
      shipDraft.updateShipDraftMemo(shipId, draftMemo.key, data);
    } else {
      shipDraft.postNewShipDraftMemo(shipId, data);
    }
  }
}

/**
 * メモ更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateShipMemo(
  shipId: string,
  memoId: Memo["id"],
  data: UpdateShipMemo,
  key: Draft["key"] = ""
) {
  const { setting, ship, draft } = store.getState();
  const draftMemo = selectors.draft.shipMemosSelectors.selectById(
    draft.shipMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () =>
          webapi.getMemosFileUploadUrl({
            type: fileAttachedType.MEMO,
          })
        )
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のメモデータを取得する
    const prevData = selectors.shipMemo.selectById(ship.shipMemos, memoId);
    if (!prevData) throw new Error("cannot find ship memo to update");
    if (prevData.isDeleted)
      throw Error("cannot update memos that have already been deleted");

    // メモデータを更新する
    await webapi.putShipsFShipNoMemosMemoId({
      fShipNo: shipId,
      memoId,
      memoWithCategoryUpdate: {
        ...prevData,
        ...data,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftMemo) shipDraft.deleteShipDraftMemo(shipId, draftMemo.key);
  } else {
    if (draftMemo) {
      shipDraft.updateShipDraftMemo(shipId, draftMemo.key, data);
    } else {
      shipDraft.postNewShipDraftMemo(shipId, { ...data, id: memoId });
    }
  }
}

/**
 * メモ削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param key 下書き識別子
 */
export async function deleteShipMemo(
  shipId: string,
  memoId: Memo["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.shipMemosSelectors.selectById(
    draft.shipMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = { fShipNo: shipId, memoId };

    if (draftMemo) {
      // 下書きが有る上に編集下書きの場合（新規作成の場合 API を実行しない）
      if (isEditedDraft(jsonToDraftMemo(draftMemo))) {
        await webapi.deleteShipsFShipNoMemosMemoId(args);
      }
    } else {
      await webapi.deleteShipsFShipNoMemosMemoId(args);
    }
  }

  // API 実行による登録後、同一の下書きがあれば削除する
  if (draftMemo) shipDraft.deleteShipDraftMemo(shipId, draftMemo.key);
}

/**
 * コメント作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param comment 登録内容
 * @param key 下書き識別子
 */
export async function postNewShipMemoComment(
  shipId: string,
  memoId: Memo["id"],
  comment: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();

  const draftComment = selectors.draft.shipCommentsSelectors.selectById(
    draft.shipComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const files = await Promise.all(
      comment.files
        .filter((x: any): x is File => x instanceof File)
        .map((file) =>
          uploadAttachmentFile(file, () =>
            webapi.getMemosFileUploadUrl({
              type: fileAttachedType.MEMO,
            })
          )
        )
    );

    await webapi.postShipsFShipNoMemosMemoIdComments({
      fShipNo: shipId,
      memoId,
      commentCreate: {
        message: comment.message,
        files,
        mentionedUsers: comment.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftComment)
      shipDraft.deleteShipDraftComment(shipId, draftComment.key);
  } else {
    if (draftComment) {
      shipDraft.updateShipDraftComment(shipId, draftComment.key, comment);
    } else {
      shipDraft.postNewShipDraftComment(shipId, memoId, comment);
    }
  }
}

/**
 * コメント更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param commentId コメントID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateShipMemoComment(
  shipId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  data: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft, ship } = store.getState();

  const draftComment = selectors.draft.shipCommentsSelectors.selectById(
    draft.shipComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () =>
          webapi.getMemosFileUploadUrl({
            type: fileAttachedType.MEMO,
          })
        )
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のコメントデータを取得する
    const prevData = selectors.shipMemo
      .selectById(ship.shipMemos, memoId)
      ?.comments.find((c) => c.id === commentId);
    if (!prevData) throw new Error("cannot find ship memo comment to update");
    if (prevData.isDeleted)
      throw Error("cannot update comments that have already been deleted");

    // メモデータを更新する
    await webapi.putShipsFShipNoMemosMemoIdCommentsCommentId({
      fShipNo: shipId,
      memoId,
      commentId,
      commentUpdate: {
        ...prevData,
        ...data,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftComment)
      shipDraft.deleteShipDraftComment(shipId, draftComment.key);
  } else {
    if (draftComment) {
      shipDraft.updateShipDraftComment(shipId, draftComment.key, data);
    } else {
      shipDraft.postNewShipDraftComment(shipId, memoId, {
        ...data,
        id: commentId,
      });
    }
  }
}

/**
 * コメント削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param memoId メモID
 * @param commentId コメントID
 * @param key 下書き識別子
 */
export async function deleteShipMemoComment(
  shipId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftComment = selectors.draft.shipCommentsSelectors.selectById(
    draft.shipComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = { fShipNo: shipId, memoId, commentId };

    if (draftComment) {
      // 下書きが有る上に編集下書きの場合（新規作成の場合 API を実行しない）
      if (isEditedDraft(jsonToDraftComment(draftComment))) {
        await webapi.deleteShipsFShipNoMemosMemoIdCommentsCommentId(args);
      }
    } else {
      await webapi.deleteShipsFShipNoMemosMemoIdCommentsCommentId(args);
    }
  }

  // API 実行による登録後、同一の下書きがあれば削除する
  if (draftComment) shipDraft.deleteShipDraftComment(shipId, draftComment.key);
}

export async function fetchShipDiagrams(shipId: string) {
  const webapi = await getAuthedWebapi();

  const { diagrams } = await webapi.getShipsFShipNoDiagrams({
    fShipNo: shipId,
  });
  return diagrams;
}

export async function fetchShipEquipments(
  shipId: string,
  sort?: "modelTypeAsc" | "modelTypeDesc"
) {
  const webapi = await getAuthedWebapi();

  const { models } = await webapi.getShipsFShipNoModels({
    fShipNo: shipId,
    sort:
      sort === "modelTypeAsc"
        ? GetShipsFShipNoModelsSortEnum.ModelTypeAsc
        : GetShipsFShipNoModelsSortEnum.ModelTypeDesc,
  });
  return models;
}

/**
 * re_export
 */
export const pullDraft = shipDraft.pullDraft;

/**
 * 文字列に含まれるURLをaタグに置き換えて返却する
 * URLを含まない場合はsanitize後の文字列を返す
 *
 * @param str メモ・コメント文字列
 * @returns sanitize後の文字列
 */
export function replaceSanitizedHtml(str: string, disabled?: boolean) {
  const regexp_url = /((https?:\/\/+[^\s]+[\w]+))/g;
  const regexp_makeLink = function (url: string, href: string) {
    if (disabled) {
      return `<span class="disabled">${url}</span>`;
    }
    return `<a href=${href} target="_blank" rel="noopener noreferrer">${url}</a>`;
  };

  // URLを含む場合はaタグに置換する
  let convertedText = str.replace(regexp_url, regexp_makeLink);

  // 改行を含む場合は<br/>に置換する
  convertedText = convertedText.replace(/\n/g, "<br/>");

  return sanitizeHtml(convertedText, {
    allowedTags: ["a", "br", "span"],
    allowedSchemes: ["http", "https"],
    allowedAttributes: { a: ["href", "target", "rel"], span: ["class"] },
    disallowedTagsMode: "escape",
  });
}

/**
 * カテゴリ一覧を取得
 *
 * @returns
 */
export async function fetchCategories() {
  const webapi = await getAuthedWebapi();
  const data = await webapi.getCategories();

  const { setting } = store.getState();
  const language = setting.language;

  const categories = data.categories.map((category) => ({
    id: category.id,
    name: language === "en" ? category.nameEn : category.nameJa,
  }));

  store.dispatch(actions.ship.categoriesReceived({ categories }));
}
