import { GetServiceOrdersSortEnum } from "../webapi/generated";
import {
  ServiceOrder as WebApiServiceOrder,
  ServiceOrderDetail as WebApiServiceOrderDetail,
  ServiceOrderModel as WebApiServiceOrderModel,
} from "../webapi/generated/models";

export type ServiceOrder = WebApiServiceOrder;
export type ModifiedServiceOrder = ServiceOrder & {
  reportable?: boolean;
};
export type ServiceOrderDetail = WebApiServiceOrderDetail;
export type ServiceOrderModel = WebApiServiceOrderModel;

export type ServiceOrderSortType = `${GetServiceOrdersSortEnum}`;

export function convertServiceOrderSortQuery(query?: string) {
  switch (query) {
    case GetServiceOrdersSortEnum.ReceptionDateAsc:
      return GetServiceOrdersSortEnum.ReceptionDateAsc;
    case GetServiceOrdersSortEnum.ReceptionDateDesc:
      return GetServiceOrdersSortEnum.ReceptionDateDesc;
    default:
      return GetServiceOrdersSortEnum.ReceptionDateDesc;
  }
}
